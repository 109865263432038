import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';
import { CoreSession } from '../../../core/core.session';
import { SessionDataProvider } from '../../../core/session-data-provider.service';
import { ConstantConfigurations } from '../../../shared/models/constants/constant-configuration';
import { ConstantMessages } from '../../../shared/models/constants/constant-message';
import { EmployeeKeyFilter } from '../../../shared/models/employee-keys/employee-keys';
import { DialogResult } from '../../../shared/models/enums/dialog-result.enum';
import { EmployeeKeysNotifictaionType } from '../../../shared/models/enums/employee-keys-notifictaion-type-enum';
import { EmployeeKeysService } from '../../../shared/services/employee-operations/employee-keys.service';
import { EmployeeOperationService } from '../employee-operation.service';

@Component({
  selector: 'app-employee-key-dialog',
  templateUrl: './employee-key-dialog.component.html',
  styleUrls: ['./employee-key-dialog.component.css']
})
export class EmployeeKeyDialogComponent implements OnInit {

  DateTmieNow: Date;
  AllDiff: number;
  DiffMon: number;
  DiffDay: number;
  DiffMin: number;
  DiffSec: number;
  countDown: Subscription;
  counter: number;
  tick = 1000;
  IshiddenButton: boolean;
  keyStatus = '';
  colorStatus = '';
  OkResult: DialogResult = DialogResult.Ok;
  RejectResult: DialogResult = DialogResult.No;
  providedKey: any = {};
  @Input() selectedNotification: any = null;

  query: EmployeeKeyFilter = {
    page: 0,
    pageSize: 20
  };


  @ViewChild("keyModal", { static: true }) keyModal: NgbModal;

  constructor(
    public coreSession: CoreSession,
    private modalService: NgbModal,
    private sessionDataProvider: SessionDataProvider,
    private config: NgbModalConfig,
    private translateService: TranslateService,
    private employeeKeysService: EmployeeKeysService
  ) {
  }

  ngOnInit() {

  }

  public Show(notification) {

    this.selectedNotification = notification
    if (this.selectedNotification != null)
      this.provideEmployeeKey();

  }
  ngOnDestroy() {
    if (this.modalService) {
      this.modalService.dismissAll();
    }
    this.selectedNotification = null;
    if (this.countDown != undefined) {
      this.countDown.unsubscribe();
    }
    this.countDown = undefined;
  }

  openKeyDialog() {

    if (this.providedKey.employeeKeysNotifictaionStatus == EmployeeKeysNotifictaionType.Pending || this.providedKey.employeeKeysNotifictaionStatus == EmployeeKeysNotifictaionType.TimedOut) {
      this.IshiddenButton = false;
    } else {
      this.IshiddenButton = true;
    }

    this.CheckDateNotification();
    this.config.backdrop = "static";
    const modalOpened = this.modalService.open(this.keyModal, {
      centered: true
    });
    modalOpened.result.then(
      result => {
        if (result == DialogResult.Ok) {
          this.updateKeyToProvided();
        } else if (result == DialogResult.No) {
          this.rejectKeyToProvided();
        }
        this.providedKey = {};
        // this.config.backdrop = true;
        return result;
      },
      reason => {
        return undefined;
      }
    );

  }


  provideEmployeeKey() {
    this.query.creationSourcesId = 2;
    this.query.notificationId = this.selectedNotification.notificationId;
    this.query.customerId = this.selectedNotification.customerId;
    this.query.outletId = this.selectedNotification.outletId;
    this.query.employeeId = this.selectedNotification.employeeId;
    this.query.keyTypeId = this.selectedNotification.keyTypeId;
    this.query.isOnline = true;
    if (this.sessionDataProvider.allMenusAvailable.every(menu => menu.menuId !== '600-001')){
      return  this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), 
      this.translateService.instant(ConstantMessages.MsgMenuNotAuthorized).replace('{0}', 'EmployeeKeys'));
    }
    this.coreSession.ModalLoading.Show();
    this.employeeKeysService.provideEmployeeKey(this.query).subscribe(
      response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.providedKey = response.data;
          this.openKeyDialog();
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      error => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }

  setTimeOutNotifictaion() {

    this.providedKey.isOnline = true;
    this.providedKey.notificationId = this.selectedNotification.notificationId;

    if (this.providedKey.employeeKeysNotifictaionStatus == EmployeeKeysNotifictaionType.Pending) {
      this.employeeKeysService.timeOutEmployeeKeyNotifictaion(this.providedKey).subscribe();
    }
  }

  rejectKeyToProvided() {

    this.providedKey.isOnline = true;
    this.providedKey.notificationId = this.selectedNotification.notificationId;
    this.employeeKeysService.rejectKeyToProvided(this.providedKey).subscribe(
      response => {
        if (response.status != null && response.status >= 0) {

        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      error => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }

  updateKeyToProvided() {
    this.providedKey.isOnline = true;
    this.providedKey.notificationId = this.selectedNotification.notificationId;
    this.employeeKeysService.setKeyToProvided(this.providedKey).subscribe(
      response => {
        if (response.status != null && response.status >= 0) {

        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        }
      },
      error => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
      }
    );
  }

  CheckDateNotification() {

    this.selectedNotification.notificationDate = new Date(this.selectedNotification.notificationDate);
    this.DateTmieNow = new Date();

    this.DiffMon = this.DateTmieNow.getMonth() - this.selectedNotification.notificationDate.getMonth()
    this.DiffDay = this.DateTmieNow.getDay() - this.selectedNotification.notificationDate.getDay()
    this.DiffMin = this.DateTmieNow.getMinutes() - this.selectedNotification.notificationDate.getMinutes()
    this.DiffSec = this.DateTmieNow.getSeconds() - this.selectedNotification.notificationDate.getSeconds()
    this.AllDiff = (this.DiffMin * 60) + this.DiffSec;

    if (this.DiffMon == 0 && this.DiffDay == 0 && this.providedKey.employeeKeysNotifictaionStatus == EmployeeKeysNotifictaionType.Pending) {
      if (this.AllDiff > parseInt(this.sessionDataProvider.getConfigurationValue(ConstantConfigurations.EmployeeKeyResponceTimeOut)) * 60) {
        this.stopCounter();
      } else {
        this.IshiddenButton = false;
        this.counter = (parseInt(this.sessionDataProvider.getConfigurationValue(ConstantConfigurations.EmployeeKeyResponceTimeOut)) * 60) - this.AllDiff
        this.countDown = timer(0, this.tick)
          .subscribe(() =>
            this.counter <= 0 ? this.stopCounter() : --this.counter)
      }
    } else {
      this.stopCounter();
    }

  }

  stopCounter() {
    if (this.providedKey.employeeKeysNotifictaionStatus == EmployeeKeysNotifictaionType.Pending) {
      this.setTimeOutNotifictaion();
      this.providedKey.employeeKeysNotifictaionStatus = EmployeeKeysNotifictaionType.TimedOut;
    }

    this.IshiddenButton = true;
    this.counter = 0;
    this.setColor();
  }

  setColor() {

    switch (this.providedKey.employeeKeysNotifictaionStatus) {
      case 2:
        this.keyStatus = 'Desc_Sent';
        this.colorStatus = 'label-green'
        break;
      case 3:
        this.keyStatus = 'Desc_Reject';
        this.colorStatus = 'label-red'
        break;
      case 4:
        this.keyStatus = 'Desc_Timed_Out';
        this.colorStatus = 'label-yellow'
        break;
    }

  }

}
