import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy
} from "@angular/core";
import { EmployeeService } from "../../../../content/employee/employee.service";
import { FormGroup, AbstractControl } from "@angular/forms";
import { EmployeeTypes } from "../../../models/enums/employee-types.enum";
import { HereMapService } from "../../../../content/employee-operation/maps/here-maps.service";
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from '../../../models/constants/constant-message';

@Component({
  selector: "app-employee-filter",
  templateUrl: "./employee-filter.component.html",
  styleUrls: ["./employee-filter.component.css"]
})
export class EmployeeFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() organizationId = null;
  @Input() multiOrganizationId = null;
  @Input() currentFormControlName = 'employeeId';
  @Input() hideSelected: boolean = false;
  @Input()
  parentForm: FormGroup;

  @Input()
  getEmployeesBasedOnSelectedCustomer: boolean;

  @Input()
  customerId: number;

  @Input('employeeLabelText')
  employeeLabelText = 'Desc_Employee'

  @Input()
  outletId: number;

  @Input()
  filterOnVehicle: boolean;

  @Input()
  excludeOnholdEmployee: boolean = false;

  @Input()
  filterOnWarehouse: boolean;

  @Input()
  isMandatory: boolean = false;

  @Input()
  vehicleId: number;

  @Input()
  warehouseId: number;

  @Input()
  regionId: number;

  @Input()
  channelId: number;

  @Input()
  modeId: number;

  @Input()
  clearEmployeeDataSource: string;

  @Input()
  emitIdAndCaption = false;

  @Input()
  employeeTypes: EmployeeTypes[];

  @Input()
  isLabelHidden: boolean;

  @Input()
  hasTerritories: boolean;

  @Input()
  hasVehicles: boolean;

  @Input()
  isAllEmployeesFieldAvailable: boolean = false;

  @Input('stockCountingTaskMode')
  stockCountingTaskMode: boolean = false;

  @Input() routeId = -1;
  @Input() filterOnRoute = false;

  @Input() isMultiSelect = false;
  @Input() isExceptSelectedEmployees = false;

  @Input() salesManagerId = -1;
  @Input() supervisorId = -1;

  @Output()
  employeeSelected: EventEmitter<number> = new EventEmitter();

  @Output()
  onChangeIdCaptionEmployee = new EventEmitter();

  @Input()
  isFOEmployee: boolean = false;

  @Input() securityGroupId = -1;
  @Input() getEmployeesBasedOnSelectedSecurityGroup = false;

  @Input() changeSecurityGroupEvent: EventEmitter<number> = null;

  @Input() setValueForDisabledControl: boolean = false;
  @Input() territoryIds: string;
  @Input() getEmployeesHavingOrdersFromInvoicedOrders: boolean = false;
  @Input() getEmployeesHavingOrdersFromApproved: boolean = false;
  @Input() getEmployeesHavingOrdersFromReschedule: boolean = false;
  @Input() filterEmployee: any;
  @Input() isRestrictEmployee : boolean = false;
  securityGroupSubscription: any;
  allEmployeesList: any[] = [];
  employeeDataSource: any[];
  selectedEmployeeId: number;
  isEmployeeLoading = false;

  dropdownEmployeesSettings = {
    singleSelection: false,
    idField: 'employeeId',
    textField: 'employeeCodeName',
    selectAllText: '',
    unSelectAllText: '',
    position: 'top',
    allowSearchFilter: true,
    searchPlaceholderText: '',
    itemsShowLimit: 1
  };
  employeeFilter = {
    employeeTypes: [],
    hasTerritories: false,
    hasVehicles: false,
    routeId: -1,
    regionId: -1,
    channelId: -1,
    filterOnRoute: this.filterOnRoute,
    isAllEmployeesFieldAvailable: false,
    customerId: -1,
    outletId: -1,
    organizationId: -1,
    multiOrganizationId: '',
    isExceptSelectedEmployees: false,
    salesManagerId: -1,
    supervisorId: -1,
    isFOEmployee: false,
    securityGroupId: -1,
    territoryIds: null,
    excludeOnholdEmployee: false,
    getEmployeesHavingOrdersFromInvoiced: false,
    getEmployeesHavingOrdersFromApproved: false,
    getEmployeesHavingOrdersFromReschedule: false
  };

  isRequired = false;
  constructor(
    private employeeService: EmployeeService,
    private hereMapService: HereMapService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    if (this.getEmployeesBasedOnSelectedSecurityGroup) {
      this.securityGroupSubscription = this.changeSecurityGroupEvent.subscribe((value) => {
        if (value)
          this.securityGroupId = value;
        this.populateEmployeeList(this.employeeFilter);
      });
    }
    this.isRequired = false;
    if (!this.isMultiSelect) {
      if (this.parentForm.get(this.currentFormControlName).validator != null) {
        const validator = this.parentForm
          .get(this.currentFormControlName)
          .validator({} as AbstractControl);
        if (validator && validator.required) {
          this.isRequired = true;
        }
      }
    }
    else {
      if (this.parentForm.get('employeeControl').validator != null) {
        const validator = this.parentForm
          .get('employeeControl')
          .validator({} as AbstractControl);
        if (validator && validator.required) {
          this.isRequired = true;
        }
      }
      this.dropdownEmployeesSettings.selectAllText = this.translateService.instant(ConstantMessages.SelectAllCaption);
      this.dropdownEmployeesSettings.unSelectAllText = this.translateService.instant(ConstantMessages.UnSelectAllCaption);
      this.dropdownEmployeesSettings.searchPlaceholderText = this.translateService.instant(ConstantMessages.SearchCaption);

      this.populateEmployeesListForMultiSelection();
    }

  }

  ngOnChanges() {
    // this.isRequired = false;
    if (!this.isMultiSelect) {
      if (this.clearEmployeeDataSource) {
        this.employeeDataSource = [];
        return;
      }
      this.selectedEmployeeId = !this.setValueForDisabledControl ? this.parentForm.value.employeeId : this.parentForm.get(this.currentFormControlName).value;

      this.employeeFilter.employeeTypes = this.employeeTypes;
      this.employeeFilter.hasTerritories = this.hasTerritories;
      this.employeeFilter.hasVehicles = this.hasVehicles;
      this.employeeFilter.isAllEmployeesFieldAvailable = this.isAllEmployeesFieldAvailable;
      this.employeeFilter.routeId = this.routeId;
      this.employeeFilter.filterOnRoute = this.filterOnRoute;
      this.employeeFilter.regionId = this.regionId ? this.regionId : -1;
      this.employeeFilter.channelId = this.channelId ? this.channelId : -1;
      this.employeeFilter.customerId = this.customerId ? this.customerId : -1;
      this.employeeFilter.outletId = this.outletId ? this.outletId : -1;
      this.employeeFilter.organizationId = this.organizationId ? this.organizationId : -1;
      this.employeeFilter.multiOrganizationId = this.multiOrganizationId;
      this.employeeFilter.isExceptSelectedEmployees = this.isExceptSelectedEmployees;
      this.employeeFilter.salesManagerId = this.salesManagerId ? this.salesManagerId : -1;
      this.employeeFilter.supervisorId = this.supervisorId ? this.supervisorId : -1;
      this.employeeFilter.isFOEmployee = this.isFOEmployee;
      this.employeeFilter.territoryIds = this.territoryIds;
      this.employeeFilter.excludeOnholdEmployee = this.excludeOnholdEmployee;
      this.populateEmployeeList(this.employeeFilter);
    } else {
      if (this.parentForm.get('employeeControl').validator != null) {
        const validator = this.parentForm.get('employeeControl').validator({} as AbstractControl);
        if (validator && validator.required) {
          this.isRequired = true;
        }
      }

    }
    if (this.hideSelected) {
      this.parentForm.get(this.currentFormControlName).setValue(null);
    }
  }

  ngOnDestroy() {
    if (this.securityGroupSubscription) {
      this.securityGroupSubscription.unsubscribe();
    }
  }

  populateEmployeeList(filter: any) {
    //   if(!this.stockCountingTaskMode)
    //  this.parentForm.get(this.currentFormControlName).setValue(undefined);
    //if modeId = 1 sonic will retrieve all employees with vehicle tracking data
    var orgId = this.organizationId ? this.organizationId : -1;
    this.employeeFilter.organizationId = orgId;
    this.employeeFilter.securityGroupId = this.securityGroupId;
    if (this.getEmployeesHavingOrdersFromInvoicedOrders) {
      this.employeeFilter.getEmployeesHavingOrdersFromInvoiced = true;
    }
    if (this.getEmployeesHavingOrdersFromApproved) {
      this.employeeFilter.getEmployeesHavingOrdersFromApproved = true;
    }
    if (this.getEmployeesHavingOrdersFromReschedule)
      this.employeeFilter.getEmployeesHavingOrdersFromReschedule = true;

    if (this.modeId && this.modeId === 1) {
      this.hereMapService.getVehicleEmployees(orgId).subscribe(result => {
        this.employeeDataSource = result;
        this.isEmployeeLoading = false;
      });
    } else {
      if (this.filterOnVehicle) {
        if (this.vehicleId !== -1) {
          this.isEmployeeLoading = true;
          this.employeeService.getEmployeeLoadVehicle(this.vehicleId, orgId).subscribe(result => {
            this.employeeDataSource = result;
            if (this.employeeDataSource && this.employeeDataSource.length > 0) {
              this.parentForm.get(this.currentFormControlName).setValue(this.employeeDataSource[0].employeeId);
            }
            this.isEmployeeLoading = false;
          });
        }
      } else
        if (this.filterOnWarehouse) {
          if (this.warehouseId !== -1) {
            this.isEmployeeLoading = true;
            this.employeeService.getWarehouseStoreKeeper(this.warehouseId, orgId).subscribe(result => {
              this.employeeDataSource = result;
              if (this.employeeDataSource && this.employeeDataSource.length > 0) {
                this.parentForm.get(this.currentFormControlName).setValue(this.employeeDataSource[0].employeeId);
              }
              this.isEmployeeLoading = false;
            });
          }
        }
        else if (this.getEmployeesBasedOnSelectedCustomer) {
          this.isEmployeeLoading = true;
          this.employeeService.getCustomerRouteEmployees(this.employeeFilter).subscribe(result => {
            this.employeeDataSource = result;
            this.isEmployeeLoading = false;
          });
        }
        else {
          this.isEmployeeLoading = true;
          
          this.parentForm.get(this.currentFormControlName).setValue(this.selectedEmployeeId > -1 ? this.selectedEmployeeId : null);
          this.employeeService
            .getEmployeesFilterData(this.employeeFilter)
            .subscribe(result => {
              this.employeeDataSource = result;              
              if (this.filterOnRoute && this.routeId != -1 && this.employeeDataSource.length == 1) {
                this.parentForm.get(this.currentFormControlName).setValue(this.employeeDataSource[0].employeeId);
                this.employeeSelected.emit(this.employeeDataSource[0]);
              }
              if (this.getEmployeesBasedOnSelectedSecurityGroup && this.employeeDataSource.length > 0 && this.securityGroupId != -1) {
                this.parentForm.get(this.currentFormControlName).setValue(this.employeeDataSource[0].employeeId);
              }
              if (this.isExceptSelectedEmployees && this.customerId != -1) {
                var index = this.employeeDataSource.findIndex(x => x.customerId == this.customerId);
                if (this.employeeDataSource.length - 1 >= index && index > -1)
                  this.parentForm.get(this.currentFormControlName).setValue(this.employeeDataSource[index].employeeId);
              }
              if(this.filterEmployee && this.isRestrictEmployee){ // to filter employees that already has keys already in key-Privilege Screen
                this.employeeDataSource = this.employeeDataSource.filter(item => {
                  return !this.filterEmployee.some(employeeId => employeeId === item.employeeId);
                });
              }
              this.isEmployeeLoading = false;
            });
        }
    }
  }

  onEmployeeDataChanged(employee) {
    this.selectedEmployeeId = this.parentForm.value.employeeId;
    if (!this.emitIdAndCaption) this.employeeSelected.emit(employee);
    else {
      let event = {
        id: this.selectedEmployeeId,
        caption: employee.employeeCodeName
      };
      this.onChangeIdCaptionEmployee.emit(event);
    }
  }
  populateEmployeesListForMultiSelection() {
    // this.employeeService.getALLEmployee().subscribe(result => {
    //   this.allEmployeesList = result;
    // });
    this.isEmployeeLoading = true;
    var orgId = this.organizationId ? this.organizationId : -1;
    this.employeeFilter.organizationId = orgId;
    this.employeeFilter.employeeTypes = this.employeeTypes;

    this.employeeService
      .getEmployeesFilterData(this.employeeFilter)
      .subscribe(result => {
        this.allEmployeesList = result;


        this.isEmployeeLoading = false;
      });
  }
}
