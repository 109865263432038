import { NgModule } from "@angular/core";
import { SharedTableComponent } from "./components/shared-table/shared-table.component";
import { CommonModule, DatePipe } from "@angular/common";
import { MatPaginatorModule } from "@angular/material/paginator";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { SharedItemComponent } from "./components/items/shared-item/shared-item.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EffectsDirective } from "./directives/effects.directive";
import { FilterPipe } from "./pipes/filter.pipe";
import {
  NgbModule,
  NgbCollapse,
  NgbDropdownMenu,
  NgbDropdown,
} from "@ng-bootstrap/ng-bootstrap";

import { ToastyModule } from "ng2-toasty";
import { SharedItemsListFilterComponent } from "./components/items/shared-items-list-filter/shared-items-list-filter.component";
import { NgbModalModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalDialogComponent } from "./components/modal-dialog/modal-dialog.component";
import { ModalLoadingComponent } from "./components/modal-loading/modal-loading.component";
import { CustomerFilterComponent } from "./components/filters/customer-filter/customer-filter.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { MainFilterComponent } from "./components/filters/main-filter/main-filter.component";
import { EmployeeFilterComponent } from "./components/filters/employee-filter/employee-filter.component";
import { DivisionFilterComponent } from "./components/filters/division-filter/division-filter.component";
import { DateTimePickerComponent } from "./components/filters/date-time-picker/date-time-picker.component";
import { OnlyNumber } from "./directives/onlyNumbers/onlyNumbers.directive";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DropdownDirective } from "./directives/dropdown.directive";
import { VehicleFilterComponent } from "./components/filters/vehicle-filter/vehicle-filter.component";
import { WarehouseFilterComponent } from "./components/filters/warehouse-filter/warehouse-filter.component";
import { TakePromotionComponent } from "./components/promotion/take-promotion/take-promotion.component";
import { KeysPipe } from "./pipes/keys.pipe";
import { MatTabsModule } from "@angular/material/tabs";
import { ValidationService } from "./services/validation.service";
import { CalculationService } from "./services/calculations.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { ImageService } from "./services/image.service";
import { OperationSummaryComponent } from "./components/operation-summary/operation-summary.component";
import { NumberOfDigitsRounder } from "./pipes/decimal-rounder.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { AutoFocusDirective } from "./directives/autoFocus.directive";
import { CurrencyFilterComponent } from "./components/filters/currency-filter/currency-filter.component";
import { CustomerClassFilterComponent } from "./components/filters/customer-filters/customer-class-filter/customer-class-filter.component";
import { CustomerSubClassFilterComponent } from "./components/filters/customer-filters/customer-sub-class-filter/customer-sub-class-filter.component";
import { EntryFormComponent } from "./components/SharedForms/entry-form/entry-form.component";
import { CustomerTypeFilterComponent } from "./components/filters/customer-filters/customer-type-filter/customer-type-filter.component";
import { PaymentTermFilterComponent } from "./components/filters/customer-filters/payment-term-filter/payment-term-filter.component";
import { PaymentTermEntryComponent } from "../content/customer-operation/payments/payment-term-entry/payment-term-entry.component";
import { SliderComponent } from "./components/slider/slider.component";
import { TextInputLanguagesComponent } from "./components/text-input-languages/text-input-languages.component";
import { BatchSelectionComponent } from "./components/batch/batch-selection/batch-selection.component";
import { AddNewBatchComponent } from "./components/batch/add-new-batch/add-new-batch.component";
import { NumberOfDigits } from "./directives/numberOfDigits.directive";
import { NumberOfStockDigits } from "./directives/numberOfStockDigits.directive";
import { NumberOfStockDigitsRounder } from "./pipes/decimal-rounder-stock.pipe";
import { CustomerClassEntryComponent } from "../content/master-data-definition/customers/customer-class-entry/customer-class-entry.component";
import { MainSideFilterComponent } from "./components/filters/main-side-filter/main-side-filter.component";
import { SideFilterTemplateComponent } from "./components/filters/side-filter-template/side-filter-template.component";
import { WarehouseTransStatusFilterComponent } from "./components/filters/warehouse-trans-status-filter/warehouse-trans-status-filter.component";
import { ItemsSummaryComponent } from "./components/items/items-summary/items-summary.component";
import { ItemsListComponent } from "./components/items/item-list/item-list.component";
import { OrganizationFilterComponent } from "./components/filters/organization-filter/organization-filter.component";
import { CustomerGroupFilterComponent } from "./components/filters/customer-filters/customer-group-filter/customer-group-filter.component";
import { CustomerChannelFilterComponent } from "./components/filters/customer-filters/customer-channel-filter/customer-channel-filter.component";
import { SecurityGroupFilterComponent } from "./components/filters/security-group-filter/security-group-filter.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { SelectCustomersComponent } from "./components/customers/select-customers/select-customers.component";
import { ItemsLazyLoadingFilterComponent } from "./components/items/items-lazy-loading-filter/items-lazy-loading-filter.component";
import { PriceListTypesFilterComponent } from "./components/filters/price-list-types-filter/price-list-types-filter.component";
import { ItemCategoryFilterComponent } from "./components/filters/item-category-filter/item-category-filter.component";
import { ChangeLanguageComponent } from "../content/admin-settings/change-language/change-language.component";
import { PackGroupItemsComponent } from "./components/promotion/pack-group-items/pack-group-items.component";
import { SettedUpResolver } from "./resolvers/settedup.resolver";
import { DeviceFilterComponent } from "./components/filters/device-filter/device-filter.component";
import { WarehouseTypeFilterComponent } from "./components/filters/warehouse-type-filter/warehouse-type-filter.component";
import { PackGroupFilterComponent } from "./components/filters/pack-group-filter/pack-group-filter.component";
import { PackTypeFilterComponent } from "./components/filters/pack-type-filter/pack-type-filter.component";
import { FillPackGroupItemsQuantityComponent } from "./components/promotion/fill-pack-group-items-quantity/fill-pack-group-items-quantity.component";
import { ServiceSetupDialogComponent } from "./components/service-setup-dialog/service-setup-dialog.component";
import { RouteFilterComponent } from "./components/filters/route-filter/route-filter.component";
import { LocationFilterComponent } from "./components/filters/location-filter/location-filter.component";
import { MatInputModule } from "@angular/material/input";
import { ItemsListsListComponent } from "../content/master-data-definition/items-lists-definition/items-lists-list/items-lists-list.component";
import { ItemsListsEntryComponent } from "../content/master-data-definition/items-lists-definition/items-lists-entry/items-lists-entry.component";
import { ItemsMultiselectFilterComponent } from "./components/filters/items-multiselect-filter/items-multiselect-filter.component";
import { ImportDialogComponent } from "./components/import-dialog/import-dialog.component";
import { AddSerialsComponent } from "./components/Serials/add-serials/add-serials.component";
import { AddSubSerialsComponent } from "./components/Serials/add-sub-serials/add-sub-serials.component";
import { SerialTableComponent } from "./components/Serials/serial-table/serial-table.component";
import { InvalidSerialsComponent } from "./components/Serials/invalid-serials/invalid-serials.component";
import { ImportSerialsFromExcelComponent } from "./components/Serials/import-serials-from-excel/import-serials-from-excel.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { QuillModule } from "ngx-quill";
import { VendorFilterComponent } from "./components/filters/vendor-filter/vendor-filter.component";
import { CustomerPointsAdjustmentDialogComponent } from "./components/customer-points-adjustment-dialog/customer-points-adjustment-dialog.component";
import { AddItemsBySerialComponent } from "./components/Serials/add-items-by-serial/add-items-by-serial.component";
import { IgxLinearGaugeModule } from "igniteui-angular-gauges";
import { SSCCListComponent } from "./components/ssccGeneration/sscc-list/sscc-list.component";
import { SsccDetailsComponent } from "./components/ssccGeneration/sscc-details/sscc-details.component";
import { ScanBarcodeComponent } from "./components/ssccGeneration/scan-barcode/scan-barcode.component";
import { DateAgo } from "./pipes/date-ago.pipe";
import { StockCountingTasksStatusFilterComponent } from "./components/filters/stock-counting-tasks-filter/stock-counting-tasks-status-filter/stock-counting-tasks-status-filter.component";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { SerializedPromotedItemsComponent } from "./components/items/serialized-promoted-items/serialized-promoted-items.component";
import { TerritoryFilterComponent } from "./components/filters/territory-filter/territory-filter.component";
import { TransactionTypeFilterComponent } from "./components/filters/transaction-type-filter/transaction-type-filter/transaction-type-filter.component";
import { DisplayUnitGroupFilterComponent } from "./components/filters/display-unit-filter/display-unit-group-filter/display-unit-group-filter.component";
import { DisplayUnitStatusComponent } from "./components/filters/display-unit-filter/display-unit-status/display-unit-status.component";
import { DisplayUnitTypeComponent } from "./components/filters/display-unit-filter/display-unit-type/display-unit-type.component";
import { DisplayMultiTextComponent } from "./components/display-mutli-text/display-multi-text.component";
import { NumberOfStockDigitsFloorRounder } from "./pipes/floor-rounder-stock.pipe";
import { WebFormEntryComponent } from "./components/web-form/web-form-entry/web-form-entry.component";
import { WebFormListComponent } from "./components/web-form/web-form-list/web-form-list.component";
import { BankFilterComponent } from "./components/filters/bank-filter/bank-filter.component";
import { PriceTypeFilterComponent } from "./components/filters/price-type-filter/price-type-filter.component";
import { SelectItemsComponent } from "./components/items/select-items/select-items.component";
import { SharedDialogComponent } from "./components/shared-dialog/shared-dialog.component";
import { BrandFilterComponent } from "./components/filters/brand-filter/brand-filter.component";
import { SingleClickDirective } from "./directives/singleClick.directive";
import { SingleClickForEnterKeyDirective } from "./directives/singleClickForEnterKey.directive";
import { DisplayUnitTemplateComponent } from "./components/filters/display-unit-filter/display-unit-template/display-unit-template.component";
import { SelectSecondaryPackComponent } from "./components/secondary-pack/select-secondary-pack/select-secondary-pack.component";
import { WebFormListMasterDataComponent } from "./components/web-form/web-form-list-master-data/web-form-list-master-data.component";
import { WebFormListRoiComponent } from "./components/web-form/web-form-list-roi/web-form-list-roi.component";
import { ItemsFilterComponent } from "./components/filters/items-filter/items-filter.component";
import { SelectTerritoriesComponent } from "./components/select-territories/select-territories.component";
import { FormatTimePipe } from './pipes/format-time.pipe';
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { MatIconModule } from "@angular/material/icon";
import { MatTreeModule } from "@angular/material/tree";
import { MatButtonModule } from "@angular/material/button";
import { TreeHierarchyEntryComponent } from "./components/tree-hierarchy-entry/tree-hierarchy-entry.component";
import { ActivationDeactivationReasonEntryComponent } from "./components/activation-deactivation-reason-entry/activation-deactivation-reason-entry.component";
import { ReasonFilterComponent } from "./components/filters/reason-filter/reason-filter.component";
import { SelectDisplayUnitComponent } from "./components/display-unit/select-display-unit/select-display-unit.component";
import { AfterReturnPromotionComponent } from './components/returns/after-return-promotion/after-return-promotion.component';
import { SelectPromotionsComponent } from "./components/promotion/select-promotion/select-promotions.component";
import { ViewItemsComponent } from './components/items/view-items/view-items.component';
import { StockStatusFilterComponent } from './components/filters/stock-status-filter/stock-status-filter.component';
import { SalesOrderTypeFilterComponent } from './components/filters/sales-order-type-filter/sales-order-type-filter.component';
import { MatChipsModule } from '@angular/material/chips';
import { SelectCustomerGroupsComponent } from "./components/customer-groups/select-customer-groups/select-customer-groups.component";
import { PdfCreatorService } from "./services/pdf-creator.service";
import { ImportedExcelErrorsDialogComponent } from './components/imported-excel-errors-dialog/imported-excel-errors-dialog.component';
import { KpiFilterComponent } from './components/filters/kpi-filter/kpi-filter.component';
import { CommissionCalcPriceComponent } from './components/commission-calc-price/commission-calc-price.component';
import { CommCalcImportedExcelErrorsDialogComponent } from "../content/performance/commission-calcuation/comm-calc-imported-excel-errors-dialog/comm-calc-imported-excel-errors-dialog.component";
import { ShowImagesDialogComponent } from "./components/show-images-dialog/show-images-dialog.component";
import { SurveyFilterComponent } from './components/filters/survey-filter/survey-filter.component';
import { EditPriceComponent } from "./components/edit-price/edit-price.component";

@NgModule({
  declarations: [
    SharedTableComponent,
    ModalDialogComponent,
    ModalLoadingComponent,
    CustomerFilterComponent,
    MainFilterComponent,
    EmployeeFilterComponent,
    SharedItemComponent,
    EffectsDirective,
    FilterPipe,
    SharedItemsListFilterComponent,
    DivisionFilterComponent,
    DateTimePickerComponent,
    OnlyNumber,
    DropdownDirective,
    VehicleFilterComponent,
    WarehouseFilterComponent,
    WarehouseTransStatusFilterComponent,
    TakePromotionComponent,
    CurrencyFilterComponent,
    CustomerClassFilterComponent,
    CustomerSubClassFilterComponent,
    CustomerClassEntryComponent,
    CustomerTypeFilterComponent,
    CustomerGroupFilterComponent,
    CustomerChannelFilterComponent,
    SecurityGroupFilterComponent,
    PaymentTermFilterComponent,
    PaymentTermEntryComponent,
    KeysPipe,
    OperationSummaryComponent,
    EntryFormComponent,
    NumberOfDigitsRounder,
    AutoFocusDirective,
    SliderComponent,
    TextInputLanguagesComponent,
    BatchSelectionComponent,
    AddNewBatchComponent,
    NumberOfDigits,
    NumberOfStockDigits,
    NumberOfStockDigitsRounder,
    NumberOfStockDigitsFloorRounder,
    MainSideFilterComponent,
    SideFilterTemplateComponent,
    ItemsListComponent,
    ItemsSummaryComponent,
    SelectCustomersComponent,
    OrganizationFilterComponent,
    ItemsLazyLoadingFilterComponent,
    PriceListTypesFilterComponent,
    ItemCategoryFilterComponent,
    ChangeLanguageComponent,
    PackGroupItemsComponent,
    DeviceFilterComponent,
    RouteFilterComponent,
    WarehouseTypeFilterComponent,
    PackGroupFilterComponent,
    PackTypeFilterComponent,
    FillPackGroupItemsQuantityComponent,
    ServiceSetupDialogComponent,
    LocationFilterComponent,
    ItemsListsListComponent,
    ItemsListsEntryComponent,
    ItemsMultiselectFilterComponent,
    ImportDialogComponent,
    LocationFilterComponent,
    AddSerialsComponent,
    AddSubSerialsComponent,
    SerialTableComponent,
    InvalidSerialsComponent,
    ImportedExcelErrorsDialogComponent,
    CommCalcImportedExcelErrorsDialogComponent,
    ImportSerialsFromExcelComponent,
    VendorFilterComponent,
    CustomerPointsAdjustmentDialogComponent,
    AddItemsBySerialComponent,
    SSCCListComponent,
    SsccDetailsComponent,
    ScanBarcodeComponent,
    DateAgo,
    StockCountingTasksStatusFilterComponent,
    DisplayMultiTextComponent,
    AddItemsBySerialComponent,
    SerializedPromotedItemsComponent,
    TerritoryFilterComponent,
    TransactionTypeFilterComponent,
    DisplayUnitGroupFilterComponent,
    DisplayUnitStatusComponent,
    DisplayUnitTypeComponent,
    WebFormListComponent,
    WebFormEntryComponent,
    BankFilterComponent,
    PriceTypeFilterComponent,
    SelectItemsComponent,
    SharedDialogComponent,
    BrandFilterComponent,
    SingleClickDirective,
    SingleClickForEnterKeyDirective,
    DisplayUnitTemplateComponent,
    SelectSecondaryPackComponent,
    WebFormListMasterDataComponent,
    WebFormListRoiComponent,
    ItemsFilterComponent,
    SelectTerritoriesComponent,
    FormatTimePipe,
    TreeHierarchyEntryComponent,
    ActivationDeactivationReasonEntryComponent,
    ReasonFilterComponent,
    SelectDisplayUnitComponent,
    AfterReturnPromotionComponent,
    SelectPromotionsComponent,
    ViewItemsComponent,
    StockStatusFilterComponent,
    SalesOrderTypeFilterComponent,
    SelectCustomerGroupsComponent,
    KpiFilterComponent,
    CommissionCalcPriceComponent,
    ShowImagesDialogComponent,
    SurveyFilterComponent,
    EditPriceComponent
  ],
  imports: [
    CommonModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgbTooltipModule,
    MatCheckboxModule,
    ToastyModule,
    MatTabsModule,
    NgbModule,
    NgxSpinnerModule,
    TranslateModule.forChild(),
    AngularMultiSelectModule,
    NgMultiSelectDropDownModule,
    MatInputModule,
    NgMultiSelectDropDownModule,
    DragDropModule,
    QuillModule.forRoot(),
    IgxLinearGaugeModule,
    NgOptionHighlightModule,
    MatIconModule,
    MatTreeModule,
    MatButtonModule,
    MatChipsModule
  ],
  exports: [
    CommonModule,
    SharedTableComponent,
    ModalDialogComponent,
    ModalLoadingComponent,
    CustomerFilterComponent,
    MainFilterComponent,
    EmployeeFilterComponent,
    DivisionFilterComponent,
    VehicleFilterComponent,
    WarehouseFilterComponent,
    WarehouseTransStatusFilterComponent,
    DateTimePickerComponent,
    SharedItemComponent,
    CurrencyFilterComponent,
    CustomerClassFilterComponent,
    CustomerSubClassFilterComponent,
    CustomerTypeFilterComponent,
    PaymentTermFilterComponent,
    CustomerGroupFilterComponent,
    CustomerChannelFilterComponent,
    SecurityGroupFilterComponent,
    NgbTooltipModule,
    PaymentTermEntryComponent,
    OnlyNumber,
    ToastyModule,
    DropdownDirective,
    TakePromotionComponent,
    AfterReturnPromotionComponent,
    MatTabsModule,
    OperationSummaryComponent,
    NumberOfDigitsRounder,
    AutoFocusDirective,
    FilterPipe,
    SliderComponent,
    TextInputLanguagesComponent,
    BatchSelectionComponent,
    AddNewBatchComponent,
    NumberOfDigits,
    NumberOfStockDigits,
    ItemsListComponent,
    NumberOfStockDigitsRounder,
    NumberOfStockDigitsFloorRounder,
    MainSideFilterComponent,
    SideFilterTemplateComponent,
    ItemsSummaryComponent,
    SelectCustomersComponent,
    OrganizationFilterComponent,
    ItemsLazyLoadingFilterComponent,
    PriceListTypesFilterComponent,
    ItemCategoryFilterComponent,
    ChangeLanguageComponent,
    PackGroupItemsComponent,
    DeviceFilterComponent,
    WarehouseTypeFilterComponent,
    PackGroupFilterComponent,
    PackTypeFilterComponent,
    FillPackGroupItemsQuantityComponent,
    ServiceSetupDialogComponent,
    RouteFilterComponent,
    LocationFilterComponent,
    ImportDialogComponent,
    MatInputModule,
    ItemsListsListComponent,
    ItemsListsEntryComponent,
    MatInputModule,
    LocationFilterComponent,
    AddSerialsComponent,
    AddSubSerialsComponent,
    SerialTableComponent,
    InvalidSerialsComponent,
    ImportedExcelErrorsDialogComponent,
    CommCalcImportedExcelErrorsDialogComponent,
    ImportSerialsFromExcelComponent,
    VendorFilterComponent,
    CustomerPointsAdjustmentDialogComponent,
    ItemsMultiselectFilterComponent,
    SSCCListComponent,
    SsccDetailsComponent,
    ScanBarcodeComponent,
    DateAgo,
    AddItemsBySerialComponent,
    SerializedPromotedItemsComponent,
    TransactionTypeFilterComponent,
    DisplayUnitGroupFilterComponent,
    DisplayUnitStatusComponent,
    DisplayUnitTypeComponent,
    WebFormListComponent,
    WebFormEntryComponent,
    PriceTypeFilterComponent,
    SelectItemsComponent,
    SharedDialogComponent,
    BrandFilterComponent,
    SingleClickDirective,
    SingleClickForEnterKeyDirective,
    DisplayUnitTemplateComponent,
    SelectSecondaryPackComponent,
    ItemsFilterComponent,
    SelectTerritoriesComponent,
    FormatTimePipe,
    TerritoryFilterComponent,
    TreeHierarchyEntryComponent,
    ActivationDeactivationReasonEntryComponent,
    ReasonFilterComponent,
    SelectDisplayUnitComponent,
    SelectPromotionsComponent,
    ViewItemsComponent,
    StockStatusFilterComponent,
    SalesOrderTypeFilterComponent,
    SelectCustomerGroupsComponent,
    KpiFilterComponent,
    CommissionCalcPriceComponent,
    ShowImagesDialogComponent,
    SurveyFilterComponent,
    EditPriceComponent
  ],
  providers: [
    ValidationService,
    CalculationService,
    PdfCreatorService,
    ImageService,
    SettedUpResolver,
    DatePipe,
  ],
  entryComponents: [SharedItemsListFilterComponent],
})
export class SharedModule { }
