import { DateTimeModel } from "../dateTimeModel/date-time.model";
import { SalesTransactionTypes } from "../enums/sales-transaction-types";
import { ItemStockBatches } from "./item-stock-batches";
import { DataActions } from '../enums/data-actions.enum';
import { IOTSensorModel } from './iot-sensor.model';
import { CompetitorModel } from "./competitor.model";
import { ItemProductionDateTypes } from "../enums/item-production-date-types-enum";

export class ItemPackModel {
  // Item LEVEL
  itemId: number;
  itemCategoryId: number;
  divisionId: number;
  modelId: number;
  templateId: number;
  brandId: number;
  itemTypeId: number;
  availableQtyInPcs: number;
  vehicleAvailableQtyInPCs: number;
  warehouseAvailableQtyInPCs: number;
  onHandQty: number;
  reservedQty: number;
  stockQty: number;
  pendingQtyInPcs: number;
  piecesInPack: number;
  usedQtyPCs: number;
  usedPriceListId: number;
  itemName: string;
  itemCode: string;
  itemCodeName: string;
  category: string;
  model: string;
  brand: string;
  packDefinition: string;
  itemDefinition: string;
  divisionName: string;
  shelfLifeTime: number;
  stockTolerance:number;
  storageTolerance:number;
  weightTolerance:number;
  inactive: boolean;
  isInitiative: boolean;
  isBatchPriced: boolean;
  temporaryCredit: boolean;
  isDefaultBatchNo: boolean;
  isPowerSKU: boolean;
  isNew: boolean;
  isPriceProtected: boolean;
  forceDefaultPack: boolean;
  hasSerialNumber: boolean;
  hasSecondaryPack: boolean;
  secondaryPack: any;
  descriptionLanguages: any[];
  packs: PackModel[];
  secondaryPacks: any[];
  // Pack LEVEL
  packId: number;
  packTypeId: number;
  price: number;
  returnPrice: number;
  basePrice: number;
  discountString: string;
  discount: number;
  originalDiscount: number;
  promotedDiscount: number;
  itemPromotedDiscount: number;
  height: number;
  width: number;
  dipth: number;
  weight: number;
  allItemDiscount: number;
  originalAllItemDiscount: number;
  exciseContractPromotedDiscount: number;
  wholeSalesPromotedDiscount: number;
  dynamicPromotedDiscount: number;
  seqFactor: number;
  discountTypeId: number;
  tax: number;
  baseTax: number;
  foc: number;
  oldRequiredQty: number;
  oldRequiredFOCQty: number;
  oldRequiredReturnQty: number;
  requiredQty: number;
  requiredFOCQty: number;
  requiredReturnQty: number;
  barcode: string;
  batchNo: string;
  expiryDateString: string;
  uom: string;
  priceString: string;
  isImageChanged: boolean;
  hasImage: boolean;
  imagePath: string;
  netTotal: number;
  grossTotal: number;
  calculatedTax: number;
  calculatedRetailTax: number;
  calculatedDiscount: number;
  calculatedPromotedDiscount: number;
  calculatedDiscountTotal: number;
  isCalculated: boolean;
  expiryDateModel: DateTimeModel;
  expiryDateToView: any;
  batchNoToView: string;
  productionDateModel: DateTimeModel;
  salesTransactionTypeId: SalesTransactionTypes;
  consumerPrice: number;
  consumerPriceListId: number;
  fOCTypeId: number;
  reasonId: number;
  stockStatusId: number;
  itemStockBatches: ItemStockBatches[];
  selectedBatches: ItemStockBatches[];
  retailValue: number;
  packQuantityInLevels: number;
  childPack: ItemPackModel;
  childPackPrice: number;
  packHierarchyFilled: boolean;
  enableBatchForSerial: boolean;
  autoGenerateSubSerials: boolean;
  addedSerials: any[];
  addedFOCSerials: any[];
  availableSerialsForItemRecalledBySerial: any[];
  availableSerialsForItemRecalledByBatch: any[];
  recallScannedSerials: any[];
  sensors: IOTSensorModel[];
  gtin: string;
  // templateSerialStartIndex:number;
  // templateSerialEndIndex:number;
  templateSerialsIndecies: number[];
  templateBatchNoIndecies: number[];
  templateExpiryDateIndecies: number[];
  templateProductionDateIndecies: number[];
  templateGTINIndecies: number[];
  batchIncludedInTemplate: boolean;
  expiryIncludedInTemplate: boolean;
  productionIncludedInTemplate: boolean;
  totalFieldsLengthOfItemTemplate: number;
  recallId: number;
  recalledBySerial: boolean;
  recalledByBatch: boolean;
  existingQty: number;
  notes: string;
  isExceedAvailableQty: boolean;
  originalQuantity: number;
  requiredByLiter: boolean;
  startReading: number;
  endReading: number;
  invalidValue: boolean;
  packTrimSerialStartDigits: number;
  packTrimSerialEndDigits: number;
  packHasTrimSerialRule: boolean;
  isChecked: boolean;
  taxableAmount: number;
  retailCalculatedPromotedDiscount: number;
  wholeSalesCalPromotedDiscount: number;
  dynamicCalTargetPromotedDiscount: number;
  isFOCItem: boolean;
  vendorId?: number;
  isReturnItem: boolean;
  //competitor item:
  competitor: CompetitorModel;
  competitorsList: CompetitorModel[] = [];
  productionDateType: number;
  packStatusId: number;
  returnReasonId: number;
  expiryDateTimeModel?: DateTimeModel;
  customerPackBarcode?: string;
  organizationId?: number;
  organizationCodeName?: string;
  returnFromInvoiceAvailableBatchList?: any[];
  requestedQuantity: number;
  returnDiscountAmount: number;
  returnDiscountPercentage: number;
  totalProductDiscountQuantity: number;
  isFromProductDiscount: boolean;
  isReturnedAsDiscount: boolean;
  promotionRangeId?: Number;
  barcodeValues: any;
  packStatus: string;
  hasReturnReason: boolean;
  originalPrice: number;
  editedPrice: number;
  minPrice: number;
  maxPrice: number;

  constructor() {
    this.itemId = -1;
    this.itemName = '';
    this.itemCode = '';
    this.model = '';
    this.category = '';
    this.brand = '';
    this.uom = '';
    this.divisionName = '';
    this.itemId = -1;
    this.itemCategoryId = -1;
    this.modelId = -1;
    this.templateId = -1;
    this.brandId = -1;
    this.itemTypeId = -1;
    this.availableQtyInPcs = 0;
    this.vehicleAvailableQtyInPCs = 0;
    this.piecesInPack = 0;
    this.usedQtyPCs = 0;
    this.itemName = '';
    this.itemCode = '';
    this.model = '';
    this.category = '';
    this.brand = '';
    this.itemDefinition = '';
    this.divisionName = '';
    this.packs = [];
    this.secondaryPacks = [];
    this.descriptionLanguages = [];

    this.packDefinition = '';
    this.packId = -1;
    this.packTypeId = -1;
    this.price = 0;
    this.returnPrice = 0;
    this.basePrice = 0;
    this.discount = 0;
    this.originalDiscount = 0;
    this.discountTypeId = 1;
    this.tax = 0;
    this.baseTax = 0;
    this.foc = 0;
    this.requiredQty = 0;
    this.requiredFOCQty = 0;
    this.requiredReturnQty = 0;
    this.oldRequiredQty = 0;
    this.barcode = '';
    this.batchNo = '';
    this.uom = '';
    this.height = 0;
    this.width = 0;
    this.dipth = 0;
    this.weight = 0;

    this.netTotal = 0;
    this.grossTotal = 0;
    this.calculatedDiscount = 0;
    this.calculatedPromotedDiscount = 0;
    this.calculatedDiscountTotal = 0;
    this.calculatedTax = 0;
    this.isCalculated = false;
    this.salesTransactionTypeId = SalesTransactionTypes.None;
    this.consumerPrice = 0;
    this.consumerPriceListId = -1;
    this.fOCTypeId = -1;
    this.reasonId = -1;
    this.stockStatusId = -1;
    // this.expiryDate = new Date();
    // this.productionDate=new Date();
    this.expiryDateModel = new DateTimeModel();
    this.productionDateModel = new DateTimeModel();
    this.imagePath = 'assets/img/noProduct.jpg';
    this.hasImage = false;
    this.selectedBatches = [];
    this.itemStockBatches = [];
    this.hasSerialNumber = false;
    this.enableBatchForSerial = false;
    this.autoGenerateSubSerials = false;
    this.childPack = undefined;
    this.childPackPrice = 0;
    this.packHierarchyFilled = false;
    this.addedSerials = [];
    this.sensors = [];
    this.gtin = '';
    this.templateSerialsIndecies = [];
    this.templateBatchNoIndecies = [];
    this.templateProductionDateIndecies = [];
    this.templateExpiryDateIndecies = [];
    this.templateGTINIndecies = [];
    this.batchIncludedInTemplate = false;
    this.expiryIncludedInTemplate = false;
    this.productionIncludedInTemplate = false;
    this.totalFieldsLengthOfItemTemplate = 0;
    this.availableSerialsForItemRecalledBySerial = [];
    this.availableSerialsForItemRecalledByBatch = [];
    this.recallId = -1;
    this.originalQuantity = 0;
    this.requiredByLiter = false;
    this.startReading = 0;
    this.endReading = 0;
    this.packTrimSerialStartDigits = 0;
    this.packTrimSerialEndDigits = 0;
    this.packHasTrimSerialRule = false;
    this.isChecked = false;
    this.taxableAmount = 0;
    this.hasSecondaryPack = false;
    this.secondaryPack = null;
    this.isFOCItem = false;
    this.isReturnItem = false;
    this.seqFactor = 1;
    this.productionDateType = ItemProductionDateTypes.None.valueOf();
    this.packStatusId = -1;
    this.returnReasonId = -1;
    this.customerPackBarcode = "";
    this.organizationId = -1;
    this.returnFromInvoiceAvailableBatchList = [];
    this.requestedQuantity = -1;
    this.returnDiscountAmount = 0;
    this.returnDiscountPercentage = 0;
    this.totalProductDiscountQuantity = 0;
    this.isFromProductDiscount = false;
    this.isReturnedAsDiscount = false;
    this.packStatus = '';
    this.hasReturnReason = false;
    this.originalPrice = 0;
    this.editedPrice = 0;
    this.minPrice = 0;
    this.maxPrice = 0;
  }
}

// export class ItemPackEntryModel {
//     hasImage : boolean;
//     // Item LEVEL
//     itemId: number;
//     itemCategoryId: number;
//     divisionId: number;
//     modelId: number;
//     brandId: number;
//     itemTypeId: number;
//     availableQtyInPcs: number;
//     availableQtyFromPack: number;
//     shelfLifeTime: number;
//     piecesInPack: number;
//     usedQtyPCs: number;
//     itemName: string;
//     itemCode: string;
//     category: string;
//     model: string;
//     brand: string;
//     itemImage: string;
//     packDefinition: string;
//     itemDefinition: string;
//     divisionName: string;
//     inactive: boolean;
//     isInitiactive: boolean;
//     isBatchPriced: boolean;
//     temporaryCredit: boolean;
//     isDefaultBatchNo: boolean;
//     isPowerSKU: boolean;
//     isNew: boolean;
//     isPriceProtected: boolean;
//     forceDefaultPack : boolean;
//     deletedPacks:number[];
//     descriptionLanguages:any[];
//     // Pack LEVEL
//     packs :  PackModel[];

//     constructor() {
//         this.packs = [];
//         this.itemId = -1;
//         this.itemName = '';
//         this.itemCode = '';
//         this.model = '';
//         this.category = '';
//         this.brand = '';
//         this.divisionName = '';
//         this.itemId = -1;
//         this.itemCategoryId = -1;
//         this.modelId = -1;
//         this.brandId = -1;
//         this.itemTypeId = -1;
//         this.availableQtyFromPack = 0;
//         this.availableQtyInPcs = 0;
//         this.piecesInPack = 0;
//         this.usedQtyPCs = 0;
//         this.itemName = '';
//         this.itemCode = '';
//         this.model = '';
//         this.category = '';
//         this.brand = '';
//         this.itemDefinition = '';
//         this.divisionName = '';
//         this.packDefinition = '';
//         this.itemId = -1;
//     }
// }

export class PackModel {
  dataAction: DataActions;
  packId: number;
  packTypeId: number;
  price: number;
  basePrice: number;
  discountString: string;
  discount: number;
  promotedDiscount: number;
  itemPromotedDiscount: number;
  allItemDiscount: number;
  discountTypeId: number;
  tax: number;
  baseTax: number;
  foc: number;
  oldRequiredQty: number;
  quantity: number;
  packBarcode: string;
  batchNo: string;
  expiryDateString: string;
  serialSeparator: string;
  uom: string;
  priceString: string;
  packTypeName: string;
  hasImage: boolean;
  imagePath: string;
  netTotal: number;
  grossTotal: number;
  calculatedTax: number;
  calculatedDiscount: number;
  calculatedPromotedDiscount: number;
  calculatedDiscountTotal: number;
  isCalculated: boolean;
  expiryDateModel: DateTimeModel;
  productionDateModel: DateTimeModel;
  salesTransactionTypeId: SalesTransactionTypes;
  consumerPrice: number;
  consumerPriceListId: number;
  fOCTypeId: number;
  reasonId: number;
  stockStatusId: number;
  hasSerialNumber: boolean;
  isWeightPriced: boolean;
  isDefaultPack: boolean;
  packGroupsList: any[];
  packOperationsList: any[];
  templateId: number;
  constructor() {
    this.dataAction = DataActions.NoChange;
    this.uom = '';
    this.packId = -1;
    this.packTypeId = -1;
    this.price = 0;
    this.basePrice = 0;
    this.discount = 0;
    this.discountTypeId = 1;
    this.tax = 0;
    this.baseTax = 0;
    this.foc = 0;
    this.quantity = 0;
    this.oldRequiredQty = 0;
    this.packBarcode = '';
    this.batchNo = '';
    this.uom = '';
    this.netTotal = 0;
    this.grossTotal = 0;
    this.calculatedDiscount = 0;
    this.calculatedPromotedDiscount = 0;
    this.calculatedDiscountTotal = 0;
    this.calculatedTax = 0;
    this.isCalculated = false;
    this.hasSerialNumber = false;
    this.packGroupsList = [];
    this.isWeightPriced = false;
    this.salesTransactionTypeId = SalesTransactionTypes.None;
    this.consumerPrice = 0;
    this.consumerPriceListId = -1;
    this.fOCTypeId = -1;
    this.reasonId = -1;
    this.stockStatusId = -1;
    this.serialSeparator = '';
    this.expiryDateModel = new DateTimeModel();
    this.productionDateModel = new DateTimeModel();
    this.imagePath = 'assets/img/noProduct.jpg';
    this.hasImage = false;
    this.templateId = -1;
  }
}

export class ItemDivisionModel {
  divisionCode: string;
  description: string;
  divisionCodeName: string;
  divisionId: number;
  constructor() {
  }
}

export class ItemCategoryModel {
  code: string;
  name: string;
  nameCodeString: string;
  itemCategoryId: number;
  divisionId: number;
  constructor() {
  }
}
