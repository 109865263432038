import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MasterDataService } from '../../../../content/master-data-definition/master-data.service';
import { ConstantMessages } from '../../../models/constants/constant-message';

@Component({
  selector: 'app-security-group-filter',
  templateUrl: './security-group-filter.component.html',
  styleUrls: ['./security-group-filter.component.css']
})
export class SecurityGroupFilterComponent implements OnInit {

  @Input() isMultiSelect = false;
  @Input() parentForm: FormGroup;
  @Input() emitIdAndCaption=false;
  @Output() selectedSecurityGroup = new EventEmitter();
  @Output()
  onChangeIdCaptionSecurityGroup=new EventEmitter();
  @Input() filterSeucrityGroup : any;
  @Input() isRestrictSecurityGroup : boolean = false;
  securityGroupList: any[];
  isSecurityGroupLoading = false;
  isRequired = false;
  dropdownSettings = {
    singleSelection: false,
    idField: 'securityGroupId',
    textField: 'securityGroupIdName',
    selectAllText: '',
    unSelectAllText: '',
    position: 'top',
    allowSearchFilter: true,
    searchPlaceholderText: '',
    itemsShowLimit: 2
  };
  constructor(private masterDataService: MasterDataService, private translateService : TranslateService) { 
  }
  ngOnInit() {
    var controlName = 'securityGroupId';
    if(this.isMultiSelect){
      controlName = 'securityGroupIds';
      this.dropdownSettings.selectAllText = this.translateService.instant(ConstantMessages.SelectAllCaption);
      this.dropdownSettings.unSelectAllText = this.translateService.instant(ConstantMessages.UnSelectAllCaption);
      this.dropdownSettings.searchPlaceholderText = this.translateService.instant(ConstantMessages.SearchCaption);
  
    }
    if(this.parentForm.get(controlName).validator != null){
      const validator = this.parentForm.get(controlName).validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populateSecurityGroupsList();
  }
  populateSecurityGroupsList() {
    this.isSecurityGroupLoading = true;
    let filter = {
      organizationId : -1,
      employeeId : -1
    }
    this.masterDataService.getSecurityGroupsList(filter).subscribe(result => {
      this.securityGroupList = result;
      if(this.filterSeucrityGroup && this.isRestrictSecurityGroup){ // to filter SecurityGroup that already has keys already in key-Privilege Screen
        this.securityGroupList = this.securityGroupList.filter(SG => {
          return !this.filterSeucrityGroup.some(securityGroupId => securityGroupId === SG.securityGroupId);
        });
      }
      this.isSecurityGroupLoading = false;
    });
}
  onChangeSecurityGroup(selectedSecurityGroup:any) {
    if(!this.emitIdAndCaption)
    this.selectedSecurityGroup.emit(selectedSecurityGroup);
    else{
      let event = undefined;
      if(selectedSecurityGroup !== null && selectedSecurityGroup !== undefined) {
        event={
          id:selectedSecurityGroup.securityGroupId,
          caption:selectedSecurityGroup.securityGroupIdName
        }
      }
      this.onChangeIdCaptionSecurityGroup.emit(event);
    }
  }
}

