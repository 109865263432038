import { ItemPackModel } from "../Item/item.model";
import { DateTimeModel } from "../dateTimeModel/date-time.model";
import { WarehouseTransactionStatus } from "../enums/warehouse-transaction-status.enum";
import { TransactionMainTypes } from "../enums/transaction.main.types.enum";


export class WarehouseTransaction {
    signature:string;
    transactionId:string;
    warehouseId:number;
    refWarehouseId:number;
    employeeId:number;
    transactionTypeId:number;
    whTransactionStatusId:number;
    whTransactionOperationId:number;
    whTransactionCreationReasonId:number;
    //transactionDate:Date;
    transactionDateModel:DateTimeModel;
    receiptDateModel: DateTimeModel;
    //loadDate:Date;
    loadDateModel:DateTimeModel;
    sourceTransactionId:string;
    divisionId:number;
    loadBy:number;
    purchaseOrderId:string;
    truckId:number;
    approvalDate:Date;
    executionDate:Date;
    requestedBy:number;
    implementedBy:number;
    notes:string;
    creationSource:number;
    supervisorId:number;
    helperId:number;
    salesManagerId:number;
    organizationId:number;
    driverId:number;
    salesRepId:number;
    DriverName:string;
    vehiclePlateNumber:string;
    gatePassNumber:string;
    outVoucherReasonId:number;
    synchronized:boolean;
    whTransStatusDescription:string;
    employee:string;
    vehicle:string;
    addedItems:ItemPackModel[];
    newStatusId:number;
    employeeCodeName:string;
    refWarehouseNameCode:string;
    warehouseNameCode:string;
   truckNameCode:string;
    transactionMainTypes: TransactionMainTypes;
    tripId : number;
    isNewTransaction:boolean;
    allowBatchesInItems:boolean;
    routeHistoryId : number;
    sourceOrderId:string;
    readingId:number;
    vendorId:number;
    lpoNumber:string;
    wHTransactionStatusFromUI:number;
    vendorCodeName:string;
    documentReasonId?: number;
    documentReason?:string;
    customerId:number;
    outletId:number;
    destinationCustomerID:number;
    destinationOutletID: number;
    formProcessModeId: number;
    constructor(){
        this.routeHistoryId = -1;
        this.allowBatchesInItems = false;

        this.tripId = -1;
        this.signature='';
        this.transactionId='';

        this.warehouseId=-1;
        this.refWarehouseId=-1;
        this.employeeId=-1;
        this.transactionTypeId=0;
        this.whTransactionStatusId=0;
        this.whTransactionOperationId=0;
        this.whTransactionCreationReasonId=0;
        this.sourceTransactionId='';
        this.divisionId=-1;
        this.loadBy=-1;
        this.purchaseOrderId='';

        this.requestedBy=-1;
        this.implementedBy=-1;
        this.notes='';
        this.creationSource=5;
       this. supervisorId=-1;
        this.helperId=-1;
        this.salesManagerId=-1;
        this.organizationId=-1;
       this. driverId=-1;
        this.salesRepId=-1;
        this.DriverName='';
        this.vehiclePlateNumber='';
        this.gatePassNumber='';
        this.outVoucherReasonId=-1;
        this.synchronized=false;
        this.newStatusId=WarehouseTransactionStatus.Pending.valueOf();
        this.employeeCodeName='';
        this.refWarehouseNameCode='';
        this.warehouseNameCode='';
        this.addedItems=[];
        this.isNewTransaction=true;
        this.sourceOrderId='';
        this.vendorId=-1;
        this.lpoNumber='';
        this.vendorCodeName='';
    }
}
export class WarehouseTransactionStatusModel{
    warehouseTransactionStatusId:number;
description:string;
}
