import { CustomerValidations as CustomerValidationsModel } from "../enums/customer-validations";
import { CustomerGroupModel } from "./customer.group.model";
import { TerritoryModel } from "../route/territory.model";
import { RouteModel } from "../route/route.model";
import { DataActions } from "../enums/data-actions.enum";
import { TextLanguage } from "../general/textLanguage.model";

export class CustomerOutletModel {
  customerIdSignature: string;
  outletIdSignature: string;
  customerOutletSignature: string;
  outletCode: string = '';//ok
  outletName: string = '';//ok
  extraInfoLink: string = '';//ok
  outletCodeName: string = '';
  barcode: string;
  customerTypeId: number = 1;//ok
  paymentTermId: number = 0;//ok
  billsNumber: number = 0;//ok
  currencyId: number;
  groups: CustomerGroupModel[];
  territories: TerritoryModel[];
  routes: RouteModel[];
  fax: string = '';//ok
  email: string = '';//ok
  phone: string = '';//ok
  streetId: number = -1;
  address: string = '';//ok
  address2: string = '';//ok
  inactive: boolean = false;//ok
  onHold: boolean = false;//ok
  isHighlighted: boolean = false;//ok
  taxable: boolean = false;//ok
  commissionerOfReceipt: string = '';//ok
  vendorNumber: string = '';//ok
  taxNumber: string = '';//ok
  gln: string = '';
  tradeLicenceNumber: string = '';
  customerClassId: number = -1;//ok
  customerSubClassId: number = -1;//ok
  notes: string = '';//ok
  dataAction: DataActions = DataActions.NoChange;
  customerValidations: CustomerValidationsModel;
  attachedFiles: any;
  machineTypeId: number;
  machineData: any;

  account: any;
  accountsList: any[];
  outletId: number;
  customerId: number;
  iCashBalance: number;
  hasICashBalance: boolean;

  descriptionLanguages: TextLanguage[];
  routesList: RouteModel[];
  outletDivisionPaymentTermList: any[];
  gpsLatitude: string;
  gpsLongitude: string;
  addressLanguages: TextLanguage[];
  address2Languages: TextLanguage[];
  areaId: number;
  cityId: number;
  stateId: number;
  countryId: number;
  zipCode: string;
  operatorName: string;
  operatorPassword: string;
  orginalOperatorName: string;
  orginalOperatorPassword: string;
  operatorId: number = -1;
  tempOperatorId: number = -1;
  matchWithExistsOperator: boolean;
  isLockedOperator: boolean;
  orginalIsLockedOperator: boolean;
  isNeedToSaveOperator: boolean;
  isMain: boolean;
  customerOutletContacts: CustomerOutletContactModel[];
  outletAttachments: string[];
  dummyOutletID: number = -1;
  selected: boolean;
  territoryRouteList: any[];
  territoryIdList: any[];
  hasPrivilegeOnPaymentTermDivision: boolean;
  hasPrivilegeOnOnHoldDivision: boolean;
  onHoldDivisionsList: any[];
  isThereAnyGroupAchievementPromotions?: boolean;
  includedGroupAchievementPromotions?: any[];
  excludedGroupAchievementPromotions?: any[];
  customerGroupsInDB?: any[];
  landmark: string = '';
  buildingNumber: string;
  isBusinessCustomer: boolean;
  CustomerOutletModel() {
    this.outletDivisionPaymentTermList = [];
    this.routesList = [];
    this.hasICashBalance = false;
    this.outletId = -1;
    this.customerId = -1;
    this.groups = [];
    this.customerValidations = new CustomerValidationsModel();
    this.outletCodeName = '';
    this.streetId = -1;
    this.customerIdSignature = '';
    this.outletIdSignature = '';
    this.customerOutletSignature = '';
    this.territories = [];
    this.routes = [];
    this.dataAction = DataActions.NoChange;
    this.machineTypeId = -1;
    this.descriptionLanguages = [];
    this.gln = '';
    this.gpsLatitude = '';
    this.gpsLongitude = '';
    this.addressLanguages = [];
    this.address2Languages = [];
    this.areaId = -1;
    this.cityId = -1;
    this.stateId = -1;
    this.countryId = -1;
    this.operatorId = -1;
    this.customerOutletContacts = [];
    this.outletAttachments = [];
    this.selected = false;
    this.onHoldDivisionsList = [];
    this.isThereAnyGroupAchievementPromotions = false;
    this.customerGroupsInDB = [];
    this.landmark = '';
    this.isBusinessCustomer = false;
  }

}

export class CustomerOutletContactModel {
  contactId: number;
  contactName: string;
  mobile: number;
  phone: number;
  fax: number;
  street: number;
  streetName: string;
  address: string;
  email: string;
  contactDescriptionsLanguages: TextLanguage[];
  addressDescriptionsLanguages: TextLanguage[];
  languageId: number;

  CustomerOutletContactModel() {
    this.contactId = -1;
    this.contactName = '';
    this.mobile = 0;
    this.phone = 0;
    this.fax = 0;
    this.street = 0;
    this.streetName = '';
    this.address = '';
    this.email = '';
    this.contactDescriptionsLanguages = [];
    this.addressDescriptionsLanguages = [];
    this.languageId = -1;
  }
}
