import { CoreSession } from "./../../../core/core.session";
import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { ResponseModel } from "../../../shared/models/api-models/api-models";
@Injectable({
  providedIn: 'root'
})
export class SurveyService {


  private url = "";
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    this.url = baseUrl + "Survey";
  }
  //#region [LOV]
  // getLOVByID(LOVID: any) {
  //   const urlValue: string = this.url + "/GetLOVByID";
  //   return this.http.post<ResponseModel>(urlValue, LOVID)
  //     .map(response => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     });
  // }


  // getLOVs() {
  //   const urlValue: string = this.url + "/GetLOVs";
  //   return this.http.post<ResponseModel>(urlValue, "").map(response => {
  //     const result = new ResponseModel();
  //     result.data = response['data'];
  //     result.message = response['message'];
  //     result.status = response['status'];
  //     return result;
  //   });
  // }
  //#endregion
  getAllCustomerSurveys(filterModel: any) {
    const urlValue: string = this.url + "/getAllCustomerSurveys";
    return this.http.post<ResponseModel>(urlValue, filterModel).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }
  // getInspectorSurveys(filterModel: any) {
  //   const urlValue: string = this.url + "/GetInspectorSurveys";
  //   return this.http.post<ResponseModel>(urlValue, filterModel).map(response => {
  //     const result = new ResponseModel();
  //     result.data = response['data'];
  //     result.message = response['message'];
  //     result.status = response['status'];
  //     return result;
  //   });
  // }
  FillUI(surveyTypeId: number) {
    const urlValue: string = this.url + "/FillUI";
    return this.http.post<ResponseModel>(urlValue, surveyTypeId).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }

  SaveCustomerSurvey(surveyObject: any) {
    const urlValue: string = this.url + "/InsertCustomerSurvey";
    return this.http.post<ResponseModel>(urlValue, surveyObject).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }

  getFieldGroups() {
    const urlValue: string = this.url + '/getFieldGroups';
    return this.http
      .post<ResponseModel>(urlValue, "")
      .map(
        (response) => {
          const res = new ResponseModel();
          res.data = response['data'];
          res.message = response['message'];
          res.status = response['status'];
          return res;
        }
      );
  }

  UpdateCustomerSurvey(surveyObject: any) {
    const urlValue: string = this.url + "/UpdateCustomerSurvey";
    return this.http.post<ResponseModel>(urlValue, surveyObject).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }

  getSurveyQuestions(dCFID: number) {
    const urlValue: string = this.url + "/getSurveyQuestions";
    return this.http.post<ResponseModel>(urlValue, dCFID).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }

  // getSurveyData(surveyId: number, dcfid: number, warehouseId: number, readingId: number, organizationId: number, isClone: boolean) {
  //   let filter = {
  //     surveyId: surveyId,
  //     dcfid: dcfid,
  //     warehouseId: warehouseId,
  //     readingId: readingId,
  //     organizationId: organizationId,
  //     isClone: isClone
  //   }
  //   const urlValue: string = this.url + '/GetSurveyData';
  //   return this.http.post<ResponseModel>(urlValue, filter)
  //     .map(
  //       (response) => {
  //         return response;
  //       }
  //     );
  // }
  getAppliedList(surveyId: any) {
    const urlValue: string = this.url + "/getAppliedList";
    return this.http.post<ResponseModel>(urlValue, surveyId).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }
  getQualityControlAppliedList(surveyId: any) {
    const urlValue: string = this.url + "/GetQualityControlAppliedList";
    return this.http.post<ResponseModel>(urlValue, surveyId).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }
  getEmployeeAppliedList(surveyId: any) {
    const urlValue: string = this.url + "/getEmployeesAppliedList";
    return this.http.post<ResponseModel>(urlValue, surveyId).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }

  getDisplayUnitAppliedList(surveyId: number) {
    const urlValue: string = this.url + "/GetDisplayUnitAppliedList";
    return this.http.post<ResponseModel>(urlValue, surveyId).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }

  saveGroup(group: any) {
    let filter = {
      groupId: -1,
      groupName: group
    };
    const urlValue: string = this.url + "/InsertGroup";
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }

  AllowDeleteQuestion(fieldID: any, surveyTypeId: any) {
    let filter = {
      fieldID: fieldID,
      surveyTypeId: surveyTypeId
    };
    const urlValue: string = this.url + "/AllowRemoveQuestion";
    return this.http.post<ResponseModel>(urlValue, filter)
      .map(response => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }

  DeleteSurvey(surveyId: any, dcfid: any, surveyTypeId: number, linkedToPromotion: boolean) {
    let filter = {
      surveyId: surveyId,
      dcfid: dcfid,
      surveyTypeId: surveyTypeId,
      linkedToPromotion: linkedToPromotion
    };
    const urlValue: string = this.url + "/DeleteSurvey";
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }

  getQuestionsDepend(questionModel: any) {
    const urlValue: string = this.url + "/GetQuestionsDepend";
    return this.http.post<ResponseModel>(urlValue, questionModel)
      .map(response => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }
  getLOVOptions(lOVId: number) {
    const urlValue: string = this.url + "/GetLOVOptions";
    return this.http.post<ResponseModel>(urlValue, lOVId).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }
  getSurveyPeriodTypes(surveyTypeId: Number) {
    const urlValue: string = this.url + "/GetSurveyPeriodTypes";
    return this.http.post<ResponseModel>(urlValue, surveyTypeId).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }

  getSurveyPeriods(surveyId: number) {
    const urlValue: string = this.url + "/GetSurveyPeriods";
    return this.http.post<ResponseModel>(urlValue, surveyId)
      .map(response => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }

  // saveQualityControlReading(surveyReadingModel: any) {
  //   const urlValue: string = this.url + "/InsertQualityControlReading";
  //   return this.http.post<ResponseModel>(urlValue, surveyReadingModel).map(response => {
  //     const result = new ResponseModel();
  //     result.data = response['data'];
  //     result.message = response['message'];
  //     result.status = response['status'];
  //     return result;
  //   });
  // }
  getSurveyMenusImages() {
    const urlValue = this.url + "/GetSurveyMenusImages";
    return this.http.post<ResponseModel>(urlValue, "").map(
      (response) => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      }
    );
  }

  executeCloneSurveyProcedure(surveyId: number) {
    const urlValue: string = this.url + "/CloneSurveyProcedure";
    return this.http.post<ResponseModel>(urlValue, surveyId).map(response => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    });
  }
  GetLinkedToPromotionSurveys(promotionId: number) {
    const urlValue: string = this.url + "/GetLinkedToPromotionSurveys";
    return this.http.post<ResponseModel>(urlValue, promotionId)
      .map(response => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      });
  }
  getSurveys(SurveyTypeId) {
    const urlValue: string = this.url + '/GetSurveys';
    return this.http.post<ResponseModel>(urlValue, SurveyTypeId).map(response => {
      return response.data;
    });
  }
}
