import { ErrorHandler } from '@angular/core';

export class GlobalErrorHandler implements ErrorHandler {
    handleError(error) {
        console.log("hello from GlobalErrorHandler");
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            console.log("hello from GlobalErrorHandler from inside the if");
            // window.location.reload();
        }
    }
}