export const ConstantMessages = {
  ErrorHappened: 'Desc_Error_Happened',
  ErrorLargeSize: 'Desc_File_Large_Size',
  ErrorCaption: 'Desc_Error_Caption',
  EmailCaption: 'Desc_Email_Sent_Successfully',
  EmailFailedCaption: 'Desc_Email_Sent_Failed',
  NotificationFailedCaption: 'Desc_Notification_Sent_Failed',
  SuccessCaption: 'Desc_Success_Caption',
  WarningCaption: 'Desc_Warning_Caption',
  MsgAllcustomerOrCustomerGroupOnlyAllowToExcludeCustomer: 'Desc_Allcustomer_Or_CustomerGroup_Only_Allow_To_Exclude_Customer',
  AfterVisit: 'Desc_After_Visit',
  BeforVisit: 'Desc_Befor_Visit',
  MsgLoadRequestSuccess: 'Desc_Load_Request_approved',
  MsgEmployeeExceedsSalesRestriction: 'MsgEmployeeExceedsSalesRestriction',
  MsgVehicleUploadedForExecuteOffLoad: 'MsgVehicleUploadedForExecuteOffLoad',
  MsgCustomerExceedsSalesRestriction: 'MsgCustomerExceedsSalesRestriction',
  MsgAddedMobileNumberAlreadyExists: 'Desc_Added_Mobile_Number_Already_Exists',
  MsgAddedPhoneNumberAlreadyExists: 'Desc_Added_Phone_Number_Already_Exists',
  MsgAddedFaxNumberAlreadyExists: 'Desc_Added_Fax_Number_Already_Exists',
  MsgAddedEmailAlreadyExists: 'Desc_Added_Email_Already_Exists',
  MsgFillAtLeastOneOfContactFields: 'Desc_Fill_At_Least_One_Of_Contact_Detail_Fields',
  MsgYouCantInsertDuplicateNumbers: 'Desc_Sorry_you_cant_insert_duplicated_amount_to_buy',
  MsgPleaseFillAllAmountsInTheValueslist: 'Desc_Please_fill_all_amounts_in_the_values_list',
  MsgEmployeeExceedsSalesRestrictionWithParameters: 'MsgEmployeeExceedsSalesRestrictionWithParameters',
  MsgCustomerExceedsSalesRestrictionWithParameters: 'MsgCustomerExceedsSalesRestrictionWithParameters',
  MsgQtyExceedsAllowedEmployeeLimit: 'MsgQtyExceedsAllowedEmployeeLimit',
  MsgQtyExceedsAllowedCustomerLimit: 'MsgQtyExceedsAllowedCustomerLimit',
  MsgSavedSuccessfuly: 'Desc_Saved_Successfully',
  MsgUpdatedSuccessfuly: 'Desc_Updated_Successfuly',
  MsgSelectItem: 'Desc_Select_Item',
  MsgSelectPack: 'Desc_Select_Pack',
  MsgSelectKey: 'Desc_Select_Key',
  MsgSelectPromotion: 'Desc_Select_Promotion',
  MsgItemAlreadyexist: 'Desc_Item_Already_exist',
  MsgSelectItemCategory: 'Desc_Select_Item_Category',
  MsgSelectCurrency: 'Desc_Select_Currency',
  MsgNoItemsFound: 'Desc_No_Items_Found',
  MsgMissingAttachment: 'Desc_Missing_Attachment',
  MsgDeleteConfirmation: 'Desc_Confirmation_Delete',
  MsgChangeSecurityGroupConfirmation: 'Desc_Change_Security_Group',
  MsgUnlockConfirmation: 'Desc_Confirmation_Unlock',
  MsgPromotionNotification: 'Desc_Promotion_Notification',
  MsgChangeKPISubTypeConfirmation: 'Desc_Confirmation_Change_KPISubtype',
  MsgResetSurveyPeriodList: 'Desc_Survey_Period_List_Reset_Message',
  MsgApproveConfirmation: 'Desc_Approve_Confirmation',
  MsgExecuteConfirmation: 'Desc_Execute_Confirmation',
  MsgConfirmConfirmation: 'Desc_Confirm_Confirmation',
  MsgCancelConfirmation: 'Desc_Cancel_Confirmation',
  MsgDeletedSuccessfully: 'Desc_Deleted_Successfully',
  MsgUnlockedSuccessfully: 'Desc_Unlock_Successfully',
  MsgCancelPromotionConfirmation: 'Desc_Confirmation_Cancel_Promotion',
  MsgRejectConfirmation: 'Desc_Confirmation_Reject',
  MsgSelectedInvoiceVoided: 'Desc_Selected_Invoice_Voided',
  MsgVoidConfirmation: 'Desc_Confirmation_Void',
  MsgVoidReturnConfirmation: 'Desc_Confirmation_Void_Return',
  MsgNoRowSelected: 'Desc_No_Row_Selected',
  InvalidUsernamePassword: 'InValid Username or Password',
  QuantityInsertedExceedStock: 'The inserted quantity exceeds stock quantity',
  InvalidZeroPrice: 'Desc_You_can_not_insert_item_with_zero_price',
  MsgNoEmployeeAccount: 'Desc_No_Employee_Account',
  MsgNoCustomerAccount: 'Desc_No_Customer_Account',
  MsgCustomerViolatePaymentTerms: 'Desc_Customer_Violate_Payment_Terms',
  MsgCustomerExceedsCreditLimit: 'Desc_Customer_Exceeds_Credit_Limit',
  MsgOnHoldCustomer: 'Desc_OnHold_Customer',
  MsgNoDocumentSequence: 'Desc_No_Document_Sequence',
  MsgCantEditFreeItem: 'Desc_Cant_Edit_Free_Item',
  MsgCantCancelLoadRequestWithTrip: 'Desc_Cant_Cancel_LoadRequest_With_Trip',
  MsgDesc_Cant_Execute_LoadRequest_With_Trip: 'Desc_Cant_Execute_LoadRequest_With_Trip',
  Desc_Cant_Cancel_Move_Order_With_Trip: 'Desc_Cant_Cancel_Move_Order_With_Trip',
  MsgClearFilledDataConfirmation: 'Desc_Clear_Filled_Confirmation',
  MsgClearFilledPackGroupConfirmation: 'Desc_Clear_Filled_Group_Confirmation',
  MsgDisplayUnitNoCustomer: 'Desc_Display_Unit_No_Customer_Warnings',
  MsgChangeOrganization: 'Desc_Change_Organization',
  MsgFillOperatorDataCorrectly: 'Desc_Fill_Operator_Data_Correctly',
  MsgPreviousSerialsWillBeOverwritten: "Msg_Previous_Serials_Will_Be_Overwritten",
  MessageCannotDeleteOperatorPrivilege: 'Desc_Cant_Delete_Operator_Privilege',
  MsgCantSaveSecurityGroupsWithoutOperator: 'Desc_Cant_Save_Security_Without_Operator',
  MessageCannotDeleteOperator: 'Desc_Cant_Delete_Operator',
  MsgPasswordsNotMatches: 'Desc_Passwords_Not_Matches',
  MsgFillNumberOfMin: "Desc_Fill_Minutes",
  MsgSelectDate: "Desc_Please_Select_Date",
  MsgFillMandatory: 'Desc_Fill_Mandatory',
  MsgFillOneReportTypeAtLeast: 'Desc_Fill_One_Report_Type_At_Least',
  MsgNoBaseCurrencyForSelectedOrg: 'Desc_No_Base_Currency_For_Selected_Org',
  MsgNotValidToApprove: 'Desc_Not_Valid_To_Approve',
  MsgRefreshData: 'Desc_Refresh_Data',
  MsgNotValidToEdit: 'Desc_Not_Valid_To_Edit',
  MsgCantEditCustomers: 'Desc_Cant_Edit_Customers',
  MsgNotValidToDelete: 'Desc_Not_Valid_To_Delete',
  MsgFillBatchOrExpiry: 'Desc_Fill_Batch_Or_Expiry',
  MsgFillItemListItems: 'Desc_Define_Item_List_Items',
  MsgFillAtLeastAppliedOnOneType: 'Desc_Fill_At_Least_One_AppliedOn_Type',
  MsgFieldLength: 'Desc_Msg_Field_Length',
  MsgUsedField: 'Desc_Msg_Used_Field',
  MsgZeroLength: 'Desc_Msg_Zero_Length',
  Desc_Invalid_Operation: 'Desc_Invalid_Operation',
  MsgFillMachineData: 'Desc_Fill_Machine_Data',
  MsgClearMachineDataConfirmation: 'Desc_Machine_Data_Will_Cleared',
  MsgUniqueMachineSerialNumber: 'Desc_Unique_Machine_Serial',
  MsgUniqueMachineIMEINumber: 'Desc_Unique_Machine_IMEI',
  MsgOnlyAvlSerialMayBeDeleted: 'Desc_Only_Avl_Serial_May_Be_Deleted',
  MsgNoSerialsAdded: 'Desc_No_Serials_Added',
  MsgNoSectionsAdded: 'Desc_No_Sections_Added',
  MsgFillDetails: 'Desc_Fill_Details',
  MsgCantDeleteUsedBox: 'Desc_Cant_Delete_Used_Box',
  LblCashBox: 'Desc_Cash_Box',
  LblCoins_Box: 'Desc_Coins_Box',
  LblAllBoxes: 'Desc_All_Boxes',
  LblAll: 'Desc_All',
  MsgMinExceedsMax: 'Desc_Min_Exceeds_Max',
  MsgDefineDefaultPack: 'Desc_Define_Default_Pack',
  MsgDefineItemPacks: "Desc_Define_Item_Packs",
  MsgDefineMinPackQty: 'Desc_Define_Min_Pack',
  MsgFillMandatoryForPacks: "Desc_Fill_Mandatory_For_Packs",
  MsgPackGroupNotMatchPackQuantity: 'Desc_Diff_Qty_Items',
  MsgUniquePackType: 'Desc_Unique_Pack_Type',
  MsgUniquePackQuantity: 'Desc_Unique_Pack_Qty',
  MsgUniqueSerial: 'Desc_Unique_Serial',
  MsgUniqueName: 'Desc_Unique_Name',
  MsgEmailSyntax: 'Desc_Email_Syntax',
  MsgCannotDeleteThisDivisionBecauseItsLinkedToAccountsOrDocSeq: 'Desc_You_Cannot_Delete_This_Division_Because_Its_Linked_To_Accounts_Or_DocSeq',
  MsgCantDeleteAcountNoneZeroBalance: 'Desc_Cant_Delete_Acount_None_Zero_Balance',
  MsgAccountcreditexceedetheparentcreditlimit: 'Desc_Account_credit_exceede_the_parent_credit_limit',
  MsgYouhavetofillorganizationanddivisions: 'Desc_You_have_to_fill_organization_and_divisions',
  MsgUniqueCode: 'Desc_Unique_Code',
  MsgDivisionCode: 'Desc_Code_Exist',
  MsgUniqueBarcode: 'Desc_Unique_Barcode',
  MsgMustBeUnique: 'Desc_Msg_Must_Be_Unique',
  MsgUniqueUserName: 'Desc_Unique_User_Name',
  MsgEmployeeIsInactive: 'Desc_Employee_Inactive',
  MsgReactivateConfirmation: 'Desc_Reactivate_Confirmation',
  MsgDeactivateConfirmation: 'Desc_Deactivate_Confirmation',
  MsgCustomerInactive: 'Desc_Inactive_Confirmation',
  MsgEnableConfirmation: 'Desc_Enable_Confirmation',
  MsgDisableConfirmation: 'Desc_Disable_Confirmation',
  MsgSaveTerritoryBefore: 'Desc_Save_Territory_Before',
  MsgDoYouWantToFullyOffloadFromVehicle: 'Desc_MsgDoYouWantToFullyOffloadFromVehicle',
  SelectAllCaption: 'Desc_Select_All',
  UnSelectAllCaption: 'Desc_UnSelect_All',
  LblBasicData: 'Desc_Basic_Data',
  LblRelatedData: 'Desc_Related_Data',
  LblDocumentSequenceData: 'Desc_Document_Sequence_Data',
  LblSecurityData: 'Desc_Security_Data',
  LblNone: 'Desc_None',
  LblAdd: 'Desc_Add',
  SearchCaption: 'Desc_Search',
  InsertCustomer: 'Desc_Insert_Customer',
  MsgDesc_Delete_New_Outlet: 'Desc_Delete_New_Outlet',
  MsgDesc_Confirmation_Checkin: 'Desc_Confirmation_Checkin',
  Desc_Check_In: 'Desc_Check_In',
  Desc_Select_Driver: 'Desc_Select_Driver',
  SelectAtLeastOneDay: 'Desc_Select_One_Day',
  EmployeeShouldHaveAtLeastOneBox: 'Desc_Employee_Select_One_Box',
  MismatchCounting: 'Desc_Mismatch_counting',
  NoItemsLoaded: 'Desc_No_Items_Loaded',
  CountAllMachineBoxes: 'Desc_Count_All_Machine_Boxes',
  CollectAllMachinesToVerifyTrip: 'Desc_Collect_Machines_To_Verify_Trip',
  SerialNotExistForThisTrip: 'Desc_Serial_Not_Exist_For_This_Trip',
  SelectBatch: 'Desc_Select_Batch',
  OrderDisapproved: 'Desc_Order_Disapprove',
  OrderDispatched: 'Desc_Dispatched',
  Remove: 'Desc_Removed',
  OrderInvoiced: 'Desc_Order_Invoiced',
  OrderDelivered: 'Desc_Order_Delivered',
  OrderApproved: 'Desc_Order_Approved',
  MsgCanceledSuccessfully: 'Desc_Canceled',
  TripVerified: 'Desc_Trip_Verified',
  TripNotVerified: 'Desc_Trip_Not_Verified',
  VerifiedSuccessfully: 'Desc_Trip_Verified_Successfully',
  SelectSecurityGroup: 'Desc_Select_Security_Group',
  SelectOrganization: 'Desc_Select_Organization',
  EnterSecurityGroupName: 'Desc_Enter_Security_Group_Name',
  InsertUserAndPass: 'Desc_Insert_User_And_Pass',
  SelectSchedule: 'Desc_Select_Schdule',
  DeliveryDateCantBeLessThanAssignment: 'Desc_Delivery_Date_Cant_Be_Less_Than_Assignment',
  MsgWarehouseNotAssignedToVehicle: 'Desc_Warehouse_Not_Assigned_To_Vehicle',
  SelectWarehouse: 'Desc_Select_Warehouse',
  RequiredMoreThanAvailable: 'Desc_Requierd_More_avilable',
  RequierdMoreThanRequested: 'Desc_Requierd_More_Requested',
  AppliedMoreThanRequired: 'Desc_Applied_More_Than_Required',
  YouCanAdd: 'Desc_You_Can_ADD_ONLY',
  CompleteTheBenefit: 'Desc_Complete_Benefit',
  Promotion: 'Desc_Promotion',
  FromThisOption: 'Desc_From_This_Option',
  FillAllItems: 'Desc_Fill_All_Items',
  SetPromotionOption: 'Desc_Set_Option_For_All_Promotions',
  AddedMoreThanSatisfication: 'Desc_Added_More_Than_Prerequisites',
  DiscardPreviousChanges: 'Desc_Discard_Previous_Changes',
  CheckCDCDate: 'Desc_Check_CDC_Date',
  CheckPDCDate: 'Desc_Check_PDC_Date',
  PayAllByCashCheck: 'Desc_Pay_All_Cash_Check',
  TotalPaidLessThanRequired: 'Desc_Total_Paid_Less_Than_Required',
  DiscardPreviousPayments: 'Desc_Discard_Previous_Payments',
  SelectTransactionToPay: 'Desc_Transaction_To_Pay',
  SelectCustomer: 'Desc_Select_Customer',
  Delete: 'Desc_Delete',
  Unlock: 'Desc_Unlock',
  SurveyPeriodListReset: 'Desc_Survey_Period_List_Reset',
  DisapproveConfirmation: 'Desc_Confirmation_Disapprove',
  Invoicing: 'Desc_Invoicing',
  InvoiceConfirmation: 'Desc_Confirmation_Invoice',
  Delivery: 'Desc_Delivery',
  DeliveryConfirmation: 'Desc_Confirmation_Delivery',
  MessageNoTargetsAchievedYet: 'Desc_No_Targets_Achieved_Yet',
  MessageNoDynamicTargetsAchievedYet: 'Desc_No_Dynamic_Targets_Achieved_Yet',
  MessageSomeOfDynamicTargetsNotAchievedYet: 'Desc_Some_Of_Dynamic_Targets_Not_Achieved_Yet',
  MessageNotAchievedDynamicAndWholesales: 'Desc_Not_Achieved_Dynamic_And_Wholesales',
  ApproveConfirmation: 'Desc_Confirmation_Approve',
  CheckoutConfirmation: 'Desc_Confirmation_Checkout',
  Checkout: 'Desc_Check_Out',
  MsgVoidedSuccessfully: 'Desc_Voided_Successfully',
  DriverWillBeAbleToLogin: 'Desc_Driver_Will_Be_Able_To_Login',
  TripCheckOut: 'Desc_Trip_Check_Out',
  ContactStoreKeeper: 'Desc_Contact_StoreKeeper',
  NoMenusAvailable: 'Desc_No_Menus_Available',
  SupervisorCaption: 'Desc_Supervisor',
  SalesManagerCaption: 'Desc_Sales_Manager',
  MsgCantDeleteAssignedTemplate: 'Desc_Cant_Delete_Assigned_Template',
  MsgFillCoffeeCapacity: 'Desc_Coffee_Capacity',
  LblAccountData: 'Desc_Account_Data',
  MsgAccountExist: 'Desc_Account_Exist',
  MsgFillCustomerAccountData: 'Desc_Fill_Customer_Account_Data',
  MsgExceedCustomerAccount: 'Desc_Exceed_Customer_Account',
  MsgMustDefineOutletAccount: 'Desc_Must_Define_Outlet_Account',
  MsgFillEmployeeAccount: 'Desc_Fill_Employee_Account',
  MsgFillEmployeeAccountConfirmation: 'Desc_Fill_Employee_Account_Confirmation',
  MsgFillEmployeeDataConfirmation: 'Desc_Fill_Employee_Data_Warnings',
  MsgFillOldPassword: 'Desc_Fill_Old_Password_Warning',
  MsgEmployeeNoAccountWarnings: 'Desc_Employee_No_Account_Warnings',
  MsgEmployeeNoCrediltLimitAccountWarnings: 'Desc_Employee_No_Credilt_Limit_Account_Warnings',
  MsgEmployeeNoDivisionWarnings: 'Desc_Employee_No_Division_Warnings',
  MsgEmployeeNoDeviceWarnings: 'Desc_Employee_No_Device_Warnings',
  MsgEmployeeNoVehicleWarnings: 'Desc_Employee_No_Vehicle_Warnings',
  MsgEmployeeNoTerritoryWarnings: 'Desc_Employee_No_territory_Warnings',
  MsgEmployeeNoDocSeqWarnings: 'Desc_Employee_No_Doc_Seq_Warnings',
  MsgEmployeeNoOperatorWarnings: 'Desc_Employee_No_Operator_Warnings',
  MsgEmployeeNoSecurityWarnings: 'Desc_Employee_No_Security_Warnings',
  CaptionAllOutlets: 'Desc_All_Outlets',
  CaptionMultiOutlets: 'Desc_Multi_Outlets',
  CaptionAllSubChannels: 'Desc_All_SubChannels',
  MsgAppliedLessThanRequired: 'Desc_Applied_Less_Than_Required',
  MsgNoPayments: 'Desc_No_Payments_Inserted',
  MsgCantDeleteUsedVendingTemplate: 'Desc_Cant_Delete_Used_Template',
  CaptionFOC: 'Desc_FOC',
  CaptionPromo: 'Desc_Promo',
  MsgRequiredPriority: 'Desc_Required_Priority',
  MsgExceedPaymentTermConfirmation: 'Desc_Exceed_Payment_Term_Confirmation',
  MsgExceedCreditLimitConfirmation: 'Desc_Exceed_Credit_Limit_Confirmation',

  MsgAddEnoughQuantity: 'MsgAddEnoughQuantity',
  MessageYouCanAddItemsWithQuantityOnly: 'Desc_You_Can_Add_Items_With_Quantity_Only',
  MsgAddEnoughAmount: 'MsgAddEnoughAmount',
  MsgAddedQuantityMoreThanNeeded: 'MsgAddedQuantityMoreThanNeeded',

  MsgExceedAvailableQtyBlock: 'Desc_Exceed_Available_Qty_Block',
  MsgExceedAvailableQtyConfirmation: 'Desc_Exceed_Available_Qty_Confirmation',
  MsgNoAvailableBatches: 'Desc_No_Available_Batches',
  MsgBatchExist: 'Desc_Batch_Exist',
  MsgEnterQuantity: 'Desc_Enter_quantity',
  MsgFillOneValue: 'Desc_Fill_One_Value',
  MsgSelectedPaymentVoided: 'Desc_Selected_Payment_Voided',

  CaptionInactive: 'Desc_Inactive',
  MsgCustomerExist: 'Desc_Customer_Exist',
  MsgTerritoryIsInactive: 'Desc_Territory_Inactive',
  MsgEmployeeTerritoryWillDeleted: 'Desc_Employee_Territory_Will_Deleted_Confirmation',
  MsgNoRouteCustomers: 'Desc_No_Route_Customers',
  MsgCustomerHasPendingInvoices: 'Desc_Customer_Has_Pending_Invoices',
  MsgItemAlreadyAdded: "Desc_Item_Already_Added",
  MsgInvalidTax: "Desc_Invalid_Tax",
  MsgItemCategoryAlreadyAdded: 'Desc_Item_Category_Already_Added',
  MsgPriceNotBetweenMinAndMaxPrices: "Desc_Price_Not_Between_Min_Max",
  MsgNewRangeFromLessThanTo: "Desc_New_Range_From_Should_Less_Than_New_Range_To",
  MsgOutOfRange: "Desc_Out_Of_Range",
  MsgValueNotBetweenFromAndToDiscounts: "Desc_Value_Not_Between_From_To",
  DescTaxNotMoreThan100: "Desc_Tax_Not_More_Than_100",
  MsgFacingAndMinimumMoreThanSuggested: 'Desc_Facing_And_Minimum_More_Than_Suggested',
  MsgSelectPriceListType: "Desc_Select_Price_List_Type",
  Desc_FOC: "Desc_FOC",
  Desc_Price_Discount: "Desc_Price_Discount",
  Desc_Discount: "Desc_Discount",
  Desc_Division: "Desc_Division",
  Desc_Pack_Groups: "Desc_Pack_Groups",
  Desc_Fixed_Value: "Desc_Fixed_Value",
  Desc_Percentage_Of_Net_Sales: "Desc_Percentage_Of_Net_Sales",
  Desc_Pack: "Desc_Pack",
  Desc_Item: "Desc_Item",
  MsgApplyCreditReleaseToCustomer: 'Desc_Apply_Credit_Release_To_Customer',
  MsgApplyDiscountToCustomer: 'Desc_Apply_Discount_To_Customer',
  MsgApplyItemListToCustomer: 'Desc_Apply_ItemList_To_Customer',
  MsgApplyContractedFocToCustomer: 'Desc_Apply_Contracted_Foc_To_Customer',
  MsgAssignOOSItemList: 'Desc_OOSItemList_Assignment',
  MsgApplyItemListToEmployee: 'Desc_Apply_ItemList_To_Employee',
  MsgApplyDiscountOnItem: 'Desc_Apply_Discount_On_Item',
  MsgValidValuePercentageDiscount: 'Desc_Valid_Value_Percentage_Discount',
  MsgMaxDiscountPercentage: 'Desc_Max_Discount_Percentage',
  MsgSelectChangeReason: 'Desc_Select_Change_Reason',
  MsgSelectResumeDate: 'Desc_Select_Resume_Date',
  MsgSelectRecurringDetails: 'Desc_Select_Recurring_Details',
  Desc_Active: 'Desc_Active',
  Desc_On_Hold: 'Desc_On_Hold',
  Desc_Inactive: 'Desc_Inactive',
  Desc_Weekly: 'Desc_Weekly',
  Desc_Monthly: 'Desc_Monthly',
  Desc_Every_Two_Weeks: 'Desc_Every_Two_Weeks',
  Desc_Confirmation_Remove_Assignment: 'Desc_Confirmation_Remove_Assignment',
  Desc_Message_Cancel_Load_Request_And_Change_Orders_Status: 'Desc_Message_Cancel_Load_Request_And_Change_Orders_Status',
  Desc_Message_Multi_Order_Remove_Assignment_And_Cancel_Load_Reuest: 'Desc_Message_Multi_Order_Remove_Assignment_And_Cancel_Load_Reuest',
  Desc_Message_Multi_Order_Remove_Assignment_And_Offload_Load_Reuest: 'Desc_Message_Multi_Order_Remove_Assignment_And_Offload_Load_Reuest',
  Desc_Remove_Assignment: 'Desc_Remove_Assignment',
  MsgTripStatusCheck: 'Desc_Trip_Status_Check',
  MsgTripAlreadyCancelled: 'Desc_Trip_Already_Cancelled',
  MsgTripRouteIsUploaded: 'Desc_Trip_Route_Uploaded',
  MsgEndorsementMinTimeNotAchieved: 'Desc_Endorsement_Min_time_Not_Achieved',

  MsgApplyPromotionsToCustomers: 'Desc_Apply_Promotion_To_Customer',
  MsgStepPromotionValueValidation: 'Desc_Step_Value_Validation',
  MsgRangePromotionValueValidation: 'Desc_Range_Value_Validation',
  MsgTargetOptionPromotionValueValidation: 'Desc_Target_Option_Value_Validation',
  MsgStartedTargetPromotion: 'Desc_Started_Target_Promotion',

  MsgClearStepDefinitionConfimation: 'Desc_Step_Definition_Clear_Confirmation',
  MsgFillPromotionDetails: 'Desc_Fill_Promotion_Details',
  MsgFillPromotionDetailsCorrectly: 'Desc_Fill_Promotion_Details_Correctly',
  MsgApplyAutoRangeConfirmation: 'Desc_Apply_Auto_Range_Confirmation',

  MsgStartShouldLessThanEnd: 'Desc_Start_Should_Less_Than_End',
  MsgInvalidPromotionRange: 'Desc_Invalid_Promotion_Range',
  MsgInvalidDiscountPercentage_Rang: 'Desc_Invalid_Discount_Percentage_Range',
  captionRange: 'Desc_Range',
  captionStep: 'Desc_Step',
  captionOption: 'Desc_Option',

  // promotion types
  Desc_Fixed_Promotion_Type: "Desc_Fixed_Promotion_Type",
  Desc_Ranges_Promotion_Type: "Desc_Ranges_Promotion_Type",
  Desc_Steps_Promotion_Type: "Desc_Steps_Promotion_Type",
  Desc_Monthly_Target_Promotion_Type: "Desc_Monthly_Target_Promotion_Type",
  Desc_Dynamic_Target_Promotion_Type: "Desc_Dynamic_Target_Promotion_Type",
  Desc_Survey_Promotion_Type: "Desc_Survey_Promotion_Type",


  // promotion inputs type
  Desc_Invoice_Value_Input_Type: 'Desc_Invoice_Value_Input_Type',
  Desc_Item_Value_Input_Type: 'Desc_Item_Value_Input_Type',
  Desc_Item_Quantity_Input_Type: 'Desc_Item_Quantity_Input_Type',
  Desc_Certain_Pack_Type_Input_Type: 'Desc_Certain_Pack_Type_Input_Type',
  Desc_Items_Over_Division_Input_Type: 'Desc_Items_Over_Division_Input_Type',
  Desc_Invoice_Value_Over_Group_Input_Type: 'Desc_Invoice_Value_Over_Group_Input_Type',
  Desc_Summation_Over_Group_Input_Type: 'Desc_Summation_Over_Group_Input_Type',
  Desc_Group_Equal_Quantity_Input_Type: 'Desc_Group_Equal_Quantity_Input_Type',
  Desc_Group_Differnt_Quantity_Input_Type: 'Desc_Group_Differnt_Quantity_Input_Type',
  Desc_No_Email_Template_Found_For_Document_Type: 'Desc_No_Email_Template_Found_For_Document_Type',
  Desc_Number_Of_Different_Group_Items_Input_Type: 'Desc_Number_Of_Different_Group_Items_Input_Type',
  Desc_Summation_Over_Group_Input_Type_From_Sales_Order_Type: 'Desc_Summation_Over_Group_Input_Type_From_Sales_Order_Type',
  Desc_Invoice_Value_Over_Group_Input_Type_From_Sales_Order_Type: 'Desc_Invoice_Value_Over_Group_Input_Type_From_Sales_Order_Type',
  Desc_Group_Differnt_Quantity_Input_Type_From_Sales_Order_Type: 'Desc_Group_Differnt_Quantity_Input_Type_From_Sales_Order_Type',

  Desc_Customer_Monthly_Achievement_Input_Type: 'Desc_Customer_Monthly_Achievement_Input_Type',
  Desc_Customer_Monthly_Whole_Sales_Contracts_Input_Type: 'Desc_Customer_Monthly_Whole_Sales_Contracts_Input_Type',
  Desc_Dynamic_Target_Sales_Amount_Input_Type: 'Desc_Dynamic_Target_Sales_Amount_Input_Type',
  Desc_Dynamic_Target_Summation_Over_Group_Input_Type: 'Desc_Dynamic_Target_Summation_Over_Group_Input_Type',

  Desc_Survey: 'Desc_Survey',
  Desc_Cannot_Delete_Started_Promotion: 'Desc_Cannot_Delete_Started_Promotion',
  // promotion output types
  Desc_Item_Quantity_Output_Type: 'Desc_Item_Quantity_Output_Type',
  Desc_Item_Discount_Amount_Output_Type: 'Desc_Item_Discount_Amount_Output_Type',
  Desc_Item_Discount_Percentage_Output_Type: 'Desc_Item_Discount_Percentage_Output_Type',
  Desc_All_Items_Discount_Amount_Output_Type: 'Desc_All_Items_Discount_Amount_Output_Type',
  Desc_Discount_Amount_Over_Transaction_Output_Type: 'Desc_Discount_Amount_Over_Transaction_Output_Type',
  Desc_All_Items_Discount_Percentage_Output_Type: 'Desc_All_Items_Discount_Percentage_Output_Type',
  Desc_Free_Items_Value_Output_Type: 'Desc_Free_Items_Value_Output_Type',
  Desc_Free_Group_Items_Value_On_Invoice_Percentage_Output_Type: 'Desc_Free_Group_Items_Value_On_Invoice_Percentage_Output_Type',
  Desc_Summation_Over_Group_Output_Type: 'Desc_Summation_Over_Group_Output_Type',
  Desc_Group_Equal_Quantity_Output_Type: 'Desc_Group_Equal_Quantity_Output_Type',
  Desc_Group_Discount_Amount_Output_Type: 'Desc_Group_Discount_Amount_Output_Type', // foreach item in group
  Desc_Group_Discount_Percentage_Output_Type: 'Desc_Group_Discount_Percentage_Output_Type', // foreach item in group
  Desc_Discount_Amount_Over_Group_Output_Type: 'Desc_Discount_Amount_Over_Group_Output_Type',
  Desc_Fixed_Price_Over_Item_Output_Type: 'Desc_Fixed_Price_Over_Item_Output_Type',
  Desc_Fixed_Price_Over_All_Item_Output_Type: 'Desc_Fixed_Price_Over_All_Item_Output_Type',
  Desc_Fixed_Price_Over_Group_Output_Type: 'Desc_Fixed_Price_Over_Group_Output_Type',
  Desc_Customer_Points_Type: 'Desc_Customer_Points_Type',
  Desc_Summation_Over_Group_Output_Type_From_Sales_Order_Type: 'Desc_Summation_Over_Group_Output_Type_From_Sales_Order_Type',
  Desc_Group_Discount_Percentage_Output_Type_From_Sales_Order_Type: 'Desc_Group_Discount_Percentage_Output_Type_From_Sales_Order_Type',
  Desc_Fixed_Price_Over_Group_Output_Type_From_Sales_Order_Type: 'Desc_Fixed_Price_Over_Group_Output_Type_From_Sales_Order_Type',

  Desc_Retail_Contract_Discount_Output_Type: 'Desc_Retail_Contract_Discount_Output_Type',
  Desc_Open_Steps_Output_Type: 'Desc_Open_Steps_Output_Type',
  Desc_Whole_Sales_Contracts_Discount_Output_Type: 'Desc_Whole_Sales_Contracts_Discount_Output_Type',
  Desc_Dynamic_Target_Discount_Output_Type: 'Desc_Dynamic_Target_Discount_Output_Type',
  Desc_Dynamic_Target_Summation_Over_Group_Output_Type: 'Desc_Dynamic_Target_Summation_Over_Group_Output_Type',

  Desc_Disc_Percentage_Or_Qty_Over_Group_Output_Type: 'Desc_Disc_Percentage_Or_Qty_Over_Group_Output_Type',

  // Target Modes
  Desc_Accumulative: 'Desc_Accumulative',
  Desc_Last_Invoice: 'Desc_Last_Invoice',
  Desc_Last_Invoice_Accumulative: 'Desc_Last_Invoice_Accumulative',

  MsgExistPaymentTermOnDivision: 'Desc_Exist_Payment_Term_On_Division',
  Desc_Do_You_Want_To_Continue: 'Desc_Do_You_Want_To_Continue',
  Desc_Do_You_Want_To_Restore: 'Desc_Confirmation_Restore',
  Desc_No_Changes_Were_Made: 'Desc_NoChanges_Message',
  Desc_Import: 'Desc_Import',
  Desc_Imported_Records_Count: 'Desc_Imported_Records_Count',
  Desc_Not_Imported_Records_Count: 'Desc_Not_Imported_Records_Count',
  Desc_No_File_Uploaded: 'Desc_No_File_Uploaded',
  Desc_Invalid_Excel_File: 'Desc_Invalid_Excel_File',
  Desc_Imported_Successfully: 'Desc_Imported_Successfully',
  Desc_Excel_File_Schema_Not_Match: 'Desc_Excel_File_Schema_Not_Match',


  //to be deleted
  MissingValue: "Desc_Missing_Value",


  MsgNoRoutesAdded: 'Desc_No_Routes_Added',
  Desc_Copied_From: "Desc_Copied_From",

  //Serials Messages
  Desc_Serial_Not_In_Main_Warehouse: "Desc_Serial_Not_In_Main_Warehouse",
  Desc_Serial_Entered_Before: "Desc_Serial_Entered_Before",
  Desc_Serial_Entered_For_Another_Item: "Desc_Serial_Entered_For_Another_Item",
  Desc_Serials_Entered_Before: "Desc_Serials_Entered_Before",
  Desc_Serials_Entered_For_Another_Item: "Desc_Serials_Entered_For_Another_Item",
  Desc_Some_Serials_Not_Valid: "Desc_Some_Serials_Not_Valid",
  Desc_Serial_Digits_Not_Equals: "Desc_Serial_Digits_Not_Equals",
  Desc_Serial_Digits_Less_Than_Trim_Digits: "Desc_Serial_Digits_Less_Than_Trim_Digits",
  Desc_Range_Serial_Not_Valid: "Desc_Range_Serial_Not_Valid",
  Desc_All_Serials_Not_Valid: "Desc_All_Serials_Not_Valid",
  Desc_Serials_Count_More_Than_Requested: "Desc_Serials_Count_More_Than_Requested",
  Desc_Some_Sub_Serials_Not_Added: "Desc_Some_Sub_Serials_Not_Added",
  Desc_Confirmation_Delete_Serial_And_Sub: "Desc_Confirmation_Delete_Serial_And_Sub",
  Desc_One_Or_More_Sub_Serials_Not_Valid: "Desc_One_Or_More_Sub_Serials_Not_Valid",
  Desc_Serial_Not_Exist: "Desc_Serial_Not_Exist",
  Desc_Serial_Belong_To_Another_Pack: "Desc_Serial_Belong_To_Another_Pack",
  Desc_Serial_Not_In_Vehicle: "Desc_Serial_Not_In_Vehicle",
  Desc_Serials_Count_Not_Match_Req_Qnty: "Desc_Serials_Count_Not_Match_Req_Qnty",
  Desc_Serial_Transfered_Out_In_Another_Transaction: "Desc_Serial_Transfered_Out_In_Another_Transaction",
  Desc_Parent_Of_This_Serial_Added_In_This_Transfer_Out: "Desc_Parent_Of_This_Serial_Added_In_This_Transfer_Out",
  Desc_Barcode_Not_Compatible_With_Template: 'Desc_Barcode_Not_Compatible_With_Template',
  Desc_Barcode_Of_Sub_Not_Compatible_With_Parent: 'Desc_Barcode_Of_Sub_Not_Compatible_With_Parent',
  Desc_Sub_Serial_Has_Different_Batch_Or_Expiry: 'Desc_Sub_Serial_Has_Different_Batch_Or_Expiry',
  Desc_Serial_Already_Aggregated: 'Desc_Serial_Already_Aggregated',
  Desc_From_Serial_To_Serial_Template_Fields_Not_Match: 'Desc_From_Serial_To_Serial_Template_Fields_Not_Match',
  Desc_From_Serial_Not_End_With_Numbers: 'Desc_From_Serial_Not_End_With_Numbers',
  Desc_To_Serial_Not_End_With_Numbers: 'Desc_To_Serial_Not_End_With_Numbers',
  Desc_From_Serial_Not_Contain_Numbers: 'Desc_From_Serial_Not_Contain_Numbers',
  Desc_To_Serial_Not_Contain_Numbers: 'Desc_To_Serial_Not_Contain_Numbers',
  Desc_Serials_Must_Entered: 'Desc_Serials_Must_Entered',
  Desc_Length_Of_Numbers_Part_Not_Match: 'Desc_Length_Of_Numbers_Part_Not_Match',
  Desc_Character_Part_Not_Match: 'Desc_Character_Part_Not_Match',
  Desc_From_Serial_Less_Than_To_Serial: 'Desc_From_Serial_Less_Than_To_Serial',
  Desc_Empty_Excel_Sheet: 'Desc_Empty_Excel_Sheet',
  MsgSelectOneOfCustomerFilters: 'Desc_Fill_Customer_Filters',
  MsgChangeOrganizationRemoveApplyData: 'Desc_Change_Organization_Remove_Apply_Data',
  MsgChangeOrganizationRemoveApplyDataForOrg: 'Desc_Change_Organization_Remove_Apply_Data_For_Org',
  MsgChangePromotionLevelRemoveApplyData: 'Desc_Change_Promotion_Level_Remove_Apply_Data',
  MsgChangeOrganizationRemovePackGroups: 'Desc_Change_Organization_Remove_Pack_Groups',
  MsgDeleteAccountHavingBalance: 'Desc_Delete_Account_With_Balance',
  MsgDeleteAccountHavingChilds: 'Desc_Delete_Account_With_Childs',
  MsgCustomerPointsValueValidation: 'Desc_Customer_Points_Value_Validation',
  MsgValidRedeemValue: 'Desc_Valid_Redeem_Value',

  //Start Document Proofs
  MsgSelectProofStatus: 'Desc_Select_Proof_Status',
  MsgNoDataFound: 'Desc_No_Data_Found',
  MsgSelectProofs: 'Desc_Select_Proofs',
  MsgSelectedCustomerExists: 'Desc_Selected_Customer_Exists',
  MsgSelectedGroupExists: 'Desc_Selected_Group_Exists',
  MsgSelectedChannelExists: 'Desc_Selected_Channel_Exists',
  //End Document Proofs
  Desc_Batches_Not_Selected: 'Desc_Batches_Not_Selected',


  MsgPartyAlreadyAdded: "Desc_Party_Already_Added",
  MsgLinkRecallToParty: 'Desc_Link_Recall_To_Party',
  Desc_Send_Email: 'Desc_Send_Email',
  Desc_Send_System_Notification: 'Desc_Send_System_Notification',
  Desc_Move_Item_To_Good_Or_Damaged_Warehouse: 'Desc_Move_Item_To_Good_Or_Damaged_Warehouse',
  Desc_Yes: 'Desc_Yes',
  Desc_No: 'Desc_No',
  MsgFillMaxOMinValue: 'Desc_Msg_Fill_Max_Or_Min_Value',
  MsgFillMaxOMinDateValue: 'Desc_Msg_Fill_Max_Or_Min_Date_Value',
  MsgSelectYesOrNoValue: 'Desc_Msg_Select_Yes_Or_No_Value',
  MsgSelectLOVOptionValue: 'Desc_Msg_Select_LOV_Option',
  MsgSelectWarehouseVehicleOrDamagedWarehouse: 'Desc_Msg_Select_Warehouse_Vehicle_Or_Damaged_Warehouse',
  MsgFromDateLessThanToDate: 'Desc_Msg_From_Date_Less_Than_To_Date',
  MsgQuestionDependency: 'Desc_Msg_Question_Dependency',
  MsgQuestionReordering: 'Desc_Msg_Question_Reordering',
  MsgInsertValidEmail: 'Desc_Insert_Valid_Email',
  //Start Echo Notifications
  MsgSelectStatus: 'Desc_Select_Status',
  MsgEnterSubject: 'Desc_Enter_Subject',
  MsgEnterBody: 'Desc_Enter_Body',
  MsgFileIsTooLarge: 'Desc_File_Is_Too_Large',
  //End Echo Notifications
  //Start Proof Methods
  MsgEmailSentSuccessfully: 'Desc_Selected_Proof_Email_Sent_Successfully',
  MsgProofRejected: 'Desc_Selected_Proof_Rejected_Successfully',
  MsgNoImagesFoundForTheSelectedProof: 'Desc_No_Images_For_The_Selected_Proof',
  //End Proof Methods
  MsgBounceConfirmation: 'Desc_Bounce_Confirmation',
  Desc_Cannot_Pay_Diff_Division_Transaction: "Desc_Cannot_Pay_Diff_Division_Transaction",
  MsgSelectedTransactionIsExeceuted: 'Desc_Execeuted_Transaction',

  Desc_Reserved_Serial: 'Desc_Reserved_Serial',
  Desc_No_Available_Serials: 'Desc_No_Available_Serials',
  Desc_Serial_Not_Defined_Over_Recall: 'Desc_Serial_Not_Defined_Over_Recall',
  Desc_Do_You_Want_To_Generate_SSCC: 'Desc_Do_You_Want_To_Generate_SSCC',
  Desc_Serial_Linked_To_SSCC: 'Desc_Serial_Linked_To_SSCC',
  Desc_Not_All_Quantity_Packed_In_SSCC: 'Desc_Not_All_Quantity_Packed_In_SSCC',
  Desc_All_Quantity_Packed_In_SSCC: 'Desc_All_Quantity_Packed_In_SSCC',
  Desc_Barcode_Not_Added_In_SSCC_List: 'Desc_Barcode_Not_Added_In_SSCC_List',
  Desc_Barcode_Already_Verified: 'Desc_Barcode_Already_Verified',
  Desc_Verified: 'Desc_Verified',
  Desc_Applied_Quantity_Not_Match_Req_Qnty: "Desc_Applied_Quantity_Not_Match_Req_Qnty",
  Desc_Confirmation_Execute: 'Desc_Confirmation_Execute',
  Desc_Vehilce_Execute: 'Desc_Vehilce_Execute',
  Desc_SSCC_Linked_To_Transaction: 'Desc_SSCC_Linked_To_Transaction',
  Desc_SSCC_Linked_To_Order: 'Desc_SSCC_Linked_To_Order',
  Desc_You_Must_Verify_All_Barcodes: 'Desc_You_Must_Verify_All_Barcodes',
  Desc_Confirmation_Remove_Barcode_Assignment: 'Desc_Confirmation_Remove_Barcode_Assignment',

  Desc_Offloading_Warehouses: 'Desc_Offloading_Warehouses',
  Desc_Offloading_Vehicles: 'Desc_Offloading_Vehicles',
  Desc_Acceptable_Value_Min_Max: "Desc_Acceptable_Value_Min_Max",
  Desc_Acceptable_Value_Certain: "Desc_Acceptable_Value_Certain",
  Desc_Assigne_Order_Confirmation: 'Desc_Assigne_Order_Confirmation',
  Desc_Re_Assigne_Order_Confirmation: 'Desc_Re_Assigne_Order_Confirmation',
  Desc_Notification_Time_Ago_Caption: 'Desc_Notification_Time_Ago_Caption',
  Desc_Few_Seconds_Ago_Caption: 'Desc_Few_Seconds_Ago_Caption',
  Desc_One_Minute_Ago_Caption: 'Desc_One_Minute_Ago_Caption',
  Desc_One_Hour_Ago_Caption: 'Desc_One_Hour_Ago_Caption',
  Desc_One_Day_Ago_Caption: 'Desc_One_Day_Ago_Caption',
  Desc_One_Month_Ago_Caption: 'Desc_One_Month_Ago_Caption',
  Desc_One_Year_Ago_Caption: 'Desc_One_Year_Ago_Caption',
  Desc_Notification_Minutes_Caption: 'Desc_Notification_Minutes_Caption',
  Desc_Notification_Hours_Caption: 'Desc_Notification_Hours_Caption',
  Desc_Notification_Days_Caption: 'Desc_Notification_Days_Caption',
  Desc_Notification_Months_Caption: 'Desc_Notification_Months_Caption',
  Desc_Notification_Years_Caption: 'Desc_Notification_Years_Caption',

  Desc_Confirm_Send_Notification_To_Driver: 'Desc_Confirm_Send_Notification_To_Driver',

  Desc_Recall_Caption: 'Desc_Recall_Caption',
  Desc_Quality_Control_Caption: 'Desc_Quality_Control_Caption',
  Desc_Recall_Header_Hint: 'Desc_Recall_Header_Hint',
  Desc_Recall_Body_Hint: 'Desc_Recall_Body_Hint',
  Desc_Quality_Control_Header_Hint: 'Desc_Quality_Control_Header_Hint',
  Desc_Quality_Control_Body_Hint: 'Desc_Quality_Control_Body_Hint',
  Desc_Commission_Calculation_Header_Hint: 'Desc_Commission_Calculation_Header_Hint',
  Desc_Commission_Calculation_Body_Hint: 'Desc_Commission_Calculation_Body_Hint',
  Desc_Select_PaymentTerm: 'Desc_Select_PaymentTerm',
  Desc_Sonic_App_Caption: 'Desc_Sonic_App_Caption',
  Desc_Customer_App_Caption: 'Desc_Customer_App_Caption',
  Desc_Near_Expiry_Operation_Caption: 'Desc_Near_Expiry_Operation_Caption',
  MsgClearPromotionDetailsWarning: 'Desc_Clear_Promotion_Details_Warning',
  DescMsgCantDeleteBuiltInGroupsAndFields: 'Desc_Msg_Cant_Delete_BuiltIn_Groups_And_Fields',
  DescAddQueryMsg: 'Desc_Add_Query_Msg',
  Desc_Check_Customer_Attachment_Path: 'Desc_Check_Customer_Attachment_Path',
  Desc_Server_Error_While_Downloading_File: 'Desc_Server_Error_While_Downloading_File',
  Desc_There_Is_No_Template_Assigned_For_This_Customer: 'Desc_There_Is_No_Template_Assigned_For_This_Customer',
  MsgClearInsertedDetailsWarning: 'Desc_Clear_Promotion_Details_Warning',
  MsgClearInsertedDetailsWarningForOrg: 'Desc_Clear_Promotion_Details_Warning_For_Org',
  MsgOverwriteItem: 'Desc_Overwrite_Item',
  MsgSelectPackGroup: 'Desc_Select_Pack_Group',
  Desc_Select_Employee: 'Desc_Select_Employee',
  Desc_Apply_Employee_Or_Security_Group: 'Desc_Apply_Employee_Or_Security_Group',
  Desc_Msg_Customer_Outlet_Blocked_Operation_Exist: 'Desc_Msg_Customer_Outlet_Blocked_Operation_Exist',
  Desc_Msg_Group_Blocked_Operation_Exist: 'Desc_Msg_Group_Blocked_Operation_Exist',
  Desc_Some_Orders_Already_Canceled: 'Desc_Some_Orders_Already_Canceled',
  MsgSelectCutomerOrGroup: 'Desc_Select_Cutomer_Or_Group',
  MsgSelectStoreKeeper: 'Desc_Msg_Select_StoreKeeper',
  MsgSelectDeliveryNote: 'Desc_Select_Delivery_Note',
  MsgSelectedDeliveryNoteNotPending: 'Desc_Selected_Delivery_Note_Not_Pending',
  MsgQuantityValueValidation: 'Desc_Quantity_Value_Validation',
  MsgSameItemWithDifferentTax: 'Desc_Same_Item_With_Different_Tax',
  MsgNoEmployeeDivisionAccount: 'Desc_No_Employee_Division_Account',
  MsgNoVehicleAccount: 'Desc_No_Vehicle_Account',
  MsgLoadValueExceedsAvailableCredit: 'Desc_Load_Value_Exceeds_Available_Credit',
  MsgConfirmLoadValueExceedsAvailableCredit: 'Desc_Confirm_Load_Value_Exceeds_Available_Credit',
  MsgOrderIsCanceled: 'Desc_Order_Canceled',
  MsgConsignmentOrderStatusConfirmation: 'Desc_Confirm_Consignment_Order_Status',
  MsgConsignmentSaveConfirmation: 'Desc_Save_Confirmation',
  Desc_Confirmation_Remove_Serials: 'Desc_Confirmation_Remove_Serials',
  MsgAlreadyAdded: 'Msg_Already_Added',
  Desc_UOM_Already_Added: 'Desc_UOM_Already_Added',
  DescCantusecreditnotebecauseselectedmultipledivision: 'Desc_Cant_use_credit_note_because_selected_multiple_division',
  DescMultiDivisions: 'Desc_Multi_Divisions',
  DescYouMustAddAtLeastOneSalesItem: 'Desc_You_Must_Add_At_Least_One_Sales_Item',
  DescYouMustAddAtLeastOneReturnsItem: 'Desc_You_Must_Add_At_Least_One_Returns_Item',

  MsgInvalidConsumerPrice: 'Desc_Invalid_Consumer_Price',
  MsgDefineOneCustomerAccountAtleast: 'Desc_Define_One_Customer_Account_Atleast',
  MsgConfirmDeleteAccountWithTerritories: 'Desc_Confirm_Delete_Account_with_Territories',
  DescBlockedSerial: 'Desc_Blocked_Serial',
  DescResetSerialsConfirmation: 'Desc_Reset_Serials_Confirmation',
  MsgSameCustomerOutletReassignment: 'Desc_Same_Customer_Outlet_Reassignment',
    DescYouCantSelectMoreThanOneRouteWithMultipleLoadingWarehouses: 'Desc_You_Cant_Select_More_Than_One_Route_With_Multiple_Loading_Warehouses',
    DescYouMustGenerateWeeksWeightForInsertedWeeksNumber: 'You_Must_Generate_Weeks_Weight_For_Inserted_Weeks_Number',
    DescGenerateSuggestedOrManuallyFillFactor: 'Desc_Generate_Suggested_Or_Manually_Fill_Factor',
    DescTheCalculateSuggestedFactorWillBeDiscarded: 'Desc_The_calculated_suggested_factor_will_be_discarded',
    DescGenerateLoadRequestResult: 'Desc_Generate_Load_Request_Result',MsgDefineProofTypeForOneDocumentTypeAtleast: 'Desc_Define_Proof_Type_For_One_Document_Type_Atleast',
  MsgForecastingResultDetails: 'Desc_Forecasting_Result_Details',
  MsgSelectDocumentType: 'Desc_Select_Document_Type',
  MsgProofAssignmentForCurrentDocumentWillBeReset: 'Desc_Proof_Assignment_For_Current_Doc_Will_Be_Reset',
  MsgProofAssignmentWillBeReset: 'Desc_Proof_Assignment_Will_Be_Reset',
  MsgPleaseEnterCreditLimitForDivisions: 'Desc_Please_Enter_Credit_Limit_For_Divisions',
  MsgThereIsNoLinkedCustomerToEmployee: 'Desc_There_Is_No_Linked_Customer_To_Employee',
  MsgSelectAClass: 'Desc_Select_A_Class',
  MsgSelectGroup: 'Desc_Select_Group',
  MsgSelectCustomer: 'Desc_Select_Customer',
  MsgSelectOutlet: 'Desc_Select_Outlet',
  MsgSelectRoute: 'Desc_Select_Route',
  MsgSelectSyncDate: 'Desc_Select_Sync_Date',
  MsgEmptyGTINConfirmation: 'Desc_Empty_GTIN_Confirmation',
  MsgSelectPriceListTypeAndOrganization: 'Desc_Select_Price_List_And_Organization',
  MsgAssignCustomerSalesRestricion: 'Desc_Assign_Customer_Sales_Restriction',
  MsgCannotRestrictForMultiplePacksPerItem: 'Desc_Cannot_Restrict_For_Multiple_Packs_Per_Item',
  MsgAssignmentCustomerGroupsChannelWillBeRemoved: 'Desc_Assignment_Customer_Groups_Channel_Will_Be_Removed',
  MsgDescAssignmentEmployeeSecurityGroupsWillBeRemoved: 'Desc_Assignment_Employee_Security_Groups_Will_Be_Removed',
  MsgStartDateShouldLessThanEndDate: 'Desc_Start_Date_Should_Less_Than_End_Date',
  MsgSetQuantityForOneItem: 'Desc_Set_Quantity_For_One_Item',
  MsgOffloadSuccessfuly: 'Desc_Offload_Successfully',
  DescOffloadVehicleConfirmation: 'Desc_Offload_Vehicle_Confirmation',
  MsgVehiclewithoutstockwithserials: 'Desc_Vehicle_doesnt_have_stock',
  MsgNoSerials: 'Desc_No_Serials_Found',
  MsgOffloadSuccessfulyButMantvehiclesNotOfloaded: 'Desc_Offload_Successfully_But_Some_Vehicles_Not_Offloaded',
  MsgSelectSalesManager: 'Desc_Select_Sales_Manager',
  MsgSelectSupervisor: 'Desc_Select_Supervisor',
  MsgSelectSalesPerson: 'Desc_Select_Sales_Person',
  MsgNoAssignedDriver: 'Desc_NO_Assigned_Driver',
  MsgConfirmationChangeOrgByGroup: 'Desc_Confirmation_Change_Organization_On_Group',
  MsgSelectEmployees: 'Desc_Select_Employees',
  MsgBroadcastSentSuccessfully: 'Desc_BroadCast_Sent_Successfully',
  MsgBlockTypeMustBeDefinedForItems: 'Desc_Block_Type_Must_be_Defined_For_All_Items',
  MsgConfirmationAppliedToSurvey: 'Desc_Confirmation_Add_Apply_To_Display_Unit',
  DescNotVisible: "Desc_Not_Visible",
  DescVisibleNotMandatory: "Desc_Visible_Not_Mandatory",
  MsgSelectReason: 'Desc_Please_Select_Reason',
  DescBatchNumberMustBeDefinedForAllItems: 'Desc_Batch_Number_Must_Be_Defined_For_All_Items',
  DescExpiryDateMustBeDefinedForAllItems: 'Desc_Expiry_Date_Must_Be_Defined_For_All_Items',
  DescExpiryDateMustBeDefinedForAllGoodReturns: 'Desc_Expiry_Date_Must_Be_Defined_For_All_Good_Returns',
  DescPackStatusMustBeDefinedForAllItems: 'Desc_Pack_Status_Must_Be_Defined_For_All_Items',
  DescReturnReasonBeDefinedForAllItems: 'Desc_Return_Reason_Must_Be_Defined_For_All_Items',
  DescPackStatusMustBeDefinedForTransaction: 'Desc_Pack_Status_Must_Be_Defined_For_Transaction',
  Account: 'Desc_Account',
  ReturnProcessConfirmation: 'Desc_Return_Process_Confirmation',
  MsgProcessedSuccessfully: 'Desc_Process_Successfully',
  MsgOrderScanned: 'Desc_Order_Already_Assigned',
  MsgAssignDyanmicTemplate: 'Desc_Assign_Dynamic_Template',
  MsgAssignDyanmicTemplateToEmployee: 'Desc_Assign_Dynamic_Template_To_Employee',
  MsgNewPasswordShouldBeDifferent: 'Desc_New_Password_Equal_Old_Password',
  MsgEndRangelargerStartRange: 'Desc_The_End_Range_Must_Be_Larger_Than_Start_Range',
  MsgSelectQuantityRange: 'Desc_You_Must_Select_Quantity_Range',
  MsgCannotchangeQtyRange: 'Desc_You_Can_not_Change_Qty_Range',
  DescTransactionExcuted: 'Desc_Transaction_Excuted',
  MsgAssignVisitStep: 'Msg_Assign_Visit_Step',
  MsgNoMenuSelected: 'Msg_No_Menu_Selected',
  MsgNoStepsAvailable: 'Msg_No_Steps_Available',
  MsgDefineSteps: 'Msg_Define_Steps',
  MsgMenuCannotBeMandatory: 'Msg_Menu_Cannot_Be_Mandatory',
  MsgSelectedBatchesWillBeDeleted: 'Msg_Selected_Batches_Will_Be_Deleted',
  MsgSomeUnAvailableBatches: 'Msg_Some_Added_Batches_Are_No_Longer_Available',
  MsgSelectReturnReason: 'Msg_Select_Return_Reason',
  MsgSelectPackStatus: 'Msg_Select_Pack_Status',
  MsgCalculatingBatches: 'Msg_Calculating_Batches',
  MsgExpiryDateLessThanTodayDate: 'Desc_Msg_Expiry_Date_Less_Than_Today_Date',
  RequiredMoreThanTotalReturnForPG: 'Desc_Required_More_Than_Return_PG',
  MsgNoAvailableQtyToReturn: 'Desc_No_Available_Qty_Return',
  MsgRequiredCustomerPayment: 'Desc_Required_Customer_Payment',
  MsgNotEnoughQtyFromAll: 'Desc_No_Avl_Qty_From_All_Items',
  MsgFillRequiredBenefitForAllRanges: 'Desc_Fill_Required_Benefit_For_All_Ranges',
  InvalidSerialSheet: "invalid-serials.xlsx",// not description to translate
  MsgSelectSecurityGroupOrCustomerGroup: 'Desc_Select_SG_Or_CG',
  MsgNoVisitPattern: "Desc_No_Visit_Pattern_Defined",
  MsgPostedSuccessfully: "Desc_Posted_Successfully",
  DescLoyaltyPointsPromotion: 'Desc_Loyalty_Points_Promotion',
  DescLoyaltyPromotion: 'Desc_Loyalty_Promotion',
  Desc_Day: 'Desc_Day',
  Desc_Week: 'Desc_Week',
  Desc_Month: 'Desc_Month',
  Desc_Year: 'Desc_Year ',
  MsgEditCustomerConfirmation: 'Desc_Edit_Customer_Confirmation',
  MsgCantEditPromotionOrganization: 'Desc_Cant_Edit_Promotion_Organization',
  MsgFromToValidation: 'Desc_From_To_Validation_Msg',
  MsgFromAbove100Validation: 'Desc_From_Above100_validation',
  DescExample: 'Desc_Example',
  DescCommissionAchievedRate: 'Desc_Commission_Achieved_Rate',
  DescCommissionUsedRate: 'Desc_Commission_Used_Rate',
  MsgChangeCommissionMode: 'Desc_Change_Commission_Mode',
  MsgChangeCommissionType: 'Desc_Change_Commission_Type',
  MsgCalculatedSuccessfully: "Desc_Calculated_Successfully",
  MsgAddDescriptionForCustomerOutlet: 'Desc_Add_Description_For_Customer_Outlet',
  MsgAddRoutesCustomerOutlet: 'Desc_Add_Routes_For_Customer_Outlet',
  MsgAddTerritoriesForCustomerOutlet: 'Desc_Add_Territories_For_Customer_Outlet',
  MsgAddCustomerGroupsForCustomerOutlet: 'Desc_Add_Customer_Groups_For_Customer_Outlet',
  MsgAddContactsForCustomerOutlet: 'Desc_Add_Contacts_For_Customer_Outlet',
  MsgFillTransactionID: 'Desc_Fill_Transaction_ID',
  MsgSalesMustBeGreatorThanReturnExchange: 'Desc_Sales_Must_Be_Greator_Than_Return_Exchange',
  MsgEqualExchange: 'Desc_Sales_Should_Be_Equal_Return_Exchange',
  MsgSalesMustBeLessThanReturnExchange: 'Desc_Sales_Must_Be_Less_Than_Return_Exchange',

  MsgUploadedRouteForDeviceEmployee: 'Desc_Uploaded_Route_For_Device_Employee',
  Desc_Value: 'Desc_Value',
  Desc_Volume: 'Desc_Volume',
  MsgAddDescriptionForCustomer: 'Desc_Add_Description_For_Customer',
  MsgAddAccountAndTerritoriesForCustomerOutlet: 'Desc_Add_Account_And_Territories_For_Customer_Outlet',
  MsgAddAccountAndRoutesForCustomerOutlet: 'Desc_Add_Account_And_Routes_For_Customer_Outlet',
  MsgShopContractSaveConfirmation: 'Desc_Shop_Contract_Save_Confirmation',
  MsgCustomerMustHaveAnInvoice: 'Desc_Customer_Must_Have_Invoice',
  MsgYearBonusWontBeCalculated: 'Desc_Year_Bonus_Wont_Be_Calculated',
  MsgContractHasEnded: 'Desc_Contract_Has_Ended',
  MsgExtendConfirmation: 'Desc_Extend_Confirmation',
  MsgSubmitAndCloseConfirmation: 'Desc_Submit_And_Close_Confirmation',
  MsgDateConfirmation: 'Desc_Date_Confirmation', 
  MsgSelectYearAndCommision:'Desc_Select_Year_And_Commission',
  MsgYearExtendedBefore:'Desc_Year_Extended_Before',
  MsgCannotExtendClosedYear:'Desc_Cannot_Extend_Closed_Year',
  MsgShopContractImportConfirmation:'Desc_Shop_Contracts_Import_Confirmation',
  MsgCannotImportClosedYear: 'Desc_Cannot_Import_Closed_Year',
  MsgSequenceNoMustBeSequential: 'Desc_Sequence_Number_Must_Be_Sequential',
  MsgCannotPayUnclosedYear: 'Desc_Cannot_Pay_Unclosed_Year',
  MsgCannotCalculateBonusForFutureYear: 'Desc_Cannot_Calculate_Bonus_For_Future_Year',
  MsgNoTargetsDefined: 'Desc_No_Targets_Defined',
  MsgTargetCannotBeZero: 'Desc_All_Year_Targets_Cannot_Be_Zero',
  MsgYearUnavailable: 'Desc_Year_Unavailable',

  MsgChattingNetworkError: 'Desc_Chatting_Network_Error',
  MsgMapsNetworkError: 'Desc_Maps_Network_Error',
  MsgConnectionRestored: 'Desc_Connection_Restored',
  MsgConnectionRestoredRefresh: 'Desc_Connection_Restored_Refresh',
  MsgRefreshPage: "Desc_Refresh_Page",

  Desc_Item_Group: "Desc_Item_Group",
  Desc_Add_Item_Group: "Desc_Add_Item_Group",
  MsgAssignMustSellItem: "Desc_Assign_Must_Sell_Item",
  MsgAssignCustomersToMustSellItems: "Desc_Assign_Customer_To_Must_Sell_Item",
  MsgFillSalesOrder: "Desc_Fill_Sales_Order_Types",

  MsgSelectLinkType: 'Desc_Select_Link_Type',
  MsgChangeLinkTypeConfirmation: 'Desc_Change_Link_Type_Confirmation',
  MsgLinkAppliedToDataWillBeCleared: 'Desc_Link_Applied_To_Data_Will_Be_Cleared',
  MsgApplyLink: 'Desc_Apply_Link',

  MsgEnglishUsername: "Desc_Username_English_Only",
  MsgEnglishPassword: "Desc_Password_English_Only",

  MsgInvalidParameter: "Desc_Invalid_Parameter",
  MsgChangeQuestionInfoConfirmation: "Desc_Change_Question_Info_Confirmation",
  MsgLOVNotRelatedToDynamicField: "Desc_LOV_Not_Related_To_Dynamic_Field",
  MsgDefineParameter: "Desc_Define_Parameter",
  MsgCannotPerformOperationDueToDependency: "Desc_Cannot_Perform_Operation_Due_To_Dependency",
  DescRestrictReturnFromOldInvoicesConfirmation: 'Desc_Restrict_Return_From_Old_Invoices_Confirmation',

    MsgInvalidDate: "Desc_Invalid_Date",
    
    Desc_Commission_Calculation: "Desc_Commission_Calculation",
    Msg_fill_from_to_date_msg: "Desc_fill_from_to_date_msg",
    Msg_Fill_Detail: "Desc_Fill_Detail",
    MsgAddTerritoriesBeforeAddingRoutes: 'Desc_Add_Territories_Before_Adding_Routes',
    Msg_Add_Attachments: "Desc_Add_Attachments",
    MsgItemHasZeroPrice: 'Error_Item_Has_Zero_Price',

   
    MsgPriceOutLimitedRange: 'Desc_Price_Out_Of_Limited_Range',
    MsgMenuNotAuthorized: 'Desc_Menu_Not_Auth',
    MessageSelectEmployee: 'Desc_Message_Select_Employee',
    MessageCannotMove: 'Desc_Message_Cannot_Move',
    MsgMoveConfirmation: 'Desc_Move_Confirmation',
    Desc_No_Account_For_Employee_Move: 'Desc_No_Account_For_Employee_Move',
  MsgNotValidToReject: 'Not_Valid_To_Reject',

  MsgSelectEmployeeOrSecurityGroup: "Desc_Select_Employee_Or_Security_Group",
};
