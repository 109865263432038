import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { DialogResult } from "../../models/enums/dialog-result.enum";

@Component({
  selector: 'app-show-images-dialog',
  templateUrl: './show-images-dialog.component.html',
  styleUrls: ['./show-images-dialog.component.css']
})
export class ShowImagesDialogComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('showImagesDialog', { static: true }) showImagesDialog: any;
  modalRef: NgbModalRef;
  @Input() imagesUrls: any = [];
  OkResult: DialogResult = DialogResult.Ok;
  imgClass = "col-md-12";

  constructor(
    private modalService: NgbModal,
  ) { }
  ngOnInit() {
    this.imgClass = "col-md-12";
  }
  ngOnDestroy() {
     // this.modalService.dismissAll();
  }
  ngOnChanges() {
    this.setImagesClass();
  }
  public showDialog() {
    this.setImagesClass();
    return this.modalService.open(this.showImagesDialog, { centered: true, size: 'xl' }).result.then(
      (result) => {
        if (result === DialogResult.Ok) {
          return true;
        } else {
          return -1;
        }
      });
  }
  setImagesClass() {
    if (this.imagesUrls && this.imagesUrls.length == 1) {
      this.imgClass = "col-md-12";
    } else if (this.imagesUrls && this.imagesUrls.length == 2) {
      this.imgClass = "col-md-6";
    } else if (this.imagesUrls && this.imagesUrls.length == 3) {
      this.imgClass = "col-md-4";
    } else {
      this.imgClass = "col-md-3";
    }
  }
  openImage(image) {
    if(image.hasImage){
      window.open(image.url, "_blank");
    }
  }
}


