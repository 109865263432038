export enum FormProcessMode {
  None = 1,
  LoadRequest = 1,
  Sales = 2,
  Orders = 3,
  Return = 4,
  Delivery = 5,
  Transfer = 6,
  FOCSales = 7,
  FOCOrders = 8,
  Inventory = 9,
  ExchangeSale = 10,
  ExchangeReturn = 11,
  PromotionSales = 12,
  PromotionOrders = 13,
  LoadFullVan = 14,
  InputVoucher = 15,
  OutputVoucher = 16,
  Search = 17,
  ReplenishCustomerStock = 18,
  RemoveCustomerStock = 19,
  InventoryCustomerStock = 20,
  OffLoad = 21,
  TemporaryCreditSales = 22,
  ContractedFOC = 23,
  ContractedFOCAmount = 24,
  PriceProtection = 25,
  Samples = 26,
  LoadAffectStock = 27,
  DamagedStock = 28,
  LoadConfirmation = 29,
  SalesForecasting = 30,
  StockCounting = 31,
  OrdersByItemImages = 32,
  ExtraLoad = 33,
  DownPayment = 34,
  PurchaseOrder = 35,
  Checkout = 36,
  CheckIn = 37,
  TransferIn = 38,
  TransferOut = 39,
  MoveOrder = 40,
  CreditNote = 41,
  DebitNote = 42,
  SerialsAggregation = 43,
  PartialDelivery = 44,
  SSCCFromLoadExecute = 45,
  SSCCFromMainList = 46,
  QualityControl = 47,
  SSCCFromDelivery = 48,
  RejectedOrder = 49,
  ConsignmentOrder = 50,
  InvoiceOrder = 51,
  ManufacturingDefects = 52,
  Receiving = 53,
  ROIIncomming = 54,
  ROIIncommingReturns = 55,
  Packing = 56,
  PrepareProduction = 57,
  BackToStoreOffload = 58,
  ReceiveHelloJordan = 59,
  ResendHelloJordan = 60,
  SerialReassignment = 61,
  UnlockSerials = 62,
  BackToStoreReset = 63,
  Packaging = 64,
  GenerateSerials = 65,
  ProcessReturn = 66,
  Payment = 67,
  TestInvoices = 68,
  ROIIncommingWithoutRef = 69,
  ROIIncommingReturnsWithoutRef = 70
}
