import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class AESEncryptionService {
  constructor() { }
  decryptData(value, employeeId) {
    try {
      let staticKey = 'AeInSe' + employeeId.toString().padStart(10, 'S');
      var key = CryptoJS.enc.Utf8.parse(staticKey);
      var iv = CryptoJS.enc.Utf8.parse(staticKey);
      var decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (e) {
      window.location.reload();
      return null;
    }

  }
}
