import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { TerritoryService } from '../../../../content/master-data-definition/employee-managment/territory/territory.service';

@Component({
  selector: 'app-route-filter',
  templateUrl: './route-filter.component.html',
  styleUrls: ['./route-filter.component.css']
})
export class RouteFilterComponent implements OnInit, OnChanges {
  @Input() parentForm: FormGroup;
  @Input() organizationId = -1;
  @Input() territoryId = -1;
  @Input() hideInactiveRoutes = false;
  @Input() isExchangeMode = false;
  @Input() selectFirstItemByTerritory = false;
  @Input() employeeId = -1;
  @Input() filterByEmployee = false;
  selectedRouteId: number;
  routeDataSource: any[];
  isRouteLoading = false;
  isRequired = false;
  @Output() routeSelected = new EventEmitter();
  constructor(private territoryService: TerritoryService) { }

  ngOnInit() {
    if (this.parentForm.get('routeId').validator != null) {
      const validator = this.parentForm.get('routeId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    // if (!this.isExchangeMode)
    //   this.populateRoutes();
  }
  ngOnChanges() {
    // when change organization or territory then reset selected route
    this.parentForm.get('routeId').setValue(null);
    if (!this.isExchangeMode) {
      this.populateRoutes();
    } else if (this.isExchangeMode && this.territoryId != -1) {
      this.populateRoutes();
    }else{
      this.routeDataSource = [];
    }
  }
  populateRoutes() {
    if(!this.filterByEmployee){
      this.employeeId = -1;
    }
    var routeFilter = {
      organizationId: this.organizationId,
      territoryId: this.territoryId,
      hideInactiveRoutes: this.hideInactiveRoutes,
      isExchangeMode: this.isExchangeMode,
      employeeId : this.employeeId ? this.employeeId : -1
    }
    this.isRouteLoading = true;
    this.territoryService.getRoutes(routeFilter).subscribe(result => {
      this.routeDataSource = result.data;
      this.isRouteLoading = false;
      if (this.selectFirstItemByTerritory && this.isExchangeMode && this.territoryId > 0) {
        if (result.data.length > 0) {
          this.parentForm.get('routeId').setValue(result.data[0].routeId)
          this.routeSelected.emit(result.data[0]);
        } else {
          this.routeSelected.emit(undefined);
        }
      }

      // else if(this.selectFirstItemByTerritory){
      //   this.parentForm.get('routeId').setValue(result.data[0].routeId)
      //   this.routeSelected.emit(result.data[0]);
      // }
    });
  }
  onRouteDataChanged(selectedRoute) {
    this.routeSelected.emit(selectedRoute);
  }
}
