import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { SurveyService } from '../../../../content/master-data-definition/survey-managment/survey.service';
import { SurveyTypes } from '../../../models/enums/survey-types.enum';

@Component({
  selector: 'app-survey-filter',
  templateUrl: './survey-filter.component.html',
  styleUrls: ['./survey-filter.component.css']
})
export class SurveyFilterComponent implements OnInit {

  @Input() currentFormControlName = 'surveyId';
  @Input() surveyTypeId = SurveyTypes.CustomerSurvey;
  @Input() hideLabel = false;
  @Input() parentForm: FormGroup;
  @Output() onChangeSurvey = new EventEmitter();
  surveyList: any[];
  isLoading = false;
  isRequired = false;

  constructor(private surveyService: SurveyService) {
  }
  ngOnInit() {
    if (this.parentForm.get(this.currentFormControlName).validator != null) {
      const validator = this.parentForm.get(this.currentFormControlName).validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    this.populateSurveyList();
  }

  populateSurveyList() {
    this.isLoading = true;
    this.surveyService.getSurveys(this.surveyTypeId).subscribe(result => {
      this.surveyList = result;
      this.isLoading = false;
    });
  }
  onChangeSurveyClick(survey) {
    this.onChangeSurvey.emit(survey);
  }
}

