import { WarehouseOperationModule } from './content/warehouse-operation/warehouse-operation.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastyModule } from 'ng2-toasty';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SessionDataProvider } from './core/session-data-provider.service';
import { GlobalErrorHandler } from './core/global-error-handler';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { VehicleOperationModule } from './content/vehicle-operation/vehicle-operation.module';
// import {ConnectionServiceModule} from 'ng-connection-service';   

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, document.getElementsByTagName('base')[0].href + 'Resources/Lang/', '.json');
}

export function SessionDataFactory(provider: SessionDataProvider) {
  return () => provider.loadSessionLanguges();
}
export function SessionDataFactory2(provider: SessionDataProvider) {
  return () => provider.loadSessionOperatorData();
}
@NgModule({
  declarations: [
    AppComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    // ConnectionServiceModule,
    ToastyModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    })
  ],
  exports: [
    TranslateModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    {
      provide: APP_INITIALIZER,
      useFactory: SessionDataFactory,
      deps: [SessionDataProvider],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: SessionDataFactory2,
      deps: [SessionDataProvider],
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href + 'API/';
}
