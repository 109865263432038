import { ItemStockBatches } from './../../../models/Item/item-stock-batches';
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TransactionMainTypes } from "../../../models/enums/transaction.main.types.enum";
import { TotalsModel } from "../../../models/Item/totals.model";
import { ItemPackModel } from "../../../models/Item/item.model";
import { SharedTableResult } from "../../../models/shared-table/shared-table-result.interface";
import { ITableProperties } from "../../../models/shared-table/table-properties.interface";
import { CoreSession } from "../../../../core/core.session";
import { FormProcessMode } from "../../../models/enums/form-process-mode.enum";
import { SessionDataProvider } from "../../../../core/session-data-provider.service";
import { ConstantConfigurations } from "../../../models/constants/constant-configuration";
import * as cloneDeep from 'lodash/cloneDeep';
import { SalesTransactionTypes } from '../../../models/enums/sales-transaction-types';
import { CalculationService } from '../../../services/calculations.service';

@Component({
  selector: "app-items-summary",
  templateUrl: "./items-summary.component.html",
  styleUrls: ["./items-summary.component.css"],
})
export class ItemsSummaryComponent implements OnInit {
  @Output("BackEvent") backEvent = new EventEmitter();
  @Output("SaveEvent") saveEvent = new EventEmitter();
  @Output("RecurringEvent") recurringEvent = new EventEmitter();
  @Input('orderTypeId') orderTypeId: number = -1;
  @Input("ShowBatches") showBatches = false;
  @Input("ShowTax") showTax = false;
  @Input("ShowRetailTax") showRetailTax = false;
  @Input("ItemsList") itemsList: ItemPackModel[] = [];
  @Input("Totals") totals: TotalsModel = new TotalsModel();
  @Input("ItemProcessMode") itemProcessMode: FormProcessMode =
    FormProcessMode.Orders;
  @Input("TransactionMainType") transactionMainType: TransactionMainTypes =
    TransactionMainTypes.CustomerTransaction;
  @Input("executeOperation") executeOperation = false;
  @Input("ShowFooter") showFooter = true;
  saveCaption = "Desc_Save";
  AutoExecuteLoadRequestInBO = false;
  AutoExecuteOffloadRequestInBO = false;
  isROIScreen = false;
  isConsignmentOrder = false;
  hideBackButtonForNonEditableTransferIn = false;
  AllowEditItemInTransfer = false;
  calculateTaxBeforeDiscount: boolean = false;
  applyRoundingPerLine: boolean = false;
  applyZATCARoundingRules: boolean = false;
  @Input("hideBackButton") hideBackButton = false;
  @Input("forcedItemDiscountList") forcedItemDiscountList: any[] = [];
  @Input("applyReturnDiscount") applyReturnDiscount: boolean = false;
  @Input("allowTaxOverFreeItems") allowTaxOverFreeItems: boolean = false;
  summaryList: SharedTableResult = {
    totalItems: 0,
    data: [],
  };
  summaryTableData: ITableProperties = {
    pageSize: 25,
    showPaginator: false,
    showSearch: false,
    isOnline: false,
    showSearchBtn: false,
    showEditButton: false,
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      { title: "Desc_Item_Name", key: "itemName", isSortable: false },
      { title: "Desc_Item_Code", key: "itemCode", isSortable: false },
      { title: "Desc_UOM", key: "uom", isSortable: false },
    ],
  };
  showTaxInWHOperations: boolean = false;
  allowTax: boolean = false;
  includeExciseTaxInDiscount: boolean = false;

  constructor(
    public coreSession: CoreSession,
    private sessionData: SessionDataProvider,
    private calculationService: CalculationService
  ) { }
  ngOnInit() {
    this.allowTax = this.sessionData.getConfigurationValue(ConstantConfigurations.AllowTax).toLowerCase() === "true";
    this.showTaxInWHOperations = (this.transactionMainType === TransactionMainTypes.WarehouseTransaction &&
      this.sessionData.getConfigurationValue(ConstantConfigurations.AllowTaxInWarehouseOperations).toLowerCase() === "true" &&
      this.allowTax);
    this.applyRoundingPerLine = this.sessionData.getConfigurationValue(ConstantConfigurations.ApplyRoundingOnCalculations).toLowerCase() === "true";
    this.applyZATCARoundingRules = this.sessionData.getConfigurationValue(ConstantConfigurations.ApplyZATCARoundingRules).toLowerCase() === "true";
    this.includeExciseTaxInDiscount = this.sessionData.getConfigurationValue(ConstantConfigurations.IncludeExciseTaxInDiscount).toLowerCase() === "true";
    this.calculateTaxBeforeDiscount = this.sessionData
      .getConfigurationValue(ConstantConfigurations.CalculateTaxBeforeDiscounts)
      .toLowerCase() === "true";
    this.AllowEditItemInTransfer =
      this.sessionData
        .getConfigurationValue(ConstantConfigurations.AllowEditItemInTransfer)
        .toLowerCase() === "true";
    this.AutoExecuteLoadRequestInBO =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.AutoExecuteLoadRequestInBO
        )
        .toLowerCase() === "true";
    this.isConsignmentOrder =
      this.itemProcessMode === FormProcessMode.ConsignmentOrder;
    this.isROIScreen =
      this.itemProcessMode === FormProcessMode.DamagedStock ||
      this.itemProcessMode === FormProcessMode.ManufacturingDefects ||
      this.itemProcessMode === FormProcessMode.ROIIncomming ||
      this.itemProcessMode === FormProcessMode.ROIIncommingReturns ||
      this.itemProcessMode === FormProcessMode.ROIIncommingWithoutRef ||
      this.itemProcessMode === FormProcessMode.ROIIncommingReturnsWithoutRef;
    this.AutoExecuteOffloadRequestInBO =
      this.sessionData
        .getConfigurationValue(
          ConstantConfigurations.AutoExecuteOffloadRequestInBO
        )
        .toLowerCase() === "true";
    if (
      (this.itemProcessMode === FormProcessMode.LoadRequest &&
        this.AutoExecuteLoadRequestInBO) ||
      (this.itemProcessMode === FormProcessMode.OffLoad &&
        this.AutoExecuteOffloadRequestInBO) ||
      this.executeOperation
    ) {
      this.saveCaption = "Desc_Vehilce_Execute";
    }
    if (this.itemProcessMode === FormProcessMode.SerialReassignment) {
      this.saveCaption = "Desc_Save";
    }
    if (this.itemProcessMode != FormProcessMode.ConsignmentOrder)
      this.summaryTableData.columns.push({
        title: "Desc_Quantity",
        key: "requiredQty",
        isSortable: false,
        numberOfStockDigits: true,
      });
    if (this.transactionMainType === TransactionMainTypes.CustomerTransaction) {
      if (this.showBatches) {
        this.summaryTableData.columns.push(
          { title: "Desc_BatchNo", key: "batchNoToView", isSortable: false },
          {
            title: "Desc_ExpiryDate",
            key: "expiryDateToView",
            isSortable: false,
            isExpiryDate: true,
          }
        );
      }
      this.summaryTableData.columns.push({
        title: "Desc_Price",
        key: "priceString",
        isSortable: false,
        numberOfDigits: true,
      });
      if (this.itemProcessMode != FormProcessMode.ConsignmentOrder)
        this.summaryTableData.columns.push({
          title: "Desc_Discount",
          key: "calculatedDiscountTotal",
          isSortable: false,
          numberOfDigits: true,
        });
      else
        this.summaryTableData.columns.push({
          title: "Desc_Discount",
          key: "discount",
          isSortable: false,
          numberOfDigits: true,
        });

      if (this.showTax) {
        if (this.itemProcessMode != FormProcessMode.ConsignmentOrder)
          this.summaryTableData.columns.push({
            title: "Desc_Tax",
            key: "calculatedTax",
            isSortable: false,
            numberOfDigits: true,
          });
        else
          this.summaryTableData.columns.push({
            title: "Desc_Tax",
            key: "tax",
            isSortable: false,
            numberOfDigits: true,
          });
        if (this.showRetailTax) {
          this.summaryTableData.columns.push({
            title: "Desc_Retail_Tax",
            key: "calculatedRetailTax",
            isSortable: false,
            numberOfDigits: true,
          });
        }
      }
      if (this.itemProcessMode != FormProcessMode.ConsignmentOrder)
        this.summaryTableData.columns.push({
          title: "Desc_Total",
          key: "netTotal",
          isSortable: false,
          numberOfDigits: true,
        });
    } else {
      if (
        this.itemProcessMode === FormProcessMode.TransferIn &&
        !this.AllowEditItemInTransfer
      ) {
        this.hideBackButtonForNonEditableTransferIn = true;
      }
      if (this.showBatches) {
        this.summaryTableData.columns.push(
          { title: "Desc_BatchNo", key: "batchNoToView", isSortable: false },
          {
            title: "Desc_ExpiryDate",
            key: "expiryDateToView",
            isSortable: false,
            isExpiryDate: true,
          }
        );
      }
      if (!this.isROIScreen && this.allowTax && this.sessionData.getConfigurationValue(ConstantConfigurations.AllowTaxInWarehouseOperations).toLowerCase() === "true") {
        this.summaryTableData.columns.push({
          title: "Desc_Tax",
          key: "calculatedTax",
          isSortable: false,
          numberOfDigits: true,
        });
      }
    }
    if (this.isROIScreen) {
      // this.summaryTableData.columns.push({
      //   title: "Desc_Value",
      //   key: "netTotal",
      //   isSortable: false,
      //   numberOfDigits: true,
      // });
      if (this.sessionData.getConfigurationValue(ConstantConfigurations.AllowTax).toLowerCase() === "true") {
        this.summaryTableData.columns.push({
          title: "Desc_Tax",
          key: "calculatedTax",
          isSortable: false,
          numberOfDigits: true,
        });
      }
    }
    if (this.transactionMainType === TransactionMainTypes.WarehouseTransaction) {
      this.summaryTableData.columns.push({
        title: "Desc_Price",
        key: "priceString",
        isSortable: false,
        numberOfDigits: true,
      });
      this.summaryTableData.columns.push({
        title: "Desc_Total",
        key: "netTotal",
        isSortable: false,
        numberOfDigits: true,
      });
    }
    // Cash data source for shared table from selected batches
    if (this.showBatches) {
      var addedPacksBatchLevel = [];
      addedPacksBatchLevel = this.prepareBatchesToView();
      if (addedPacksBatchLevel && addedPacksBatchLevel.length > 0) {
        this.summaryList.data = addedPacksBatchLevel;
        this.summaryList.totalItems = addedPacksBatchLevel.length;
      }
    } else {
      // Cash data source for shared table from added pack
      if (this.itemsList && this.itemsList.length > 0) {
        this.summaryList.data = this.itemsList;
        this.summaryList.totalItems = this.itemsList.length;
      }
    }
  }
  prepareBatchesToView() {
    var totalRequiredPayment = 0;
    if (this.applyReturnDiscount) {
      this.totals = new TotalsModel();
    }
    this.itemsList;
    var addedPacksBatchLevel: any = [];
    var serialBatch: ItemStockBatches = new ItemStockBatches();
    this.itemsList.forEach((pack) => {
      if (pack.hasSerialNumber || pack.itemTypeId === 2) {
        if (pack.addedSerials && pack.addedSerials.length > 0) {
          pack.addedSerials.forEach((serial) => {
            const selectedBatchesIndex = addedPacksBatchLevel.findIndex(
              i =>
                i.batchNo === serial.batchNo &&
                i.expiryDateModel.year == serial.expiryDateModel.year &&
                i.expiryDateModel.month == serial.expiryDateModel.month &&
                i.expiryDateModel.day == serial.expiryDateModel.day &&
                i.packId == serial.packId
            );
            if (selectedBatchesIndex == -1) {
              serialBatch = new ItemStockBatches();
              serialBatch.itemName = pack.itemName;
              serialBatch.itemCode = pack.itemCode;
              serialBatch.uom = pack.uom;
              serialBatch.expiryDateToView = serial.expiryDateModel.date;
              serialBatch.batchNo = serial.batchNo;
              serialBatch.expiryDateModel = serial.expiryDateModel;
              serialBatch.batchNoToView = serial.batchNo;
              serialBatch.priceString = pack.priceString;
              serialBatch.requiredQty = serial.serialsCount;
              serialBatch.netTotal = pack.netTotal;
              addedPacksBatchLevel.push(serialBatch);
            }
            else {
              addedPacksBatchLevel[selectedBatchesIndex].requiredQty += +serial.serialsCount;
            }
          });
        }
        else
          addedPacksBatchLevel.push(pack);
      } else if (pack.selectedBatches && (!pack.returnFromInvoiceAvailableBatchList || pack.returnFromInvoiceAvailableBatchList.length === 0)) {
        let value = 0;
        pack.selectedBatches.forEach((batch) => {
          batch.itemName = pack.itemName;
          batch.itemCode = pack.itemCode;
          batch.uom = pack.uom;
          batch.expiryDateToView = batch.expiryDateModel.date;
          batch.batchNoToView = batch.batchNo;
          batch.priceString = pack.priceString == "" ? pack.price.toString() : pack.priceString;
          // batch.calculatedTax = (pack.price * batch.requiredQty) * (pack.tax / 100);
          batch.calculatedTax = this.calculationService.multiplyTwoNumbers(this.calculationService.multiplyTwoNumbers(pack.price, batch.requiredQty), this.calculationService.divideTwoNumbers(pack.tax, 100));
          // batch.netTotal = (pack.price * batch.requiredQty) * ( 1 + (pack.tax / 100));
          batch.netTotal = (pack.price * batch.requiredQty) * (1 + (pack.tax / 100));
          batch.netTotal = this.calculationService.multiplyTwoNumbers(this.calculationService.multiplyTwoNumbers(pack.price, batch.requiredQty), this.calculationService.sumTwoNumbers(1, this.calculationService.divideTwoNumbers(pack.tax, 100)));
          addedPacksBatchLevel.push(batch);
          value += batch.netTotal;
        });
      } else if (pack.returnFromInvoiceAvailableBatchList && pack.returnFromInvoiceAvailableBatchList.length > 0) {

        var selectedFromInvoiceBatches = pack.returnFromInvoiceAvailableBatchList.filter(batchObj => batchObj.requiredQuantity > 0);
        selectedFromInvoiceBatches.forEach(invBatch => {
          var requiredPaymentAmount = 0;
          var packDiscountAmount = 0;
          var packGrossTotal = 0;
          if (this.applyReturnDiscount && this.forcedItemDiscountList && this.forcedItemDiscountList.length > 0) {
            var i = this.forcedItemDiscountList.findIndex(i => i.itemId === pack.itemId && i.packId === pack.packId
              && i.isFromProductDiscount === pack.isFromProductDiscount
            );
            if (i > -1) {
              packDiscountAmount = this.forcedItemDiscountList[i].discountAmount;
              packGrossTotal = this.forcedItemDiscountList[i].packGrossTotal;
            }
          }
          var idx = addedPacksBatchLevel.findIndex(batch =>
            batch.batchNo === invBatch.batchNo &&
            batch.packId === pack.packId &&
            batch.itemId === pack.itemId &&
            batch.salesTransactionTypeId === pack.salesTransactionTypeId
          );

          var calculatedTaxTotal = 0;
          let addedExciseTax = 0;
          // var calculatedExciseTaxAmount = invBatch.baseExciseTax * invBatch.requiredQuantity * pack.piecesInPack;
          var calculatedExciseTaxAmount = this.calculationService.multiplyTwoNumbers(this.calculationService.multiplyTwoNumbers(invBatch.baseExciseTax, invBatch.requiredQuantity), pack.piecesInPack);
          if (this.includeExciseTaxInDiscount) {
            addedExciseTax = pack.calculatedRetailTax;
          }
          // var grossTotal = pack.salesTransactionTypeId === SalesTransactionTypes.None ? invBatch.basePrice * invBatch.requiredQuantity * pack.piecesInPack : 0;
          var grossTotal = pack.salesTransactionTypeId === SalesTransactionTypes.None ? this.calculationService.multiplyTwoNumbers(this.calculationService.multiplyTwoNumbers(invBatch.basePrice, invBatch.requiredQuantity), pack.piecesInPack) : 0;
          // if (this.applyRoundingPerLine) grossTotal = this.calculationService.getNumberOnDigitFormat(grossTotal);
          if (this.applyReturnDiscount && pack.salesTransactionTypeId === SalesTransactionTypes.None) { // &&  pack.salesTransactionTypeId === SalesTransactionTypes.None ??
            // var returnDiscountAmount = (grossTotal / packGrossTotal) * packDiscountAmount;
            // var returnDiscountAmount = (grossTotal + addedExciseTax) * pack.promotedDiscount / 100;
            var returnDiscountAmount = this.calculationService.divideTwoNumbers(this.calculationService.multiplyTwoNumbers(this.calculationService.sumTwoNumbers(grossTotal, addedExciseTax), pack.promotedDiscount), 100);
            // if (this.applyRoundingPerLine) returnDiscountAmount = this.calculationService.getNumberOnDigitFormat(returnDiscountAmount);
            // var returnDiscountPercentage = grossTotal > 0 ? (returnDiscountAmount / (grossTotal + addedExciseTax)) * 100 : 0; // same as pack promoted discount
            var returnDiscountPercentage = grossTotal > 0 ? this.calculationService.multiplyTwoNumbers(this.calculationService.divideTwoNumbers(returnDiscountAmount, this.calculationService.sumTwoNumbers(grossTotal, addedExciseTax)), 100) : 0; // same as pack promoted discount

            if (this.calculationService.getNumberOnDigitFormat(returnDiscountPercentage) + invBatch.discountPercentage > 100) { // Payment is required!.
              //requiredPaymentAmount = ((grossTotal + addedExciseTax) - (((grossTotal + addedExciseTax) * (invBatch.discountPercentage / 100)) + returnDiscountAmount)) * -1;
              requiredPaymentAmount = this.calculationService.multiplyTwoNumbers(this.calculationService.subtractTwoNumbers(this.calculationService.sumTwoNumbers(grossTotal, addedExciseTax), this.calculationService.sumTwoNumbers(this.calculationService.divideTwoNumbers(this.calculationService.multiplyTwoNumbers(this.calculationService.sumTwoNumbers(grossTotal, addedExciseTax), invBatch.discountPercentage), 100), returnDiscountAmount)), -1);

              // totalRequiredPayment += requiredPaymentAmount;
              totalRequiredPayment = this.calculationService.sumTwoNumbers(totalRequiredPayment, requiredPaymentAmount);
              var discountPercentage = 100;
              // var calculatedDiscountTotal = (grossTotal + addedExciseTax);
              var calculatedDiscountTotal = this.calculationService.sumTwoNumbers(grossTotal, addedExciseTax);
            } else {
              // var calculatedDiscountTotal = (grossTotal + addedExciseTax) * invBatch.discountPercentage / 100;
              var calculatedDiscountTotal = this.calculationService.divideTwoNumbers(this.calculationService.multiplyTwoNumbers(this.calculationService.sumTwoNumbers(grossTotal, addedExciseTax), invBatch.discountPercentage), 100)
              // if (this.applyRoundingPerLine) calculatedDiscountTotal = this.calculationService.getNumberOnDigitFormat(calculatedDiscountTotal);
              // calculatedDiscountTotal += returnDiscountAmount;
              calculatedDiscountTotal = this.calculationService.sumTwoNumbers(calculatedDiscountTotal, returnDiscountAmount);
            }
          } else {
            // var calculatedDiscountTotal = (grossTotal + addedExciseTax) * invBatch.discountPercentage / 100;
            var calculatedDiscountTotal = this.calculationService.divideTwoNumbers(this.calculationService.multiplyTwoNumbers(this.calculationService.sumTwoNumbers(grossTotal, addedExciseTax), invBatch.discountPercentage), 100)
            // if (this.applyRoundingPerLine) calculatedDiscountTotal = this.calculationService.getNumberOnDigitFormat(calculatedDiscountTotal);
          }
          // var calculatedDiscountTotal = grossTotal * invBatch.discountPercentage/100;
          if (this.calculateTaxBeforeDiscount) {
            // calculatedTaxTotal = (grossTotal + calculatedExciseTaxAmount) * invBatch.baseTaxPercentage / 100;
            calculatedTaxTotal = this.calculationService.divideTwoNumbers(this.calculationService.multiplyTwoNumbers(this.calculationService.sumTwoNumbers(grossTotal, calculatedExciseTaxAmount), invBatch.baseTaxPercentage), 100)
          } else {
            // calculatedTaxTotal = (grossTotal - calculatedDiscountTotal + calculatedExciseTaxAmount) * invBatch.baseTaxPercentage / 100;
            calculatedTaxTotal = this.calculationService.divideTwoNumbers(this.calculationService.multiplyTwoNumbers(this.calculationService.sumTwoNumbers(this.calculationService.subtractTwoNumbers(grossTotal, calculatedDiscountTotal), calculatedExciseTaxAmount), invBatch.baseTaxPercentage), 100);
          }
          if (pack.salesTransactionTypeId === SalesTransactionTypes.FreeReturn && this.allowTaxOverFreeItems) {
            // calculatedTaxTotal = invBatch.basePrice * invBatch.requiredQuantity * pack.piecesInPack * invBatch.baseTaxPercentage / 100;
            calculatedTaxTotal = this.calculationService.divideTwoNumbers(this.calculationService.multiplyTwoNumbers(this.calculationService.multiplyTwoNumbers(this.calculationService.multiplyTwoNumbers(invBatch.basePrice, invBatch.requiredQuantity), pack.piecesInPack), invBatch.baseTaxPercentage), 100);
          }
          // if (this.applyRoundingPerLine) calculatedTaxTotal = this.calculationService.getNumberOnDigitFormat(calculatedTaxTotal);
          // var calculatedTaxTotal = (grossTotal - calculatedDiscountTotal) * invBatch.baseTaxPercentage/100;
          // var netTotal = grossTotal - calculatedDiscountTotal + calculatedTaxTotal + calculatedExciseTaxAmount;
          var netTotal = this.calculationService.sumTwoNumbers(this.calculationService.sumTwoNumbers(this.calculationService.subtractTwoNumbers(grossTotal, calculatedDiscountTotal), calculatedTaxTotal), calculatedExciseTaxAmount);
          if (idx > -1) {
            addedPacksBatchLevel[idx].requiredQty += invBatch.requiredQuantity;
            // addedPacksBatchLevel[idx].gross += grossTotal;
            addedPacksBatchLevel[idx].gross = this.calculationService.sumTwoNumbers(addedPacksBatchLevel[idx].gross, grossTotal);
            // addedPacksBatchLevel[idx].requiredQuantityInPcs += invBatch.requiredQuantity * pack.piecesInPack;
            addedPacksBatchLevel[idx].requiredQuantityInPcs = this.calculationService.sumTwoNumbers(addedPacksBatchLevel[idx].requiredQuantityInPcs, this.calculationService.multiplyTwoNumbers(invBatch.requiredQuantity, pack.piecesInPack));
            // addedPacksBatchLevel[idx].calculatedDiscountTotal += calculatedDiscountTotal;
            addedPacksBatchLevel[idx].calculatedDiscountTotal = this.calculationService.sumTwoNumbers(addedPacksBatchLevel[idx].calculatedDiscountTotal, calculatedDiscountTotal);;
            // addedPacksBatchLevel[idx].calculatedTaxTotal += calculatedTaxTotal;
            addedPacksBatchLevel[idx].calculatedTaxTotal = this.calculationService.sumTwoNumbers(addedPacksBatchLevel[idx].calculatedTaxTotal, calculatedTaxTotal);
            // addedPacksBatchLevel[idx].netTotal += netTotal;
            addedPacksBatchLevel[idx].netTotal = this.calculationService.sumTwoNumbers(addedPacksBatchLevel[idx].netTotal, netTotal);
            // addedPacksBatchLevel[idx].calculatedTax += calculatedTaxTotal;
            addedPacksBatchLevel[idx].calculatedTax = this.calculationService.sumTwoNumbers(addedPacksBatchLevel[idx].calculatedTax, calculatedTaxTotal);
            // addedPacksBatchLevel[idx].calculatedRetailTax += calculatedExciseTaxAmount;
            addedPacksBatchLevel[idx].calculatedRetailTax = this.calculationService.sumTwoNumbers(addedPacksBatchLevel[idx].calculatedRetailTax, calculatedExciseTaxAmount);
          } else {
            var batchObj = {
              batchNo: invBatch.batchNo,
              batchNoToView: invBatch.batchNo,
              expiryDateModel: invBatch.expiryDateModel,
              requiredQty: 0 + invBatch.requiredQuantity,
              itemName: pack.itemName,
              itemCode: pack.itemCode,
              packId: pack.packId,
              itemId: pack.itemId,
              uom: pack.uom,
              expiryDateToView: invBatch.expiryDateModel.date,
              netTotal: netTotal,
              gross: grossTotal,
              // requiredQuantityInPcs: 0 + invBatch.requiredQuantity * pack.piecesInPack,
              requiredQuantityInPcs: this.calculationService.sumTwoNumbers(this.calculationService.multiplyTwoNumbers(invBatch.requiredQuantity, pack.piecesInPack), 0),
              calculatedDiscountTotal: calculatedDiscountTotal,
              calculatedTaxTotal: calculatedTaxTotal,
              calculatedTax: calculatedTaxTotal,
              calculatedRetailTax: calculatedExciseTaxAmount,
              salesTransactionTypeId: pack.salesTransactionTypeId
            }
            addedPacksBatchLevel.push(batchObj);
          }
          invBatch.requiredPaymentAmount = requiredPaymentAmount;
          if (this.applyReturnDiscount) {
            invBatch.returnDiscountPercentage = returnDiscountPercentage;
            // if (pack.salesTransactionTypeId === SalesTransactionTypes.None) {
            //   this.updateReturnTotals(this.totals, grossTotal, calculatedTaxTotal, calculatedExciseTaxAmount, calculatedDiscountTotal, 0, netTotal);
            // } else if (pack.salesTransactionTypeId === SalesTransactionTypes.FreeReturn && this.allowTaxOverFreeItems) {
            //   this.updateReturnTotals(this.totals, 0, calculatedTaxTotal, 0, 0, 0, netTotal);
            // }
          } else {
            invBatch.returnDiscountPercentage = 0;
          }
        });

        addedPacksBatchLevel.forEach(batchObj => {
          // batchObj.priceString = batchObj.gross / batchObj.requiredQty;
          batchObj.priceString = this.calculationService.divideTwoNumbers(batchObj.gross,batchObj.requiredQty);
        });
      }
    });
    if (this.applyReturnDiscount) {
      addedPacksBatchLevel.forEach(pack => {
        var gross = this.applyRoundingPerLine ? this.calculationService.getNumberOnDigitFormat(pack.gross) : pack.gross;
        var discount = this.applyRoundingPerLine ? this.calculationService.getNumberOnDigitFormat(pack.calculatedDiscountTotal) : pack.calculatedDiscountTotal;
        var tax = this.applyRoundingPerLine && !this.applyZATCARoundingRules ? this.calculationService.getNumberOnDigitFormat(pack.calculatedTax) : pack.calculatedTax;
        var retailTax = this.applyRoundingPerLine ? this.calculationService.getNumberOnDigitFormat(pack.calculatedRetailTax) : pack.calculatedRetailTax;
        var netTotal = this.applyRoundingPerLine ? this.calculationService.getNumberOnDigitFormat(pack.netTotal) : pack.netTotal;
        if (pack.salesTransactionTypeId === SalesTransactionTypes.None) {
          this.updateReturnTotals(this.totals, gross, tax, retailTax, discount, 0, netTotal);
        } else if (pack.salesTransactionTypeId === SalesTransactionTypes.FreeReturn && this.allowTaxOverFreeItems) {
          this.updateReturnTotals(this.totals, 0, tax, 0, 0, 0, netTotal);
        }
      })
      this.totals.itemsCount = this.calculationService.fillItemsCount(this.itemsList);
      this.totals.itemsQtyTotal = 0;
      this.itemsList.map(item => this.totals.itemsQtyTotal = this.totals.itemsQtyTotal + item.requiredQty);
      this.calculationService.roundTotals(this.totals);
    }

    return addedPacksBatchLevel;
  }

  updateReturnTotals(totals: TotalsModel, gross, calculatedTax, calculatedRetailTax, calculatedDiscountTotal, calculatedPromotedDiscount, netTotal) {
    // totals.grossTotal = totals.grossTotal + gross;
    totals.grossTotal = this.calculationService.sumTwoNumbers(totals.grossTotal,gross);
    // totals.taxTotal = totals.taxTotal + calculatedTax;
    totals.taxTotal = this.calculationService.sumTwoNumbers(totals.taxTotal,calculatedTax);
    // totals.retailTaxTotal = totals.retailTaxTotal + calculatedRetailTax;
    totals.retailTaxTotal = this.calculationService.sumTwoNumbers(totals.retailTaxTotal,calculatedRetailTax);
    // totals.discountTotal = totals.discountTotal + calculatedDiscountTotal;
    totals.discountTotal = this.calculationService.sumTwoNumbers(totals.discountTotal,calculatedDiscountTotal);
    // totals.promotedDiscountTotal = totals.promotedDiscountTotal + calculatedPromotedDiscount;
    totals.promotedDiscountTotal = this.calculationService.sumTwoNumbers(totals.promotedDiscountTotal,calculatedPromotedDiscount);
    // totals.netTotal += netTotal;
    totals.netTotal = this.calculationService.sumTwoNumbers(totals.netTotal,netTotal);
  }

  onBackClicked() {
    this.summaryList.totalItems = 0;
    this.summaryList.data = [];
    this.totals = new TotalsModel();
    this.itemsList = [];
    this.backEvent.emit();
  }
  onSaveClicked() {
    this.saveEvent.emit();
  }
  onRecurringOrderClicked() {
    this.recurringEvent.emit();
  }
}
