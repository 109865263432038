import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs-compat';
import { ItemService } from '../../../services/data-definition/product/item.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-items-filter',
  templateUrl: './items-filter.component.html',
  styleUrls: ['./items-filter.component.css']
})
export class ItemsFilterComponent implements OnInit, OnChanges {
  @Input() parentForm: FormGroup;
  @Input() currentFormControlName = 'itemId'
  @Input() itemCategoryId: number = -1;
  @Input() divisionId: number = -1;
  @Input() brandId: number = -1;
  @Input() modelId: number = -1;
  @Input() itemId: number = -1;
  @Input() itemTypeId: number;
  @Input() selectedItemId = -1;
  @Input() hideLabel = false;
  @Input() showUpperLimitOnly: boolean = false;
  @Input() excludedItemIdsList: any[] = [];
  @Output() selectedItem = new EventEmitter();
  itemsDataSource: any[] = [];
  isItemsLoading = false;
  isRequired = false;
  itemsInput$ = new Subject<string>();
  selectedItemObj: any;
  itemFilter: any = {
    ItemCategoryId: null,
    divisionId: this.divisionId ? this.divisionId : -1,
    brandId: this.brandId ? this.brandId : -1,
    modelId: this.modelId ? this.modelId : -1,
    itemId: this.itemId ? this.itemId : -1,
    showUpperLimitOnly: this.showUpperLimitOnly,
    excludeCompetitorItem: true,
    IsLazyLodingCombo: true,
    selectItemId: -1,
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: 15
    },
    excludedItemIdsList: []
  };

  bufferSize = 200;

  constructor(private itemService: ItemService) { }

  ngOnInit() {
    //Check if input is required.
    if (this.parentForm.get(this.currentFormControlName).validator != null) {
      const validator = this.parentForm.get(this.currentFormControlName).validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    // this.populateItemsList();
    // this.onItemSearch();
  }

  ngOnChanges() {
    this.itemFilter = {
      ItemCategoryId: this.itemCategoryId ? this.itemCategoryId : -1,
      divisionId: this.divisionId ? this.divisionId : -1,
      brandId: this.brandId ? this.brandId : -1,
      modelId: this.modelId ? this.modelId : -1,
      itemId: this.itemId ? this.itemId : -1,
      showUpperLimitOnly: this.showUpperLimitOnly ? this.showUpperLimitOnly : false,
      excludeCompetitorItem: true,
      IsLazyLodingCombo: true,
      selectItemId: this.selectedItemId ? this.selectedItemId : -1,
      customListFilter: {
        searchFilter: '',
        page: 0,
        pageSize: 15
      },
      excludedItemIdsList: this.excludedItemIdsList
    };
    // this.itemsDataSource = [];
    this.populateItemsList();
    this.onItemSearch();
  }

  populateItemsList() {
    this.isItemsLoading = true;
    this.itemFilter.ItemCategoryId = this.itemCategoryId == null ? -1 : this.itemCategoryId;
    this.itemFilter.divisionId = this.divisionId == null ? -1 : this.divisionId;
    if (this.selectedItemId > 0) {
      this.itemFilter.selectItemId = this.selectedItemId;
    } else {
      this.itemFilter.selectItemId = -1;
    }
    this.itemFilter.excludedItemIdsList = this.excludedItemIdsList ? this.excludedItemIdsList : [];
    this.itemService.getItemsListSharedTable(this.itemFilter)
      .subscribe(result => {

        if (result != undefined && result.data != undefined) {
          if (result.data.totalItems === 0) {
            this.itemsDataSource = [];
          }
          if (this.itemFilter.customListFilter.page == 0) {
            this.itemsDataSource = result.data.data;
          } else
            this.itemsDataSource = this.itemsDataSource.concat(
              result.data.data
            );
        }
        this.isItemsLoading = false;
      });
  }


  fetchMoreItemsFromServer(term) {

    // this.itemFilter.customListFilter.searchFilter = term;
    this.itemFilter.customListFilter.page = this.itemsDataSource.length;
    this.populateItemsList();
  }

  onItemSearchClear() {
    this.itemFilter = {
      ItemCategoryId: this.itemCategoryId ? this.itemCategoryId : -1,
      divisionId: this.divisionId ? this.divisionId : -1,
      brandId: this.brandId ? this.brandId : -1,
      modelId: this.modelId ? this.modelId : -1,
      itemId: this.itemId ? this.itemId : -1,
      showUpperLimitOnly: this.showUpperLimitOnly ? this.showUpperLimitOnly : false,
      excludeCompetitorItem: true,
      IsLazyLodingCombo: true,
      selectItemId: -1,
      customListFilter: {
        searchFilter: '',
        page: 0,
        pageSize: 15
      }
    };
  }

  onItemSearch() {
    this.itemsInput$
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        switchMap(term => this.getItemSearchResultFromServer(term))
      )
      .subscribe(data => {
        this.itemsDataSource = data.slice(0, this.bufferSize);
      });
  }
  getItemSearchResultFromServer(term) {
    this.itemFilter.customListFilter.page = 0;
    this.itemsDataSource = null;
    this.itemFilter.customListFilter.searchFilter = term;
    return this.itemService
      .getItemsListSharedTable(this.itemFilter)
      .map(response => {
        return response.data.data;
      });
  }

  fillValuesCategoryIdAndDivisionId() {
    if (this.itemCategoryId != -1 && this.itemCategoryId != null) {
      this.itemFilter.ItemCategoryId = this.itemCategoryId;
    }
    if (this.divisionId != -1 && this.divisionId != null) {
      this.itemFilter.divisionId = this.divisionId;
    }
  }

  onItemDataChanged($event) {
    if ($event) {
      this.selectedItemObj = $event;
    } else {
      this.selectedItemObj = undefined;
    }
    this.selectedItem.emit(this.selectedItemObj);
  }



}
