import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { CoreSession } from '../../../../core/core.session';

@Component({
  selector: 'app-side-filter-template',
  templateUrl: './side-filter-template.component.html',
  styleUrls: ['./side-filter-template.component.css']
})
export class SideFilterTemplateComponent implements OnInit {

  @Input('bodyTemplet') bodyTemplet: TemplateRef<any>;
  @Output('onResetClicked') onResetClicked = new EventEmitter();
  @Output('onFindClicked') onFindClicked = new EventEmitter();
  @Input() closeFilter: boolean = true;

  constructor(private coreSession: CoreSession) { }

  ngOnInit() {
  }

  onFindClick() {
    this.onFindClicked.emit();
    if (this.closeFilter)
      this.coreSession.closeOnFind.next();
  }

  onReset() {
    this.onResetClicked.emit();
  }

}
