import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  TemplateRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { ITableProperties } from "../../models/shared-table/table-properties.interface";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { CustomListFilter } from "../../models/shared-table/custom-list.interface";
import { SharedTableResult } from "../../models/shared-table/shared-table-result.interface";
import {
  RowOperation,
  MultiSelectionOperation,
} from "../../models/enums/shared-table-operation.enum";
import { CoreSession } from "../../../core/core.session";
import { TranslateService } from "@ngx-translate/core";
import { ConstantMessages } from "../../models/constants/constant-message";
import { DataTypes } from "../../models/enums/data-types.enum";
import { ItemService } from "../../services/data-definition/product/item.service";
import { ImportDialogComponent } from "../import-dialog/import-dialog.component";
import { ImportDataTypes } from "../../models/import/ImportDataTypes";
import { SessionDataProvider } from "../../../core/session-data-provider.service";
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDragRelease,
} from "@angular/cdk/drag-drop";
import { ConstantConfigurations } from "../../models/constants/constant-configuration";
import { ShowImagesDialogComponent } from "../show-images-dialog/show-images-dialog.component";

@Component({
  selector: "app-shared-table",
  templateUrl: "./shared-table.component.html",
  styleUrls: ["./shared-table.component.css"],
})
export class SharedTableComponent implements OnInit, OnChanges {
  @ViewChild("showImagesDialog", { static: true }) showImagesDialog: ShowImagesDialogComponent;
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("import") import: ImportDialogComponent;
  imagesUrls: any = [];
  //#region [Outputs]
  @Output()
  tableFilterChanged = new EventEmitter();

  @Output()
  newBtnClicked = new EventEmitter();

  @Output()
  newImportClicked = new EventEmitter();

  @Output()
  onImportCompleted = new EventEmitter();

  @Output()
  extraBtnClicked = new EventEmitter();

  @Output()
  saveBtnClicked = new EventEmitter();

  @Output()
  closeBtnClicked = new EventEmitter();

  @Output()
  plusMinusBtnClicked = new EventEmitter();

  @Output()
  rowOperation = new EventEmitter();

  @Output()
  multiSelectionOperation = new EventEmitter();

  @Output()
  checkAllOperation = new EventEmitter();

  @Output()
  checkRowOperation = new EventEmitter();

  @Output()
  onValueChanged = new EventEmitter();

  @Output()
  onValueChange = new EventEmitter();

  @Output()
  onDragDrop = new EventEmitter();

  @Output()
  onDisabledInputClicked = new EventEmitter();

  @Output()
  localFilterChanged = new EventEmitter();
  //#endregion

  //#region [Inputs]
  @Input() currentId = "";
  @ViewChild("fileImportInput", { static: true }) fileImportInput: any;
  @Input() dataType: ImportDataTypes;
  @Input() multiSelectDataSource: any[] = [];
  @Input() multiSelectDropdownSettings: any = {};
  @Input() ngSelectDataSource: any[] = [];
  @Input() isFilteredDataSource = false;
  @Input() ngSelectDropdownSettings: any = {};// add (bindValue and bindLabel)
  @Input() applyCopyNameToSelectedValueForMultiSelect: false;
  @Input() displayMultiTextSettings = {};
  @Input("filterBody") filterBody: TemplateRef<any>;
  @Input() multipleBooleans = false;
  @Input() rowButton = false;
  @Input() disabled = false;
  @Input() dataLoaded = false;
  @Input() oddEvenRowColor = true;
  @Input()
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: [],
  };
  @Input() listOfDataSources: SharedTableResult[] = [];
  @Input() increaseFilterBodyWidth: boolean = false;
  @Input() searchText;
  @Input() disableInputTextIfNotChecked: boolean = false;
  @Input() onlyNumber: boolean = true;
  @Input()
  TabelProperties: ITableProperties = {
    pageSizeOptions: this.coreSession.pageSizeOptions,
    pageSize: 1,
    showPaginator: false,
    showSearch: false,
    isOnline: false,
    showSearchBtn: false,
    newBtnCaption: "Desc_New",
    importBtnCaption: "Desc_Import",
    extraBtnCaption: "Extra",
    extraBtnIcon: "fa fa-download",
    SearchBoxLabel: "Desc_Enter_To_Search",
    specificActionWhenCheckAll: false,
    specificActionWhenCheckRow: false,
    showOptionButtonsSeparated: false,
    showAllButtons: true,
    showEditButton: true,
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [],
    isConfigurationScreen: false,
  };
  @Input() refreshTable: boolean = false;
  @Input() disableDragDropPerItem: boolean = false;
  @Input() hideSelectAll: boolean = false;
  @Input() changeFilterPosition: boolean = false;
  @Input() disabledNgSelect: boolean = false;
  @Input() entryFormObj: any = {};
  //#endregion

  //#region [DECLARATIONS]
  query: CustomListFilter = {
    page: null,
    pageSize: null,
  };
  pageIndex = 0;
  textAlign = "";
  minWith = "0";
  paddingButtons = "1";
  singlDataSourceAsArray: SharedTableResult[] = [];
  isSelectAll = false;
  selectedRowsList: any[] = [];
  cloneDataSourceItems: any[] = [];
  originalDataSourceCount: number = 0;
  isFilterOpend = false;
  resetPaginator = true;
  rowSelected: any = -1;
  maxNumberOfDigitsLength: string = "";
  maxNumberOfStockDigitsLength: string = "";
  numberOfDigits: number = 0;
  numberOfStockDigits: number = 0;
  noImagePath = document.getElementsByTagName("base")[0].href + "Resources/noProduct.jpg";
  isSearch = false;
  //#endregion

  //#region [Life Cycle Hooks]
  constructor(
    public coreSession: CoreSession,
    private translateService: TranslateService,
    public sessionDataProvider: SessionDataProvider
  ) { }
  // ngAfterViewInit(): void {
  //   setTimeout(() => {
  //     if (this.searchInput && this.searchInput.nativeElement) this.searchInput.nativeElement.focus();
  //   }, 50);
  // }
  pageSizeOptions = [];
  ngOnInit() {
    this.textAlign = this.coreSession.isRTL ? "left" : "right";
    if (this.currentId && this.currentId != "") {
      this.currentId = "_" + this.currentId;
    }
    if (
      this.TabelProperties.pageSizeOptions &&
      this.TabelProperties.pageSizeOptions.length > 0
    ) {
      this.pageSizeOptions = this.TabelProperties.pageSizeOptions;
    } else {
      this.pageSizeOptions = this.coreSession.pageSizeOptions;
    }
    if (
      !this.TabelProperties.pageSize ||
      !this.pageSizeOptions.includes(this.TabelProperties.pageSize)
    ) {
      this.TabelProperties.pageSize = this.coreSession.pageSize;
    }
    if (this.TabelProperties.openFilterDirectly) {
      this.isFilterOpend = true;
    }
    if (!this.TabelProperties.newBtnCaption) {
      this.TabelProperties.newBtnCaption = "Desc_New";
    }
    if (!this.TabelProperties.importBtnCaption) {
      this.TabelProperties.importBtnCaption = "Desc_Import";
    }
    if (!this.TabelProperties.SearchBoxLabel) {
      this.TabelProperties.SearchBoxLabel = "Desc_Enter_To_Search";
    }
    // items List represents original items in one page, and on move to onother page it will change

    if (this.dataSource) {
      if (
        this.TabelProperties.showPaginator &&
        this.dataSource.data.length > this.TabelProperties.pageSize
      ) {
        this.dataSource.pageData = this.dataSource.data.slice(
          0,
          this.TabelProperties.pageSize
        );
      } else {
        this.dataSource.pageData = this.dataSource.data;
      }
    }
    this.query.searchFilter = this.searchText;
    if (this.dataSource && this.dataSource.data) {
      let index = this.dataSource.data.findIndex((d) => d.selected == true);
      if (index > -1) {
        this.rowSelected = index;
      }
    }
    // //Until fixing multi selection design
    // this.TabelProperties.multiSelectionOperations=[];
    // this.TabelProperties.isMultiSelection=false;
    this.numberOfDigits = +this.sessionDataProvider.getConfigurationValue(
      ConstantConfigurations.NumberOfDigits
    );
    this.maxNumberOfDigitsLength = 9 + this.numberOfDigits + "";
    this.numberOfStockDigits = +this.sessionDataProvider.getConfigurationValue(
      ConstantConfigurations.NumberOfStockDigits
    );
    this.maxNumberOfStockDigitsLength = 9 + this.numberOfStockDigits + "";
  }

  ngOnChanges() {
    //Until fixing multi selection design
    // this.TabelProperties.multiSelectionOperations=[];
    // this.TabelProperties.isMultiSelection=false;
    let currentPage = this.pageIndex;
    this.cloneDataSourceItems = this.dataSource.data;

    if (this.TabelProperties.isMultiSelection) {
      //this.dataSource.data.forEach(a => (a.isChecked = false));
      this.isSelectAll = false;
      const count = this.dataSource.data.filter(
        (a) => a.isChecked === true
      ).length;
      if (count === this.dataSource.data.length && count > 0) {
        this.isSelectAll = true;
      }
    }
    if (this.dataSource && this.dataSource.data)
      this.dataSource.data.forEach((a) => (a.enableEditMode = false));
    if (this.listOfDataSources.length === 0) {
      this.singlDataSourceAsArray = [];
      this.singlDataSourceAsArray.push(this.dataSource);
    }
    if (this.dataSource && this.dataSource.data) {
      if (
        this.TabelProperties.showPaginator &&
        this.dataSource.data.length > this.TabelProperties.pageSize
      ) {
        this.dataSource.pageData = this.dataSource.data.slice(
          0,
          this.TabelProperties.pageSize
        );
      } else {
        this.dataSource.pageData = this.dataSource.data;
      }

      if (!this.query.page || this.query.page == 0) this.pageIndex = 0;
    }

    if (this.originalDataSourceCount > this.dataSource.totalItems) {
      let index = currentPage;
      let totalPages =
        this.dataSource.totalItems / this.TabelProperties.pageSize;

      totalPages = Math.ceil(totalPages);
      if (index >= totalPages - 1) {
        index = totalPages;
        index = Math.ceil(index);
        if (index - 1 > -1) {
          index = index - 1;
        }
      }
      this.pageIndex = index;

      if (this.TabelProperties.isOnline) {
        this.query.page = this.pageIndex;
        this.query.pageSize = this.TabelProperties.pageSize;
        //if (!this.isSearch) 
        //this.tableFilterChanged.emit(this.query);
      } else {
        let startIndex = this.pageIndex * this.TabelProperties.pageSize + 1;
        let endIndex = (this.pageIndex + 1) * this.TabelProperties.pageSize;
        this.dataSource.pageData = this.dataSource.data.slice(
          startIndex - 1,
          endIndex
          );
        }
    }
    this.originalDataSourceCount = this.dataSource.totalItems;
    this.CustomizationShredTable();
    this.copyNameToSelectedValueForMultiSelect();
    this.isSearch = false;
  }

  //#endregion

  //#region [Filters]
  applaySearchFilter() {
    if (this.TabelProperties.isOnline) {
      this.query.page = 0;
      this.query.pageSize = this.TabelProperties.pageSize;
      this.isSearch = true;
      this.tableFilterChanged.emit(this.query);
      if (this.TabelProperties.clearSearchBoxAfterFinish) {
        this.query.searchFilter = "";
      }
    } else {
      this.localFilter();
      if (this.dataSource) {
        this.dataSource.totalItems = this.dataSource.data.length;
        if (this.dataSource.data.length > this.TabelProperties.pageSize) {
          this.dataSource.pageData = this.dataSource.data.slice(
            0,
            this.TabelProperties.pageSize
          );
        } else {
          this.dataSource.pageData = this.dataSource.data;
        }
      }
      this.localFilterChanged.emit(this.query.searchFilter);
    }
    this.pageIndex = 0;
  }

  localFilter() {
    if (!this.query.searchFilter) {
      this.query.searchFilter = "";
    }
    this.query;
    if (
      this.query.searchFilter.trim().length === 0 ||
      this.query.searchFilter.trim() === "" ||
      this.query.searchFilter.trim().length === null
    ) {
      this.dataSource.data = this.cloneDataSourceItems.slice();
      return;
    }
    // if(!this.cloneDataSourceItems || this.cloneDataSourceItems.length==0){
    //   this.cloneDataSourceItems = this.dataSource.data;
    //}
    const TempArray = [];
    for (const row of this.cloneDataSourceItems) {
      for (const column of this.TabelProperties.columns) {
        if (
          (row[column.key] + "")
            .toLowerCase()
            .includes(this.query.searchFilter.toLowerCase().trim())
        ) {
          TempArray.push(row);
          break;
        }
      }
    }
    this.dataSource.data = TempArray.slice();
  }
  //#endregion

  //#region [Sorting]
  sortBy(columnName: string) {
    if (this.query.sortBy === columnName) {
      this.query.isSortAscending = !this.query.isSortAscending;
    } else {
      this.query.sortBy = columnName;
      this.query.isSortAscending = true;
    }

    if (this.TabelProperties.isOnline) {
      this.query.page = 0;
      this.query.pageSize = this.TabelProperties.pageSize;
      this.tableFilterChanged.emit(this.query);
    } else {
      // Offline Sorting
      if (this.listOfDataSources.length === 0) {
        this.offlineSort(columnName, this.query.isSortAscending);
        if (this.dataSource) {
          this.dataSource.totalItems = this.dataSource.data.length;
          if (this.dataSource.data.length > this.TabelProperties.pageSize) {
            this.dataSource.pageData = this.dataSource.data.slice(
              0,
              this.TabelProperties.pageSize
            );
          } else {
            this.dataSource.pageData = this.dataSource.data;
          }
        }
      }
    }
    this.pageIndex = 0;
  }
  offlineSort<T>(propName, isSortAscending): void {
    this.singlDataSourceAsArray[0].data.sort((a, b) => {
      if (isSortAscending) {
        if (a[propName].toLowerCase() > b[propName].toLowerCase()) return -1;
        if (a[propName].toLowerCase() < b[propName].toLowerCase()) return 1;
        return 0;
      } else {
        if (a[propName].toLowerCase() < b[propName].toLowerCase()) return -1;
        if (a[propName].toLowerCase() > b[propName].toLowerCase()) return 1;
        return 0;
      }
    });
  }
  onToggleModule(item: any, key: any) {
    item;
    let event = null;
    if (this.multipleBooleans) {
      if (!item.booleanValue && item.mandatory) {
        item.mandatory = false;
      }
      event = {
        object: item,
        key: key,
      };
    } else {
      item[key] = item.booleanValue;
      event = {
        object: item,
        key: key,
      };
    }
    this.onValueChanged.emit(event);
  }
  onTextChanged(item: any, key: any) {
    item;
    let event = {
      object: item,
      key: key,
    };
    this.onValueChanged.emit(event);
  }

  onTextChange(item: any, key: any) {
    item;
    let event = {
      object: item,
      key: key,
    };
    this.onValueChange.emit(event);
  }

  onMultiselectDropdownChanged(item: any, key: any) {
    item;
    let event = {
      object: item,
      key: key,
    };
    this.onValueChanged.emit(event);
  }

  onNgSelectDropdownChanged(item: any, key: any, rowIndex: any) {
    let event = {
      object: item,
      key: key,
      rowIndex: rowIndex
    };
    this.onValueChanged.emit(event);
  }


  onChangePage(pageEvent: PageEvent) {
    if (this.TabelProperties.pageSize != pageEvent.pageSize) {
      this.pageIndex = 0;
      pageEvent.pageIndex = 0;
      this.TabelProperties.pageSize = pageEvent.pageSize;
    }
    this.query.page = pageEvent.pageIndex;
    if (this.TabelProperties.isOnline) {
      this.query.pageSize = pageEvent.pageSize;
      this.tableFilterChanged.emit(this.query);
    } else {
      let startIndex = pageEvent.pageIndex * this.TabelProperties.pageSize + 1;
      let endIndex = (pageEvent.pageIndex + 1) * this.TabelProperties.pageSize;
      this.dataSource.pageData = this.dataSource.data.slice(
        startIndex - 1,
        endIndex
      );
    }
    this.pageIndex = pageEvent.pageIndex;
    this.copyNameToSelectedValueForMultiSelect();
  }
  //#endregion

  //#region [ClickEvents and MultiSelections]
  onRowOperationClicked(
    item: any,
    selectedOperation: any,
    currentIndex: number
  ) {
    const event = {
      object: item,
      index: currentIndex,
      operation: selectedOperation.operation,
    };
    if (selectedOperation.operation == this.rowOperations.Select.valueOf()) {
      if (currentIndex == this.rowSelected) {
        this.dataSource.data[this.rowSelected].selected = false;
        this.rowSelected = -1;
      } else {
        if (this.rowSelected >= 0) {
          this.dataSource.data[this.rowSelected].selected = false;
        }
        this.rowSelected = currentIndex;
        this.dataSource.data[currentIndex].selected = true;
      }
    }

    this.onClickedAnyButton(item, currentIndex, selectedOperation.operation);
  }

  onClickedAnyButton(
    item: any,
    currentIndex: number,
    selectedOperation: number
  ) {
    const event = {
      object: item,
      index: currentIndex,
      operation: selectedOperation,
    };
    this.rowOperation.emit(event);
  }

  onRowChecked(item: any) {
    item.isChecked = !item.isChecked;
    var count = this.dataSource.data.filter(
      (a) => a.isChecked === false
    ).length;
    if (count > 0) {
      this.isSelectAll = false;
    }
    count = this.dataSource.data.filter((a) => a.isChecked === true).length;
    if (count === this.dataSource.data.length && count > 0) {
      this.isSelectAll = true;
    }
    if (this.TabelProperties.specificActionWhenCheckRow) {
      this.checkRowOperation.emit(item);
    }
  }

  onSelectAll() {
    this.isSelectAll = !this.isSelectAll;
    this.dataSource.data.forEach((o) => {
      if (o.languageId !== 1 && o.languageId !== 2) {
        o.isChecked = this.isSelectAll;
      }
    });
    if (this.TabelProperties.specificActionWhenCheckAll) {
      this.checkAllOperation.emit(this.isSelectAll);
    }
  }

  openFilter() {
    this.isFilterOpend = !this.isFilterOpend;
  }
  onCloseFilter() {
    this.isFilterOpend = false;
  }
  onNewClicked() {
    this.newBtnClicked.emit();
  }
  onImportClicked() {
    if (this.dataType != ImportDataTypes.MustSellItems)
      this.openImportDialog();
    this.newImportClicked.emit();
  }
  onExtraBtnClicked() {
    this.extraBtnClicked.emit();
  }
  onSaveClicked() {
    this.saveBtnClicked.emit();
  }
  onCloseClicked() {
    this.closeBtnClicked.emit();
  }
  onPlusMinusBtnClicked(item: any, key: any) {
    item;
    let event = {
      object: item,
      key: key,
    };
    this.plusMinusBtnClicked.emit(event);
  }

  openImportDialog() {
    this.import.showDialog(this.dataType).then((res) => {
      if (res == -1) {
        return false;
      } else if (res == 1) {
        //this.onImportCompleted.emit();
      }
    });
  }
  onImportDialogCompleted(event: any) {
    this.onImportCompleted.emit(event);
  }
  onMultiSelectionOperationClicked(selectedOperation: any) {
    const rows = this.findSelectedRows();
    if (rows.length > 0) {
      const event = {
        object: rows,
        operation: selectedOperation.operation,
      };
      this.multiSelectionOperation.emit(event);
    } else {
      this.coreSession.showWarrning(
        this.translateService.instant(ConstantMessages.WarningCaption),
        this.translateService.instant(ConstantMessages.MsgNoRowSelected)
      );
    }
  }

  findSelectedRows(): any[] {
    return this.dataSource.data.filter((a) => a.isChecked === true);
  }
  isBooleanValue(valueType: number) {
    return valueType === DataTypes.boolean.valueOf();
  }
  isStringValue(valueType: number) {
    return valueType === DataTypes.String.valueOf();
  }
  isNumberValue(valueType: number) {
    return valueType === DataTypes.number.valueOf();
  }
  isButtonValue(valueType: number) {
    return valueType === DataTypes.Button.valueOf();
  }
  //#endregion

  //#region [Getters]
  get rowOperations() {
    return RowOperation;
  }

  get selectionOperations() {
    return MultiSelectionOperation;
  }

  //#endregion

  //#region [METHODS]

  copyNameToSelectedValueForMultiSelect() {
    if (this.applyCopyNameToSelectedValueForMultiSelect)
      if (
        this.multiSelectDataSource &&
        this.multiSelectDataSource.length > 0 &&
        this.dataSource.pageData &&
        this.dataSource.pageData.length > 0
      ) {
        this.multiSelectDropdownSettings.idField;
        this.multiSelectDropdownSettings.textField;
        this.dataSource.pageData.forEach((a) => {
          a.routesList.forEach((r) => {
            let index = this.multiSelectDataSource.findIndex(
              (a) =>
                a[this.multiSelectDropdownSettings.idField] ===
                r[this.multiSelectDropdownSettings.idField]
            );
            if (index > -1) {
              r[this.multiSelectDropdownSettings.textField] =
                this.multiSelectDataSource[index][
                this.multiSelectDropdownSettings.textField
                ];
            }
          });
        });
      }
  }
  undoChanges(changedProperty: any, changedObject: any) {
    let languageID = changedProperty.substr(changedProperty.length - 1);
    const index = this.dataSource.data.findIndex(
      (i) => i.DescriptionKey === changedObject.DescriptionKey
    );
    this.dataSource.data[index]["DescriptionValue" + languageID] =
      this.dataSource.data[index]["DescriptionBeforeEdit" + languageID];
  }

  dropRow(event: CdkDragDrop<string[]>) {
    if (this.TabelProperties.isAllowDragDrop) {
      if (
        (!this.disableDragDropPerItem) ||
        (this.disableDragDropPerItem && this.dataSource.data[event.currentIndex].isChecked === true)
      ) {
        moveItemInArray(
          this.dataSource.data,
          event.previousIndex,
          event.currentIndex
        );
        this.onDragDrop.emit(event);
      } else {
        return;
      }
    }
  }

  onDisabledInputClick(item, key) {
    if (item && item != undefined && item.disableBooleanInput && item.isChecked) {
      var obj = {
        item: item,
        key: key
      }
      this.onDisabledInputClicked.emit(obj);
    }
  }

  CustomizationShredTable() {
    // let alreadyExistEditIcon = this.TabelProperties.rowOperations.find(x => x.operation == 33) != undefined ? true : false;
    if (this.TabelProperties.rowOperations != undefined) {
      let existDeleteIcon =
        this.TabelProperties.rowOperations.find(
          (x) => x.title == "Desc_Delete"
        ) != undefined
          ? true
          : false;

      if (existDeleteIcon) {
        this.TabelProperties.rowOperations.find(
          (x) => x.title == "Desc_Delete"
        ).icon = "fa fa-trash-o";
      }

      this.TabelProperties.rowOperations.sort(
        (a, b) => b.operation - a.operation
      );
      this.minWith = this.TabelProperties.rowOperations.length * 33 + "px";
      //this.paddingButtons = ((this.TabelProperties.rowOperations.length * 33) >= 99 ? 16 : 7) + 'px';
    }
  }

  showPaginator() {
    let result = false;
    if (
      this.TabelProperties.showPaginator &&
      this.dataSource.totalItems > this.pageSizeOptions[0]
    ) {
      result = true;
    }
    // this.dataSource.totalItems < this.pageSizeOptions[0] // if items count less thant minimum size option then no need to shw pagination
    return result;
  }
  onViewImageUrl(url, title){
    this.imagesUrls = [];
    if(url){
      let selectedImage = {
        url: url,
        title: this.translateService.instant(title),
        hasImage: true
      }
      this.imagesUrls.push(selectedImage);
      this.showImagesDialog.showDialog().then(
        (result) => {
          if (result != -1) {
          }
        });
    }
  }
  //#endregion
}
