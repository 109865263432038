import { Component, OnInit, Input, Output, EventEmitter,OnChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TerritoryService } from '../../../../content/master-data-definition/employee-managment/territory/territory.service';

@Component({
  selector: 'app-territory-filter',
  templateUrl: './territory-filter.component.html',
  styleUrls: ['./territory-filter.component.css']
})
export class TerritoryFilterComponent implements OnInit,OnChanges {

  @Input() parentForm: FormGroup;
  @Input() customeExchangeRouteCustomer = false;
  @Input() masterTerritoryId: number = -1;
  @Input() selectedOrgIds: string;
  @Output() onChangeTerritory = new EventEmitter();
  territoryDataSource: any[];
  isTerritoryLoading = false;
  isRequired = false;

  @Input() hideInactiveTerritories = false;
  constructor(private territoryService: TerritoryService) { }

  ngOnInit() {
    if (this.parentForm.get('territoryId').validator != null) {
      const validator = this.parentForm.get('territoryId').validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    // this.populateTerritoryList();
  }
  ngOnChanges() {
    this.populateTerritoryList();
  }
  populateTerritoryList() {
    let territoryFilter = {
      hideInactiveTerritories : this.hideInactiveTerritories ,
      masterTerritoryId: this.masterTerritoryId ? this.masterTerritoryId : -1,
      organizationIds : this.selectedOrgIds
    }

    this.isTerritoryLoading = true;
    this.territoryService.getAllTerritories(territoryFilter).subscribe(result => {
      if (this.customeExchangeRouteCustomer) {
        let rowIndex =  result.data.findIndex(x=>x.territoryId == this.parentForm.get('territoryId').value);
        if(rowIndex < 0)
        {
          this.parentForm.get('territoryId').setValue(null)
          this.onChangeSelectedTerritory(undefined)
        }
      }
      this.territoryDataSource = result.data;
      this.isTerritoryLoading = false;
    });
  }
  onChangeSelectedTerritory(selectedTerritory) {
    this.onChangeTerritory.emit(selectedTerritory);
  }

}
