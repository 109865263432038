export const ConstantURLs = {
  searchResultURL: "search-result",
  homeUrl: "home",
  home2Url: "home2",
  //Sidebar urls
  dataDefinitionURL: "data-definition",
  orderManagementURL: "order-management",
  consignmentOrderURL: "consignment-order",
  customerAppPropertiesURL: "customer-app-properties",
  warehouseOperationURL: "warehouse-operation",
  vehicleOperationURL: "vehicle-operation",
  tripManagementURL: "trip-management",
  employeeOperationURL: "employee-operation",
  customerOperationsURL: "customer-operation",
  reportURL: "reports",
  dashboardURL: "dashboard",
  reportsURL: "reports",
  atreportsURL: "at-reports",
  settingsURL: "settings",
  roiMainURL: "roi",
  serialOperationURL: "serial-operation",
  forecastingURL: "forecasting",
  //Data Definitions urls
  staffURL: "staff",
  machineURL: "machine",
  customerURL: "customer",
  productUrl: "product",
  dynamicFormMainURL: "dynamic-form",
  priceAndOfferURL: "price-offer",
  warehouseAndVehicleURL: "warehouse-vehicle",
  qualityControlReadingURL: "quality-control-reading",
  displayUnitURL: "display-unit",
  customeMobileViewURL: "customize-mobile",
  packStatusesURL: "pack-statuses",

  //Data Definitions --> Staff URLs
  employeeURL: "employees",
  territoryURL: "territories",
  employeeChannelsURL: "employee-channel",
  newEmployeeURL: "employees/new-employee",
  editEmployeeURL: "employees/edit-employee",
  employeeItemsListURL: "employee-items-lists",
  chattingListAssignmentURL: "chatting-list-assignment",
  moveInvoicesURL:"move-invoices",


  //Data Definitions --> Machine URLs
  machineBoxURL: "machine-boxes",
  machineTemplatesURL: "machine-templates",
  coffeeTemplatesURL: "coffee-templates",

  //Data Definitions --> Customer URLs
  customersURL: "customers",
  customerAddURL: "customers/new-customer",
  customerEditURL: "customers/edit-customer",
  customerTemplatesURL: "customer-templates",
  creditReleasesURL: "credit-releases",
  customerItemsListURL: "customer-items-lists",
  CustomerOosListURL: "customer-oos-list",
  customerPointsBalanceURL: "customer-points-balance",
  CustomerICashURL: "customer-icash",
  CustomerBlockedOperationsURL: "blocked-operations",
  CustomerSalesRestrictionURL: "customer-sales-restriction",
  customersHierarchyURL: "customers-hierarchy",
  itemBlackListURL: 'item-black-list',
  mustSellItems: 'must-sell-items',
  mustSellItem: 'must-sell-item',

  //Data Definitions --> Product URLs
  itemsURL: "items",
  divisionsURL: "divisions",
  categoriesURL: "categories",
  brandsURL: "brands",
  packGroupsURL: "pack-groups",
  salesItemsRestrictionsUrl: "sales-items-restrictions",
  itemGroupUrl: "item-group",
  gs1templateURL: "gs1-template",

  //Data Definitions --> Dynamic Forms URLs
  dynamicFormsURL: "dynamic-forms",
  customerAppSurveysURL: "customer-app-surveys",
  employeeFormsURL: "employee-forms",
  listOfValues: "list-of-values",
  displayUnitSurveyURL: "display-unit-survey",
  displayUnitSurveyStockCountingURL: "display-unit-survey-stock-counting",
  displayUnitStockCountingResultURL: "display-unit-stock-counting-result",
  //Data Definitions --> Quality Control Reading URLs
  qualityControlsReadingURL: "quality-controls-reading",

  //Data Definitions --> master-data
  masterURL: "master-data",
  geoLocationURL: "geographical-location",
  // organizationsURL: "organizations",
  // currenciesURL: "currencies",
  // banksURL: "banks",
  // branchesURL: "branches",

  //Data Definitions --> Prices & Offers URLs
  pricesURL: "prices",
  discountsURL: "discounts",
  promotionsURL: "promotions",
  bundlesURL: "bundles",
  promotionGroupsURL: "promotion-groups",
  priceProtectionURL: "price-protection",
  contractedFocURL: "contracted-foc",
  //Data Definitions --> Warehouses & Vehicles URLs
  warehousesURL: "warehouses",
  vehiclesURL: "vehicles",
  loyaltyProgramsURL: "loyalty-programs",

  //Data Definitions --> Custome Mobile View URLs
  goldenMinuteControl: "golden-minute-control",
  //end of Data Definition URLs

  //Orders Management URLs
  pendingOrdersURL: "pending-orders",
  approvedOrdersURL: "approved-orders",
  dashboardsURL: "dashboards",
  recurringOrdersURL: "recurring-orders",
  rejectOrdersURL: "rejected-order",
  //end of Orders Management

  //Consignments Orders
  consignmentOrderListURL: "consignment-order-creation",
  consignmentOrderInvoicingListURL: "consignment-order-invoicing",
  //End Of Consignment Orders

  //#region
  //Warehouse Operations URLs
  inputVouchersURL: "input-vouchers",
  outputVouchersURL: "output-vouchers",
  transferInURL: "transfer-in",
  transferOutURL: "transfer-out",
  moveOrdersURL: "move-orders",
  purchaseOrderURL: "purchase-order",
  serialsAggregationURL: "serials-aggregation",
  ssccGenerationURL: "sscc-generation",
  stockCountingTasks: "stock-counting-tasks",
  //end of Warehouse Operations

  //Vehicle Operations URLs
  loadRequestsURL: "load-requests",
  offloadRequestsURL: "offload-requests",
  //end of Vehicle Operations

  //Display Unit URLs

  listDisplayUnitURL: "display-unit-list",
  listDisplayUnitTemplateURL: "display-unit-template",

  //end of Display Unit

  //Trips Management URLs
  tripsURL: "trips",
  checkoutURL: "checkout",
  checkInURL: "checkin",
  settlementsURL: "settlements",
  settlementURL: "settlement",
  machineBoxesURL: "machine-boxes",
  boxesURL: "boxes",
  assignBoxesURL: "assign-boxes",
  releaseBoxesURL: "release-boxes",
  //end of Trips Management

  //Employee Operations URLs
  employeeKeysURL: "employee-keys",
  mapsTrackingURL: "maps",
  journeyActionsURL: "journey-actions",
  exchangeRouteCustomerURL: "exchange-route-customer",
  cancelRouteURL: "cancel-route",
  retrieveRouteCustomersURL: "retrieve-route-customers",
  employeeKeysPrivilegeURL:"employee-keys-privileges",
  //end of Employee Operations

  //#region [Customer Operations URLs]
  invoiceListURL: "invoices",
  collectionURL: "collect-invoices",
  paymentsListURL: "payments",
  downPaymentsListURL: "down-payments",
  newCustomerURL: "new-customer",
  icashReversalURL: "icash-reversal",
  creditNotesListURL: "credit-notes",
  debitNotesListURL: "debit-notes",
  transactionProofsURL: "document-proofs",
  customerMappingURL: "customer-mapping",
  invoicesEmails: "transactions-emails",
  transactionListURL: "transactions",
  postPDCURL: "post-pdc",
  calculateTargetPromotionsURL: "calculate-target-promotions",
  customerReturnsURL: "returns",
  customerExchangesURL: "exchanges",
  shopContractsURL: 'shop-contracts',
  creditNotePlanURL: 'credit-note-plan',
  debitNotePlanURL: 'debit-note-plan',
  //#endregion
  //Settings URLs
  configurationURL: "configurations",
  sonicServicesURL: "sonic-services",
  emailTemplatesURL: "email-templates",
  dynamicTemplatesURL: "dynamic-templates",
  EmployeeGroupEmailsURL: "employee-group-emails",
  securityGroupsURL: "security-groups-privilege",
  restoreToDefault: "restore-default-captions",
  approvalCycleLevelsURL: "approval-cycle-levels",
  notificationTemplatesURL: "notification-templates",
  customerVisitPatterns: "customer-visit-patterns",
  operatorLoginFailuresURL: "operator-login-failures",
  BILinks: "BI-links",
  //end of Settings

  // Customer App Properties
  custAppPropertiesURL: "cust-app-properties",
  custAppBannersURL: "cust-app-banners",
  custAppItemsURL: "cust-app-items",
  custAppFirstOrderItems: "cust-app-items",
  // End Of Customer App Properties

  // Serial Operation
  backToStoreOffloadURL: "back-to-store-offload",
  receiveHelloJordanURL: "receive-hello-jordan",
  resendHelloJordanURL: "resend-hello-jordan",
  serialReassignment: "serial-reassignment",
  backToStoreResetURL: "back-to-store-reset",
  unlockSerialURL: "unlock-serial",
  itemSerialOperationsURL: "item-serial-operations",
  offloadManuallyURL: "offload-manually",
  //End Of Serial Operation
  //#region [Achievement]
  performanceURL: "performance",
  kpisURL: "kpis",
  performanceConfigurationURL: "performance-configuration",
  commissionURL: 'commissions',
  commissionsCalculationURL : 'commissions-calculation',

  //#endregion
  editPendingOrderURL: "edit-pending-order",
  addPendingOrderURL: "add-pending-order",
  assignedOrdersURL: "assigned-orders",
  dispatchOrdersURL: "dispatch-orders",
  invoicedOrdersURL: "invoiced-orders",
  collectedOrdersURL: "collected-orders",
  loginURL: "login",
  addInvoiceURL: "add-invoice",
  loadAddURL: "add-load-request",
  offloadAddURL: "add-offload-request",
  loadEditURL: "edit-load-request",
  offloadEditURL: "edit-offload-request",
  addInputURL: "add-input-voucher",
  editInputURL: "edit-input-voucher",
  addOutputURL: "add-output-voucher",
  editOutputURL: "edit-output-voucher",
  addTransferInURL: "add-transfer-in",
  editTransferInURL: "edit-transfer-in",
  addTransferOutURL: "add-transfer-out",
  editTransferOutURL: "edit_transfer-out",
  addMoveOrderURL: "add-move-order",
  editMoveOrderURL: "edit-move-order",
  addPurchaseOrderURL: "add-purchase-order",
  editPurchaseOrderURL: "edit-purchase-order",
  myProfileURL: "my-profile",
  selectLanguageURL: "select-language",
  addDivisionURL: "add-division",
  addCategoryURL: "add-category",
  addBrandURL: "add-brand",
  addPackGroupsURL: "add-pack-group",
  addItemListURL: "add-item-list",
  addCustomerSurveyURL: "add-customer-survey",
  addQualitycontrolFormURL: "add-quality-control-form",
  addCustomerAppSurveyURL: "add-customer-app-survey",
  addQualitycontrolReadingURL: "add-quality-control-reading",
  addEmployeeFormURL: "add-employee-form",
  deviceListURL: "devices",
  addItemURL: "add-item",
  endorsementURL: "endorsement",
  setupURL: "setup",
  noMenusURL: "no_menus",
  recallsMainURL: "recalls",
  recallsListURL: "recall-tasks",
  recallsSubTasksListURL: "recall-sub-tasks",
  incidentsListURL: "incidents",
  qcMainURL: "quality-control",
  dataReviewMainURL: "data-review",
  displayUnitCustomerImgsURL: "display-unit-customers-images",
  surveyCustomerImageURL: "survey-customer-images",
  surveyEmployeeImageURL: "survey-employee-images",
  qcDefinitionURL: "quality-control-definition",
  qcReadingURL: "quality-control-reading",
  qcTasksURL: "quality-control-tasks",

  //Start of Proofs
  proofsURL: "proofs",
  documentProofsURL: "document-proofs",
  proofsReviewURL: "proofs-review",
  //End of Proofs

  // ROI
  roiFormulaURL: "roi-formula",
  roiDamagedStockURL: "damaged",
  roiIncomingStockURL: "incoming",
  roiIncomingRetunrsStockURL: "incoming-returns",
  roiManufacturingDefectsURL: "defects",
  roiMainStockURL: "stock",
  roiIncomingWithoutRefURL: "incoming-without-reference",
  roiIncomingReturnsWithoutRefURL: "incoming-returns-without-reference",

  //forecasting
  ForecastingMainURL: "forecasting",
  ForecastingWeeksWeightURL:"forecasting-weeks-weight",
  SeasonDefinitonURL:"season-definition",
  ForecastLoadRequestURL: "forecast-load-request",
  //endofforecasting

  //chatting
  chattingURL: "chatting",
  //end of chatting

  // Data Review :
  stockURL: "stock",
  trackingReportURL: "tracking-report",
  InvoicesLocations: "invoices-locations",


  //  InvoicesLocations: "invoices-locations",
  gridReportsURL: "grid-reports",
};
